import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import { useToasts } from 'shared/toast/useToasts';
import Dialog from 'shared/components/Dialog';
import useHandleError from 'shared/errors/useHandleError';
import type { OrgUnitFormValues } from 'orgUnit/OrgUnitForm';
import OrgUnitForm from 'orgUnit/OrgUnitForm';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import { useCreateOrgUnitMutation } from './CreateOrgUnit.graphql.generated';
import { resolveCreateOrgUnitInput } from './CreateOrgUnitModal.utils';

const createOrgUnitFormId = 'createOrgUnitForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

const CreateOrgUnitModal = ({ isOpen, onClose, onSuccess }: Props) => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { activeOrg } = useActiveOrg();

  const onError = useHandleError();

  const [createOrgUnit, { loading }] = useCreateOrgUnitMutation({
    onError,
  });

  const handleSubmit = useCallback(
    async (values: OrgUnitFormValues) => {
      const result = await createOrgUnit({
        variables: {
          input: resolveCreateOrgUnitInput({
            values,
            orgKey: activeOrg.orgKey,
          }),
        },
      });

      const orgUnit = result?.data?.addOrgUnit;

      if (orgUnit) {
        onSuccess();
        addToast({
          children: t('orgUnit.createOrgUnitModal.successToast'),
          variant: 'success',
        });
        onClose();
      }
    },
    [activeOrg.orgKey, addToast, createOrgUnit, onClose, onSuccess, t],
  );

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerTitle={t('orgUnit.createOrgUnitModal.heading')}
      confirmLabel={t('create')}
      cancelLabel={t('cancel')}
      formId={createOrgUnitFormId}
      isConfirmDisabled={loading}
    >
      <OrgUnitForm onSubmit={handleSubmit} id={createOrgUnitFormId} />
    </Dialog>
  );
};

export default CreateOrgUnitModal;
