import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { getNodesFromConnection } from 'shared/graphql/utils';
import Spinner from 'shared/spinner/Spinner';
import { useTeamAdapter } from 'team/TeamAdapter';
import useHandleError from 'shared/errors/useHandleError';

import type { StrategyRoomProviderContextValue } from './StrategyRoomProvider.context';
import { StrategyRoomProviderContext } from './StrategyRoomProvider.context';
import { useStrategyRoomStrategiesQuery } from './StrategyRoomProvider.graphql.generated';

type StrategyRoomProviderProps = PropsWithChildren<object>;

const StrategyRoomProvider = ({ children }: StrategyRoomProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const onError = useHandleError();

  const orgUnitIdsFilter = teamAdapter.isOrgUnit
    ? { operator: 'IN' as const, values: [teamAdapter.keyArg] }
    : { operator: 'IS_NULL' as const };

  const {
    data: strategyRoomStrategiesQueryData,
    loading: isLoading,
    refetch: refetchStrategyRoomStrategies,
  } = useStrategyRoomStrategiesQuery({
    variables: { filter: { orgUnitIds: orgUnitIdsFilter } },
    onError,
  });

  const contextValue = useMemo<Maybe<StrategyRoomProviderContextValue>>(() => {
    if (strategyRoomStrategiesQueryData) {
      return {
        strategies: getNodesFromConnection(
          strategyRoomStrategiesQueryData.activeOrg.strategies,
        ),
        refetchStrategies: refetchStrategyRoomStrategies,
      };
    }
    return undefined;
  }, [refetchStrategyRoomStrategies, strategyRoomStrategiesQueryData]);

  if (!contextValue && isLoading) {
    return <Spinner.Circle isCentered={true} />;
  }

  if (contextValue) {
    return (
      <StrategyRoomProviderContext.Provider value={contextValue}>
        {children}
      </StrategyRoomProviderContext.Provider>
    );
  }

  return null;
};

export default StrategyRoomProvider;
