import { useToggle } from 'react-use';

import Layout from 'shared/components/Layout';
import Spinner from 'shared/spinner/Spinner';
import CalendarControlsProvider from 'shared/filters/CalendarControls/CalendarControlsProvider';
import CompletedFilterProvider from 'shared/filters/CompletedFilterButton/CompletedFilterProvider';
import CollaboratingTeamsFilterProvider from 'shared/filters/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';
import StatusFilterProvider from 'shared/filters/StatusFilter/StatusFilterProvider';
import { ChipGroupProvider } from 'shared/filters/ChipGroup/ChipGroupProvider';
import { PriorityFilterProvider } from 'shared/filters/PriorityFilter';
import { UserFilterProvider } from 'shared/filters/UserFilter';
import { TimelineFilterProvider } from 'shared/filters/TimelineFilter';

import StrategyBoardContent from './StrategyBoardContent';
import StrategyBoardHeader from './StrategyBoardHeader';
import StrategyBoardFilters from './StrategyBoardFilters';
import { useStrategyBoard } from './StrategyBoardProvider';
import StrategyBoardProvider from './StrategyBoardProvider/StrategyBoardProvider';
import StrategyBoardAskSophieButton from './StrategyBoardAskSophieButton';

const StrategyBoard = () => {
  const { isStrategyBoardLoading } = useStrategyBoard();

  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  if (isStrategyBoardLoading) return <Spinner.Circle />;

  return (
    <Layout.Content variant={'contained'} hasPadding={false}>
      <StrategyBoardHeader>
        <StrategyBoardFilters
          areAllSectionsExpanded={areAllSectionsExpanded}
          toggleAreAllSectionsExpanded={toggleAreAllSectionsExpanded}
        />
        <StrategyBoardAskSophieButton />
      </StrategyBoardHeader>

      <StrategyBoardContent areAllSectionsExpanded={areAllSectionsExpanded} />
    </Layout.Content>
  );
};

export default () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <ChipGroupProvider>
        <CollaboratingTeamsFilterProvider storeToSession={true}>
          <StatusFilterProvider storeToSession={true}>
            <PriorityFilterProvider storeToSession={true}>
              <UserFilterProvider storeToSession={true}>
                <TimelineFilterProvider storeToSession={true}>
                  <StrategyBoardProvider>
                    <StrategyBoard />
                  </StrategyBoardProvider>
                </TimelineFilterProvider>
              </UserFilterProvider>
            </PriorityFilterProvider>
          </StatusFilterProvider>
        </CollaboratingTeamsFilterProvider>
      </ChipGroupProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
