import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useHandleError from 'shared/errors/useHandleError';

import type { ObjectiveInsightsContextValue } from './ObjectiveInsightsProvider.context';
import { ObjectiveInsightsContext } from './ObjectiveInsightsProvider.context';
import { useObjectiveInsightsQuery } from './ObjectiveInsightsProvider.graphql.generated';

type ObjectiveInsightsProviderProps = PropsWithChildren<object>;

const ObjectiveInsightsProvider = ({
  children,
}: ObjectiveInsightsProviderProps) => {
  const { objectiveId } = useParams() as {
    objectiveId: string;
  };

  const onError = useHandleError();

  const { data, loading: isObjectiveLoading } = useObjectiveInsightsQuery({
    variables: {
      objectiveId,
    },
    onError,
  });

  const contextValue = useMemo<ObjectiveInsightsContextValue>(
    () => ({
      objective: data?.objective,
      isObjectiveLoading,
    }),
    [data, isObjectiveLoading],
  );

  return (
    <ObjectiveInsightsContext.Provider value={contextValue}>
      {children}
    </ObjectiveInsightsContext.Provider>
  );
};

export default ObjectiveInsightsProvider;
