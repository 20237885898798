import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import type { CalendarCheck } from 'shared/filters/CalendarControls/CalendarControls.context';
import {
  testStatusIndicatorAgainstIds,
  type StatusFilterValue,
} from 'shared/filters/StatusFilter';
import type { PriorityFilterValue } from 'shared/filters/PriorityFilter';
import { testPriorityFilters } from 'shared/filters/PriorityFilter';

import type { StrategyInitiativesObjectiveFragment } from './StrategyInitiativesProvider.graphql.generated';

export const filterObjectivesByStatusIndicator = (
  objectives: StrategyInitiativesObjectiveFragment[],
  statuses: StatusFilterValue[],
) =>
  objectives.map((objective) => ({
    ...objective,
    initiatives: objective.initiatives.filter((initiative) =>
      testStatusIndicatorAgainstIds(
        statuses,
        initiative.currentInitiativeStatus?.statusIndicator,
      ),
    ),
  }));

export const filterObjectivesByCompleted = (
  objectives: StrategyInitiativesObjectiveFragment[],
  showCompleted: boolean,
) =>
  objectives.map((objective) => ({
    ...objective,
    initiatives: objective.initiatives.filter(
      (initiative) =>
        showCompleted || !initiative.currentInitiativeStatus?.complete,
    ),
  }));

export const filterObjectiveInitiativesByPriority = (
  objectives: StrategyInitiativesObjectiveFragment[],
  priorities: PriorityFilterValue[],
) =>
  objectives.map((objective) => ({
    ...objective,
    initiatives: objective.initiatives.filter((initiative) =>
      testPriorityFilters(priorities, initiative.priority),
    ),
  }));

export const filterObjectivesByUser = (
  objectives: StrategyInitiativesObjectiveFragment[],
  userIds: string[],
) => {
  if (userIds.length === 0) {
    return objectives;
  }

  return objectives.map((objective) => ({
    ...objective,
    initiatives: objective.initiatives.filter((initiative) =>
      userIds.some((userId) => userId === initiative.owner?.id),
    ),
  }));
};

export const filterObjectivesByDates = (
  objectives: StrategyInitiativesObjectiveFragment[],
  start: Maybe<Date>,
  end: Maybe<Date>,
  check?: CalendarCheck,
) => {
  if (!start && !end) return objectives;

  return objectives.map((objective) => ({
    ...objective,
    initiatives: objective.initiatives.filter((initiative) =>
      testStrategyItemAgainstInterval(initiative, { start, end }, check),
    ),
  }));
};

export const filterObjectivesByCollaboratingTeams = (
  objectives: StrategyInitiativesObjectiveFragment[],
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  objectives.filter(
    (objective) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      objective.orgUnit?.id === currentOrgUnitId,
  );

export const getObjectivesOwners = (
  objectives: StrategyInitiativesObjectiveFragment[],
) =>
  Object.values(
    objectives.reduce<
      Record<string, StrategyInitiativesObjectiveFragment['owner']>
    >((accumulator, objective) => {
      objective.initiatives.forEach((metric) => {
        const user = metric.owner;
        if (user && !accumulator[user.id]) {
          accumulator[user.id] = user;
        }
      });
      return accumulator;
    }, {}),
  ).filter(Boolean);
