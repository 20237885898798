import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useHandleError from 'shared/errors/useHandleError';

import { useObjectiveMetricsQuery } from './ObjectiveMetricsProvider.graphql.generated';
import type { ObjectiveMetricsContextValue } from './ObjectiveMetricsProvider.context';
import { ObjectiveMetricsContext } from './ObjectiveMetricsProvider.context';

type ObjectiveMetricsProviderProps = PropsWithChildren<object>;

const ObjectiveMetricsProvider = ({
  children,
}: ObjectiveMetricsProviderProps) => {
  const { objectiveId } = useParams() as {
    objectiveId: string;
  };

  const onError = useHandleError();

  const { data, loading: isObjectiveMetricsLoading } = useObjectiveMetricsQuery(
    {
      variables: { objectiveId },
      onError,
    },
  );

  const contextValue = useMemo<ObjectiveMetricsContextValue>(
    () => ({
      objective: data?.objective,
      isObjectiveMetricsLoading: isObjectiveMetricsLoading && !data,
    }),
    [data, isObjectiveMetricsLoading],
  );

  return (
    <ObjectiveMetricsContext.Provider value={contextValue}>
      {children}
    </ObjectiveMetricsContext.Provider>
  );
};

export default ObjectiveMetricsProvider;
