import type { PropsWithChildren } from 'react';
import { useEffect, useMemo, useState } from 'react';

import useMultiSearchParamState from 'shared/hooks/useMultiSearchParamState';

import type {
  UserFilterContextValue,
  UserFilterSelectItem,
} from './UserFilter.context';
import UserFilterContext from './UserFilter.context';
import { useChipGroup } from '../ChipGroup';

const filterName = 'user';

type Props = PropsWithChildren<{
  storeToSession: boolean;
}>;

const UserFilterProvider = ({ storeToSession, children }: Props) => {
  const [availableUsers, setAvailableUsers] = useState<UserFilterSelectItem[]>(
    [],
  );

  const [
    selectedUserIds,
    setSelectedUsers,
    clearSelectedUsers,
    addSelectedUser,
    removeSelectedUser,
  ] = useMultiSearchParamState<string>(filterName, [], storeToSession);

  const selectedUsers = useMemo(
    () => availableUsers.filter((user) => selectedUserIds.includes(user.id)),
    [availableUsers, selectedUserIds],
  );

  const { setChipItems } = useChipGroup();

  useEffect(
    () =>
      setChipItems(
        filterName,
        clearSelectedUsers,
        selectedUsers.map((user) => ({
          label: user.displayName || user.email,
          onRemove: () => removeSelectedUser(user.id),
        })),
      ),
    [clearSelectedUsers, removeSelectedUser, selectedUsers, setChipItems],
  );

  const value = useMemo<UserFilterContextValue>(
    () => ({
      selectedUserIds,
      setSelectedUsers,
      clearSelectedUsers,
      addSelectedUser,
      removeSelectedUser,
      availableUsers,
      setAvailableUsers,
    }),
    [
      addSelectedUser,
      availableUsers,
      clearSelectedUsers,
      removeSelectedUser,
      selectedUserIds,
      setSelectedUsers,
    ],
  );

  return (
    <UserFilterContext.Provider value={value}>
      {children}
    </UserFilterContext.Provider>
  );
};

export default UserFilterProvider;
