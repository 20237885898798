import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { css } from '@emotion/react';

import Dialog from 'shared/components/Dialog';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';

import type { LinkInitiativeDialogInitiativeFragment } from './LinkInitiativeDialog.graphql.generated';
import {
  useLinkInitiativeDialogQuery,
  useLinkInitiativeToObjectiveMutation,
} from './LinkInitiativeDialog.graphql.generated';
import InitiativeSelection from './InitiativeSelection';
import type { LinkInitiativeObjectiveParam } from '../LinkInitiativeButton.type';

type Props = {
  isOpen: boolean;
  objective: LinkInitiativeObjectiveParam;
  onClose: () => void;
};

const LinkInitiativeDialog = ({ objective, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const [selectedInitiative, setSelectedInitiative] =
    useState<LinkInitiativeDialogInitiativeFragment>();

  const onError = useHandleError();

  const { data } = useLinkInitiativeDialogQuery({
    skip: !isOpen,
    onError,
    variables: { objectiveId: objective.id },
  });

  const [linkInitiative, { loading }] = useLinkInitiativeToObjectiveMutation({
    onError,
  });

  const handleClose = useCallback(() => {
    setSelectedInitiative(undefined);
    onClose();
  }, [onClose]);

  const handleConfirm = useCallback(async () => {
    if (!selectedInitiative) return;

    await linkInitiative({
      variables: {
        objectiveId: objective.id,
        initiativeId: selectedInitiative.id,
      },
    });

    addToast({
      variant: 'success',
      children: t('initiative.linkInitiative.dialog.toast.success'),
    });

    handleClose();
  }, [
    addToast,
    handleClose,
    linkInitiative,
    objective.id,
    selectedInitiative,
    t,
  ]);

  const objectives = data?.objective.childObjectives
    .map((childObjective) => ({
      ...childObjective,
      initiatives: childObjective.initiatives.filter(
        (initiative) =>
          !objective.initiatives
            .map((initiative) => initiative.id)
            .includes(initiative.id),
      ),
    }))
    .filter((objective) => objective.initiatives.length > 0);

  const orgUnits = [...(data?.activeOrg.orgUnits || [])];

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      headerTitle={t('initiative.linkInitiative.dialog.header')}
      headerSubtitle={t('initiative.linkInitiative.dialog.description')}
      confirmLabel={t('confirm')}
      isConfirmDisabled={!selectedInitiative || loading}
      cancelLabel={t('cancel')}
      onConfirm={handleConfirm}
      animateOpen={false}
      animateClose={false}
    >
      {objectives ? (
        <InitiativeSelection
          objectives={objectives}
          orgUnits={orgUnits}
          selectedInitiative={selectedInitiative}
          setSelectedInitiative={setSelectedInitiative}
        />
      ) : (
        <div css={css({ marginTop: '5rem' })}>
          <Spinner.Circle />
        </div>
      )}
    </Dialog>
  );
};

export default LinkInitiativeDialog;
