import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMultiSearchParamState from 'shared/hooks/useMultiSearchParamState';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';

import type {
  PriorityFilterContextValue,
  PriorityFilterValue,
} from './PriorityFilter.context';
import PriorityFilterContext from './PriorityFilter.context';
import { useChipGroup } from '../ChipGroup';
import { getPriorityLabel } from './PriorityFilter.utils';
const filterName = 'priority';

type Props = PropsWithChildren<{ storeToSession: boolean }>;

const PriorityFilterProvider = ({ storeToSession, children }: Props) => {
  const { t } = useTranslation();

  const { priorities: allPriorities } = useStrategyProfilePriorities();

  const [
    selectedPriorities,
    setPriorities,
    clearPriorities,
    addPriority,
    removePriority,
  ] = useMultiSearchParamState<PriorityFilterValue>(
    filterName,
    [],
    storeToSession,
    (value) => (value === 'UNKNOWN' ? value : Number(value)),
  );

  const { setChipItems } = useChipGroup();

  useEffect(
    () =>
      setChipItems(
        filterName,
        clearPriorities,
        selectedPriorities.map((priority) => ({
          label: getPriorityLabel(t, priority, allPriorities),
          onRemove: () => removePriority(priority),
        })),
      ),
    [
      allPriorities,
      clearPriorities,
      removePriority,
      selectedPriorities,
      setChipItems,
      t,
    ],
  );

  const value = useMemo<PriorityFilterContextValue>(
    () => ({
      priorities: selectedPriorities,
      setPriorities,
      clearPriorities,
      addPriority,
      removePriority,
    }),
    [
      addPriority,
      clearPriorities,
      removePriority,
      selectedPriorities,
      setPriorities,
    ],
  );

  return (
    <PriorityFilterContext.Provider value={value}>
      {children}
    </PriorityFilterContext.Provider>
  );
};

export default PriorityFilterProvider;
