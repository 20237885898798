import { useDefinedContext } from 'shared/utils/context.utils';

import TimelineFilterProvider from './TimelineFilterProvider';
import TimelineFilterContext from './TimelineFilter.context';

export const useTimelineFilter = () =>
  useDefinedContext(TimelineFilterContext, {
    hookName: useTimelineFilter.name,
    providerName: TimelineFilterProvider.name,
  });
