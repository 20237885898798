import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { OrgUnit } from 'types.graphql.generated';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';

import { useOrgUnitCardQuery } from './OrgUnitCardProvider.graphql.generated';
import type { OrgUnitCardProviderContextValue } from './OrgUnitCardProvider.context';
import { OrgUnitCardProviderContext } from './OrgUnitCardProvider.context';

type OrgUnitCardProviderProps = PropsWithChildren<{
  orgUnitId: OrgUnit['id'];
}>;

const OrgUnitCardProvider = ({
  orgUnitId,
  children,
}: OrgUnitCardProviderProps) => {
  const onError = useHandleError();

  const { data: orgUnitCardData, loading: isOrgUnitCardLoading } =
    useOrgUnitCardQuery({ variables: { orgUnitId }, onError });

  const contextValue = useMemo<Maybe<OrgUnitCardProviderContextValue>>(() => {
    if (orgUnitCardData) {
      return {
        orgUnit: orgUnitCardData.orgUnit,
      };
    }
    return undefined;
  }, [orgUnitCardData]);

  if (!contextValue && isOrgUnitCardLoading) {
    return <Spinner.Circle size={'medium'} />;
  }

  if (contextValue) {
    return (
      <OrgUnitCardProviderContext.Provider value={contextValue}>
        {children}
      </OrgUnitCardProviderContext.Provider>
    );
  }

  return null;
};

export default OrgUnitCardProvider;
