import { createContext } from 'react';

import type { Theme } from 'types.graphql.generated';

export type ThemeFilterTheme = Pick<Theme, 'id' | 'name'>;

export type ThemeFilterContextValue = {
  addSelectedTheme: (themeId: string) => void;
  availableThemes: ThemeFilterTheme[];
  clearSelectedThemes: () => void;
  removeSelectedTheme: (themeId: string) => void;
  selectedThemeIds: string[];
  setAvailableThemes: (themes: ThemeFilterTheme[]) => void;
  setSelectedThemes: (themeIds: string[]) => void;
};

const ThemeFilterContext = createContext<ThemeFilterContextValue | undefined>(
  undefined,
);

export default ThemeFilterContext;
