import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ListState, Selection } from 'react-stately';
import { last, without } from 'lodash';

import MultiSelect from '../../components/MultiSelect';
import StatusFilterLabel from './ActionStatusFilterLabel';
import { getActionStatusLabel } from './ActionStatusFilter.utils';
import type { ActionStatusFilterValue } from './ActionStatusFilter.context';
import { useActionStatusFilter } from './useActionStatusFilter';

export type ActionStatusFilterSelectItemId = ActionStatusFilterValue | 'ALL';

export type ActionStatusFilterSelectItem = {
  id: ActionStatusFilterSelectItemId;
};

const itemIds = [
  'ALL',
  'INACTIVE',
  'ACTIVE',
] satisfies ActionStatusFilterSelectItemId[];

const renderLabel = (state: ListState<ActionStatusFilterSelectItem>) => (
  <StatusFilterLabel state={state} />
);

const StatusFilter = () => {
  const { t } = useTranslation();

  const { actionStatuses, setActionStatuses, clearActionStatuses } =
    useActionStatusFilter();

  const items = useMemo(
    () => itemIds.map((id) => ({ id, title: getActionStatusLabel(t, id) })),
    [t],
  );

  const selectedKeys = useMemo(
    () => (actionStatuses.length === 0 ? ['ALL'] : actionStatuses),
    [actionStatuses],
  );

  const onSelectionChange = useCallback(
    (selectedKeys: Selection) =>
      selectedKeys === 'all' || last(Array.from(selectedKeys)) === 'ALL'
        ? clearActionStatuses()
        : setActionStatuses(
            without([...selectedKeys], 'ALL') as ActionStatusFilterValue[],
          ),
    [clearActionStatuses, setActionStatuses],
  );

  return (
    <MultiSelect<ActionStatusFilterSelectItem>
      items={items}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      label={renderLabel}
    />
  );
};

export default StatusFilter;
