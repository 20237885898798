import { testStrategyItemAgainstInterval } from 'strategy/strategy.utils';
import type { CalendarCheck } from 'shared/filters/CalendarControls/CalendarControls.context';
import {
  testPriorityFilters,
  type PriorityFilterValue,
} from 'shared/filters/PriorityFilter';
import type { StatusFilterValue } from 'shared/filters/StatusFilter';
import { testStatusIndicatorAgainstIds } from 'shared/filters/StatusFilter';

import type { StrategyObjectivesObjective } from '../StrategyObjectives.type';

export const filterObjectivesByTheme = (
  objectives: StrategyObjectivesObjective[],
  themeIds: string[],
): StrategyObjectivesObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      (themeIds.length === 0 ||
        (!!objective.theme && themeIds.includes(objective.theme.id))),
  }));

export const filterObjectivesByStatusIndicator = (
  objectives: StrategyObjectivesObjective[],
  statuses: StatusFilterValue[],
): StrategyObjectivesObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      testStatusIndicatorAgainstIds(
        statuses,
        objective.currentObjectiveStatus?.statusIndicator,
      ),
  }));

export const filterObjectivesByCompleted = (
  objectives: StrategyObjectivesObjective[],
  showCompleted: boolean,
): StrategyObjectivesObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      (showCompleted || !objective.currentObjectiveStatus?.complete),
  }));

export const filterObjectivesByPriority = (
  objectives: StrategyObjectivesObjective[],
  priorities: PriorityFilterValue[],
): StrategyObjectivesObjective[] =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      testPriorityFilters(priorities, objective.priority),
  }));

export const filterObjectivesByUser = (
  objectives: StrategyObjectivesObjective[],
  userIds: string[],
) =>
  objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      (!userIds.length ||
        userIds.some((userId) => userId === objective.owner?.id)),
  }));

export const filterObjectivesByDates = (
  objectives: StrategyObjectivesObjective[],
  startDate: Maybe<Date>,
  endDate: Maybe<Date>,
  check?: CalendarCheck,
) => {
  if (!startDate && !endDate) return objectives;

  const interval = { start: startDate, end: endDate };

  return objectives.map((objective) => ({
    ...objective,
    isFiltersMatch:
      objective.isFiltersMatch &&
      testStrategyItemAgainstInterval(objective, interval, check),
  }));
};

export const filterObjectivesByCollaboratingTeams = (
  objectives: StrategyObjectivesObjective[],
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  objectives.filter(
    (objective) =>
      showCollaboratingTeams ||
      !currentOrgUnitId ||
      objective.orgUnit?.id === currentOrgUnitId,
  );

export const getObjectivesOwners = (
  objectives: StrategyObjectivesObjective[],
) =>
  Object.values(
    objectives.reduce<Record<string, StrategyObjectivesObjective['owner']>>(
      (accumulator, objective) => {
        const user = objective.owner;

        if (user && !accumulator[user.id]) accumulator[user.id] = user;

        return accumulator;
      },
      {},
    ),
  ).filter(Boolean);
