import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';

import type { OrgCardContextValue } from './OrgCardProvider.context';
import { OrgCardContext } from './OrgCardProvider.context';
import { useOrgCardQuery } from './OrgCardProvider.graphql.generated';

type OrgCardProviderProps = PropsWithChildren<object>;

const OrgCardProvider = ({ children }: OrgCardProviderProps) => {
  const onError = useHandleError();

  const { data, loading } = useOrgCardQuery({ onError });

  const contextValue = useMemo<Maybe<OrgCardContextValue>>(() => {
    if (data) {
      return {
        org: data.activeOrg,
      };
    }
    return undefined;
  }, [data]);

  if (!contextValue && loading) {
    return <Spinner.Circle size={'medium'} />;
  }

  if (contextValue) {
    return (
      <OrgCardContext.Provider value={contextValue}>
        {children}
      </OrgCardContext.Provider>
    );
  }

  return null;
};

export default OrgCardProvider;
