import { createContext } from 'react';

import type { StrategyInitiativesObjectiveFragment } from './StrategyInitiativesProvider.graphql.generated';
import type { StrategyInitiativesObjectivesByTheme } from './StrategyInitiativesProvider.type';

export type StrategyObjectivesInitiativesContextValue = {
  isStrategyInitiativesLoading: boolean;
  objectives: Maybe<StrategyInitiativesObjectiveFragment[]>;
  objectivesByTheme: StrategyInitiativesObjectivesByTheme[];
};

export const StrategyObjectivesInitiativesContext = createContext<
  StrategyObjectivesInitiativesContextValue | undefined
>(undefined);
