import { useTranslation } from 'react-i18next';

import { useToasts } from 'shared/toast/useToasts';
import type { Report } from 'types.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import useCanPerformOrgOrOrgUnitAction from 'user/ability/useCanPerformOrgOrOrgUnitAction';

import { useUseDeleteReportDeleteReportMutation } from './useDeleteReport.graphql.generated';

export type UseDeleteReportParams = {
  report: Pick<Report, 'id'>;
};

type UseDeleteReportReturnType = {
  canDeleteReport: boolean;
  deleteReport: (params: { onSuccess?: () => void }) => Promise<void>;
};

export const useDeleteReport = ({
  report,
}: UseDeleteReportParams): UseDeleteReportReturnType => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const canDeleteReport = useCanPerformOrgOrOrgUnitAction(
    'WRITE_STRATEGY',
    'WRITE',
  );

  const onError = useHandleError();

  const [deleteReport] = useUseDeleteReportDeleteReportMutation({
    onError,
  });

  const handleDeleteReport: UseDeleteReportReturnType['deleteReport'] = async ({
    onSuccess,
  }) => {
    if (canDeleteReport) {
      await deleteReport({
        variables: { reportId: report.id },
        update: (cache) =>
          setTimeout(() => cache.evict({ id: `Report:${report.id}` }), 100),
        onCompleted: () => {
          addToast({
            children: t('deleteSuccessToast'),
            variant: 'success',
          });
          onSuccess?.();
        },
      });
    }
  };

  return {
    canDeleteReport,
    deleteReport: handleDeleteReport,
  };
};
