import { useDefinedContext } from 'shared/utils/context.utils';

import StatusFilterProvider from './StatusFilterProvider';
import StatusFilterContext from './StatusFilter.context';

export const useStatusFilter = () =>
  useDefinedContext(StatusFilterContext, {
    hookName: useStatusFilter.name,
    providerName: StatusFilterProvider.name,
  });
