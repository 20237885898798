import type { TFunction } from 'react-i18next';

import type { ActionStatus } from 'types.graphql.generated';

import type { ActionStatusFilterValue } from './ActionStatusFilter.context';
import type { ActionStatusFilterSelectItemId } from './ActionStatusFilter';

type CheckActionStatus = Pick<ActionStatus, 'complete' | 'completionRate'>;

export const getActionStatusLabel = (
  t: TFunction,
  status: ActionStatusFilterSelectItemId,
) => {
  switch (status) {
    case 'ALL':
      return t(`actions.actionStatusMultiSelect.labels.ALL`);
    case 'ACTIVE':
      return t(`actions.actionStatusMultiSelect.labels.ACTIVE`);
    case 'INACTIVE':
      return t(`actions.actionStatusMultiSelect.labels.INACTIVE`);
  }
};

export const testActionStatusFilter = (
  filterStatus: ActionStatusFilterValue,
  statusToCheck?: CheckActionStatus,
) => {
  switch (filterStatus) {
    case 'ACTIVE': {
      return (
        !!statusToCheck?.completionRate && statusToCheck.completionRate > 0
      );
    }
    case 'INACTIVE': {
      return (
        !statusToCheck?.completionRate || statusToCheck.completionRate === 0
      );
    }
  }
};

export const testActionStatusFilters = (
  filterStatuses: ActionStatusFilterValue[],
  statusToCheck?: CheckActionStatus,
) => {
  return (
    filterStatuses.length === 0 ||
    filterStatuses.some((status) =>
      testActionStatusFilter(status, statusToCheck),
    )
  );
};
