import { useCallback } from 'react';

import type { Strategy } from 'types.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';

import { useUpdateIsStrategyCompleteMutation } from './useUpdateIsStrategyComplete.graphql.generated';

type UpdateIsStrategyCompleteFn = (params: {
  isComplete: boolean;
  onCompleted: () => void;
  strategyId: Strategy['id'];
}) => Promise<unknown>;

type UseUpdateIsStrategyCompleteReturnType = {
  updateIsStrategyComplete: UpdateIsStrategyCompleteFn;
};

export const useUpdateIsStrategyComplete =
  (): UseUpdateIsStrategyCompleteReturnType => {
    const onError = useHandleError();

    const [updateIsStrategyComplete] = useUpdateIsStrategyCompleteMutation({
      onError,
    });

    const handleUpdateIsStrategyComplete =
      useCallback<UpdateIsStrategyCompleteFn>(
        ({ strategyId, isComplete, onCompleted }) =>
          updateIsStrategyComplete({
            variables: {
              strategyId,
              isComplete,
            },
            onCompleted,
          }),
        [updateIsStrategyComplete],
      );

    return {
      updateIsStrategyComplete: handleUpdateIsStrategyComplete,
    };
  };
