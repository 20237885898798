import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserValidation } from 'user/UserValidationProvider/useUserValidation';
import useHandleError from 'shared/errors/useHandleError';

import { useValidateLoggedInUserMutation } from './useValidateLoggedInUser.graphql.generated';

export const useValidateLoggedInUser = () => {
  const { t } = useTranslation();
  const { onValidated } = useUserValidation();

  const onError = useHandleError({
    logoutOnError: true,
    defaultErrorMessage: t('signUp.verificationErrorMessage'),
  });

  const [validateLoggedInUser] = useValidateLoggedInUserMutation({
    onError,
    onCompleted: onValidated,
  });

  return useCallback(
    (authToken: string, providerAccessToken?: string) =>
      validateLoggedInUser({
        context: {
          headers: {
            authorization: `Bearer ${authToken}`,

            // Microsoft access token is used to fetch the user profile image
            'X-CREDENTIAL_ACCESS_TOKEN': providerAccessToken,
          },
        },
      }),
    [validateLoggedInUser],
  );
};
