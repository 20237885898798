import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { css } from '@emotion/react';
import * as yup from 'yup';

import Dialog from 'shared/components/Dialog';
import useHandleError from 'shared/errors/useHandleError';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useToasts } from 'shared/toast/useToasts';
import Form from 'shared/form/Form';
import { TextInputField } from 'shared/components/TextInput';
import { SliderField2 } from 'shared/components/Slider/V2';
import FieldBox from 'shared/form/FieldBox';
import Flex from 'shared/components/Flex';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';
import { ContributionMyFollowUpsViewMoreDocument } from 'contribution/ContributionMyFollowUpsViewMore/ContributionMyFollowUpsViewMore.graphql.generated';

import { useUpdateCreateOneThingTaskMutation } from './UpdateOneThingTask.graphql.generated';
import DialogHeader from './DialogHeader';
import TimeLeft from './TimeLeft';
import type { OneThingTaskForUpdate } from './OneThingCreateTaskButton.types';

type FormValues = {
  completionRate: number;
};

const formId = 'task';

type Props = {
  isOpen: boolean;
  isReadOnly: boolean;
  onClose: () => void;
  task: OneThingTaskForUpdate;
};

const UpdateTaskDialog = ({ task, isOpen, onClose, isReadOnly }: Props) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const { addToast } = useToasts();

  const onError = useHandleError();
  const [updateOneThingTask, { loading }] = useUpdateCreateOneThingTaskMutation(
    {
      onError,
    },
  );

  const handleSubmit = async ({ completionRate }: FormValues) => {
    if (loading) return;

    const result = await updateOneThingTask({
      refetchQueries: [
        ContributionMyContributionDocument,
        ContributionMyFollowUpsViewMoreDocument,
      ],
      variables: {
        input: {
          orgKey: teamAdapter.orgKey,
          idToUpdate: task.id,
          completionRate,
        },
      },
    });

    if (result.data?.saveOneThingTask.id) {
      onClose();
      addToast({
        variant: 'success',
        children: t('toolkit.toolPage.oneThing.updateTask.toast.success'),
      });
    }
  };

  const validationSchema = yup.object({
    completionRate: yup.number().required(),
  });

  const deadline = task.campaign.deadline || task.campaign.timeLine.endDate;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      headerContent={<DialogHeader task={task} isReadOnly={isReadOnly} />}
      confirmLabel={isReadOnly ? undefined : t('update')}
      isConfirmDisabled={loading}
      cancelLabel={isReadOnly ? t('close') : t('cancel')}
      onCancel={onClose}
      formId={formId}
      footerPreContent={
        !isReadOnly && deadline && <TimeLeft deadline={deadline} />
      }
    >
      <Form<FormValues>
        id={formId}
        initialValues={{ completionRate: task.completionRate }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <FieldBox
          name={'completionRate'}
          label={t('toolkit.toolPage.oneThing.updateTask.completionRate')}
        >
          <Flex gap={'1rem'}>
            <Field
              name={'completionRate'}
              component={TextInputField}
              min={0}
              max={100}
              size={'small'}
              type={'number'}
              disabled={isReadOnly}
            />
            <div css={css({ width: '100%', flexGrow: 1 })}>
              <Field
                component={SliderField2}
                size={'small'}
                name={'completionRate'}
                marks={{
                  0: 0,
                  25: 25,
                  50: 50,
                  75: 75,
                  100: 100,
                }}
                disabled={isReadOnly}
              />
            </div>
          </Flex>
        </FieldBox>
      </Form>
    </Dialog>
  );
};

export default UpdateTaskDialog;
