import { css } from '@emotion/react';
import { Trans, useTranslation } from 'react-i18next';

import Progress from 'shared/components/Progress';
import { ReactComponent as PieChartIcon } from 'shared/static/icons/icon-pie-chart.svg';

type StatusCompletionRateProgressProps = {
  completionRate: Maybe<number>;
};

const StatusCompletionRateProgress = ({
  completionRate = 0,
}: StatusCompletionRateProgressProps) => {
  const { t } = useTranslation();

  return (
    <Progress
      label={t(
        'initiative.initiativeInsights.milestoneStatuses.milestoneStatus.completionRate',
      )}
      icon={PieChartIcon}
      value={completionRate}
      css={css({ width: 52 })}
    >
      <span>
        <Trans
          i18nKey={'initiative.initiativeInsights.completionRate'}
          values={{
            completionRate,
          }}
        >
          <strong />
        </Trans>
      </span>
    </Progress>
  );
};

export default StatusCompletionRateProgress;
