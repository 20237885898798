import { useToggle } from 'react-use';
import { useTranslation } from 'react-i18next';

import InitiativesContentHeader from 'initiative/InitiativesContent/InitiativesContentHeader';
import Spinner from 'shared/spinner/Spinner';
import InitiativesContentProvider from 'initiative/InitiativesContent/InitiativesContentProvider';
import Layout from 'shared/components/Layout';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import InitiativesContentLayoutProvider from 'initiative/InitiativesContent/InitiativesContentLayoutProvider';
import { useInitiativesContentLayout } from 'initiative/InitiativesContent/InitiativesContentLayoutProvider/useInitiativesContentLayout';
import CalendarControls, {
  CalendarControlsProvider,
} from 'shared/filters/CalendarControls';
import CompletedFilterProvider from 'shared/filters/CompletedFilterButton/CompletedFilterProvider';
import CompletedFilterButton from 'shared/filters/CompletedFilterButton';
import ShowChildrenFilterButton from 'shared/filters/ShowChildrenFilterButton';
import ShowChildrenFilterProvider from 'shared/filters/ShowChildrenFilterButton/ShowChildrenFilterProvider';
import InitiativesContent from 'initiative/InitiativesContent';
import CollaboratingTeamsFilterProvider from 'shared/filters/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';
import FiltersButton from 'shared/filters/FiltersButton';
import StatusFilter, {
  StatusFilterProvider,
} from 'shared/filters/StatusFilter';
import CollaboratingTeamsFilter from 'shared/filters/CollaboratingTeamsFilter';
import TimelineFilter, {
  TimelineFilterProvider,
} from 'shared/filters/TimelineFilter';
import UserFilter, { UserFilterProvider } from 'shared/filters/UserFilter';
import PriorityFilter, {
  PriorityFilterProvider,
} from 'shared/filters/PriorityFilter';
import { ChipGroupProvider } from 'shared/filters/ChipGroup/ChipGroupProvider';
import ChipGroup from 'shared/filters/ChipGroup';

import styles from './StrategyInitiatives.module.scss';
import StrategyInitiativesProvider from './StrategyInitiativesProvider/StrategyInitiativesProvider';
import { useStrategyInitiatives } from './StrategyInitiativesProvider/useStrategyInitiatives';

const StrategyInitiatives = () => {
  const { t } = useTranslation();

  const { objectives, isStrategyInitiativesLoading } = useStrategyInitiatives();

  const { layout } = useInitiativesContentLayout();

  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  return (
    <InitiativesContentProvider objectives={objectives}>
      <Layout.Content
        hasPadding={false}
        className={styles.layout}
        variant={'contained'}
      >
        {isStrategyInitiativesLoading ? (
          <div className={styles.spinnerContainer}>
            <Spinner.Circle />
          </div>
        ) : (
          <>
            <InitiativesContentHeader
              afterStats={<ChipGroup />}
              controls={<CalendarControls />}
              filters={
                <>
                  <CompletedFilterButton />

                  <ShowChildrenFilterButton
                    activeTooltip={t(
                      'filters.showChildrenFilter.tooltip.hide.initiative',
                    )}
                    inactiveTooltip={t(
                      'filters.showChildrenFilter.tooltip.show.initiative',
                    )}
                  />

                  <FiltersButton>
                    <StatusFilter />
                    <PriorityFilter />
                    <UserFilter />
                    <TimelineFilter />
                    <CollaboratingTeamsFilter />
                  </FiltersButton>

                  <ToggleExpandIconButton
                    isExpanded={areAllSectionsExpanded}
                    onToggle={toggleAreAllSectionsExpanded}
                    isDisabled={layout !== 'list'}
                  />
                </>
              }
            />
            <InitiativesContent
              areAllSectionsExpanded={areAllSectionsExpanded}
              sortListByTheme={true}
            />
          </>
        )}
      </Layout.Content>
    </InitiativesContentProvider>
  );
};

export default () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <ShowChildrenFilterProvider storeToSession={true}>
        <ChipGroupProvider>
          <CollaboratingTeamsFilterProvider storeToSession={true}>
            <StatusFilterProvider storeToSession={true}>
              <PriorityFilterProvider storeToSession={true}>
                <UserFilterProvider storeToSession={true}>
                  <TimelineFilterProvider storeToSession={true}>
                    <StrategyInitiativesProvider>
                      <InitiativesContentLayoutProvider>
                        <StrategyInitiatives />
                      </InitiativesContentLayoutProvider>
                    </StrategyInitiativesProvider>
                  </TimelineFilterProvider>
                </UserFilterProvider>
              </PriorityFilterProvider>
            </StatusFilterProvider>
          </CollaboratingTeamsFilterProvider>
        </ChipGroupProvider>
      </ShowChildrenFilterProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
