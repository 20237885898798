import { createContext } from 'react';

export type ChipGroupItem = {
  label: string;
  onRemove: () => void;
};

export type ChipGroupContextValue = {
  chipItems: ChipGroupItem[];
  clearAllChipItems: () => void;
  setChipItems: (
    id: string,
    onRemoveAll: () => void,
    items: ChipGroupItem[],
  ) => void;
};

export const ChipGroupContext = createContext<
  ChipGroupContextValue | undefined
>(undefined);
