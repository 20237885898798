import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { uniqBy } from 'lodash';

import useHandleError from 'shared/errors/useHandleError';

import type { ObjectiveRelatedObjectivesContextValue } from './ObjectiveRelatedObjectivesProvider.context';
import { ObjectiveRelatedObjectivesContext } from './ObjectiveRelatedObjectivesProvider.context';
import { useObjectiveRelatedObjectivesQuery } from './ObjectiveRelatedObjectivesProvider.graphql.generated';

type ObjectiveRelatedObjectivesProviderProps = PropsWithChildren<object>;

const ObjectiveRelatedObjectivesProvider = ({
  children,
}: ObjectiveRelatedObjectivesProviderProps) => {
  const { objectiveId } = useParams() as {
    objectiveId: string;
  };

  const onError = useHandleError();

  const { data, loading: isRelatedObjectivesLoading } =
    useObjectiveRelatedObjectivesQuery({
      variables: {
        objectiveId,
      },
      onError,
    });

  const contextValue = useMemo<ObjectiveRelatedObjectivesContextValue>(() => {
    const relatedObjectives = data?.objective
      ? uniqBy(
          [
            ...[data.objective.parentObjective].filter(Boolean),
            ...(data.objective.parentObjective?.childObjectives || []),
            data.objective,
            ...data.objective.childObjectiveTree,
          ],
          (objective) => objective.id,
        )
      : undefined;

    return {
      objective: data?.objective,
      relatedObjectives,
      isRelatedObjectivesLoading,
    };
  }, [data, isRelatedObjectivesLoading]);

  return (
    <ObjectiveRelatedObjectivesContext.Provider value={contextValue}>
      {children}
    </ObjectiveRelatedObjectivesContext.Provider>
  );
};

export default ObjectiveRelatedObjectivesProvider;
