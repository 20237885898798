import { createRoutePath } from 'shared/utils/router';

export const teamRoutesPaths = {
  root: createRoutePath<{ teamSlug: string }>('/teams/:teamSlug'),
  strategyRoom: createRoutePath<{ teamSlug: string }>(
    `/teams/:teamSlug/strategy-room`,
  ),
  members: {
    root: createRoutePath<{ teamSlug: string }>('/teams/:teamSlug/members'),
  },
  myTeams: createRoutePath<{ teamSlug: string }>('/teams/my-teams'),
  teamHierarchy: createRoutePath<{ teamSlug: string }>(
    `/teams/:teamSlug/team-hierarchy`,
  ),
  strategyBoard: createRoutePath<{ teamSlug: string }>(
    `/teams/:teamSlug/strategy-board`,
  ),
  insights: {
    root: createRoutePath<{
      teamSlug: string;
    }>(`/teams/:teamSlug/insights`),
    overview: createRoutePath<{ teamSlug: string }, { theme?: string }>(
      `/teams/:teamSlug/insights/overview`,
    ),
    reports: {
      root: createRoutePath<{
        teamSlug: string;
      }>(`/teams/:teamSlug/insights/reports`),
      create: createRoutePath<{
        teamSlug: string;
      }>(`/teams/:teamSlug/insights/reports/create`),
      edit: createRoutePath<{
        reportId: string;
        teamSlug: string;
      }>(`/teams/:teamSlug/insights/reports/edit/:reportId`),
      view: createRoutePath<{
        reportId: string;
        teamSlug: string;
      }>(`/teams/:teamSlug/insights/reports/view/:reportId`),
    },
    assessments: {
      root: createRoutePath<{
        teamSlug: string;
      }>(`/teams/:teamSlug/insights/assessments`),
    },
  },
};
