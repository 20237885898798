import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { getNodesFromConnection } from 'shared/graphql/utils';
import useHandleError from 'shared/errors/useHandleError';

import { useSelectThemeInputForOrgQuery } from './SelectThemeInputForOrgProvider.graphql.generated';
import type { SelectThemeInputContextValue } from '../SelectThemeInput.context';
import { SelectThemeInputContext } from '../SelectThemeInput.context';
import {
  getOptions,
  getEmptyOption,
} from './SelectThemeInputForOrgProvider.utils';

type SelectThemeInputForOrgProviderProps = PropsWithChildren<object>;

const SelectThemeInputForOrgProvider = ({
  children,
}: SelectThemeInputForOrgProviderProps) => {
  const onError = useHandleError();

  const { data } = useSelectThemeInputForOrgQuery({ onError });

  const contextValue = useMemo<SelectThemeInputContextValue>(() => {
    if (data) {
      return {
        options: getOptions({
          themes: getNodesFromConnection(data.activeOrg.themes),
        }),
      };
    }
    return {
      options: [getEmptyOption()],
    };
  }, [data]);

  return (
    <SelectThemeInputContext.Provider value={contextValue}>
      {children}
    </SelectThemeInputContext.Provider>
  );
};

export default SelectThemeInputForOrgProvider;
