import { useDefinedContext } from 'shared/utils/context.utils';

import StatusFilterProvider from './ActionStatusFilterProvider';
import StatusFilterContext from './ActionStatusFilter.context';

export const useActionStatusFilter = () =>
  useDefinedContext(StatusFilterContext, {
    hookName: useActionStatusFilter.name,
    providerName: StatusFilterProvider.name,
  });
