import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { transientOptions } from 'shared/utils/emotion.utils';
import Emoji from 'shared/components/Emoji';
import { useUser } from 'user/UserProvider';
import { isSuperAdmin } from 'user/user.utils';
import useHandleError from 'shared/errors/useHandleError';

import Chat from './Chat';
import IndexItem from './IndexItem';
import { useCampaignResponsesQuery } from './CampaignResponses.graphql.generated';
import { useChat } from './ChatProvider';
import { useQuestions } from './QuestionsProvider.context';

const Content = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 100%;
`;

const IndexContainer = styled.div`
  max-width: 230px;
  width: 100%;
`;

const IndexFixedContent = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const ChatContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`;

const FastForwardContainer = styled('div', transientOptions)<{
  $isSkipChatActive: boolean;
}>`
  position: fixed;
  right: 24px;
  bottom: 24px;
  user-select: none;

  cursor: pointer;

  font-size: 3rem;
  opacity: ${(props) => (props.$isSkipChatActive ? 1 : 0.6)};

  &:hover {
    opacity: ${(props) => (props.$isSkipChatActive ? 1 : 0.75)};
  }
`;

const CampaignResponseContent = () => {
  const { user } = useUser();
  const { questions, currentQuestion, setCurrentQuestion, isReadOnly } =
    useQuestions();
  const { campaignId } = useParams<{ campaignId: string }>();
  const [continueInitialized, setContinueInitialized] = useState(false);
  const [isChatSkipActive, setIsChatSkipActive] = useState(false);

  if (!campaignId) throw new Error('campaignId is missing');

  const onError = useHandleError();

  const { data } = useCampaignResponsesQuery({
    variables: { campaignId },
    onError,
  });

  const { allMessages, visibleMessages } = useChat();

  useEffect(() => {
    if (!data || isReadOnly || continueInitialized) {
      return;
    }

    setContinueInitialized(true);

    if (data?.campaign.currentUserResponse?.status !== 'IN_PROGRESS') {
      return;
    }

    const nextQuestion = questions.find(
      (question) =>
        question.type &&
        !data.campaign.currentUserResponse?.answers.some(
          (answer) => answer.question.questionType === question.type,
        ),
    );

    if (nextQuestion) {
      setCurrentQuestion(nextQuestion);
    } else {
      const lastQuestion = questions[questions.length - 1];
      setCurrentQuestion(lastQuestion);
    }
  }, [continueInitialized, data, isReadOnly, questions, setCurrentQuestion]);

  const messages = isReadOnly ? allMessages : visibleMessages;

  useEffect(() => {
    setIsChatSkipActive(false);
  }, [currentQuestion]);

  if (!data) return null;

  const showFastForwardButton = isSuperAdmin(user) && !isReadOnly;

  return (
    <>
      <Content>
        <IndexContainer>
          <IndexFixedContent>
            {questions.map((question) => (
              <IndexItem key={question.id} question={question} />
            ))}
          </IndexFixedContent>
        </IndexContainer>
        <ChatContainer>
          <Chat messages={messages} isChatSkipActive={isChatSkipActive} />
        </ChatContainer>
      </Content>
      {showFastForwardButton && (
        <FastForwardContainer
          $isSkipChatActive={isChatSkipActive}
          onClick={() => setIsChatSkipActive(!isChatSkipActive)}
        >
          <Emoji icon={'⏩'} />
        </FastForwardContainer>
      )}
    </>
  );
};

export default CampaignResponseContent;
