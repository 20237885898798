import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';

import useSearchParamState from 'shared/hooks/useSearchParamState';

import type { CompletedFilterValue } from './CompletedFilter.context';
import CompletedFilterContext from './CompletedFilter.context';

type Props = PropsWithChildren<{
  storeToSession: boolean;
}>;

const CompletedFilterProvider = ({ storeToSession, children }: Props) => {
  const [showCompleted, setShowCompleted] = useSearchParamState<boolean>(
    'showCompleted',
    false,
    storeToSession,
    JSON.parse,
  );

  const toggleShowCompleted = useCallback(() => {
    setShowCompleted(!showCompleted);
  }, [setShowCompleted, showCompleted]);

  const value = useMemo<CompletedFilterValue>(
    () => ({ showCompleted: showCompleted ?? false, toggleShowCompleted }),
    [showCompleted, toggleShowCompleted],
  );

  return (
    <CompletedFilterContext.Provider value={value}>
      {children}
    </CompletedFilterContext.Provider>
  );
};

export default CompletedFilterProvider;
