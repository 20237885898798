import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { PromptMessage } from 'ai/AskSophieChat';

export default function useAssessmentPromptMessages() {
  const { t } = useTranslation();

  return useMemo<PromptMessage[]>(
    () =>
      [
        {
          buttonLabel: t('ai.strategySummary.button.pestel'),
          mainPromptKey: 'AI_ANALYSIS_PESTEL',
          numberOfCharacters: 5000,
          assessmentType: 'PESTEL',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.porters'),
          mainPromptKey: 'AI_ANALYSIS_PORTERS_FIVE_FORCES',
          numberOfCharacters: 5000,
          assessmentType: 'PORTERS_FIVE_FORCES',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.customerSegmentation'),
          mainPromptKey: 'AI_ANALYSIS_CUSTOMER_SEGMENTATION_FRAMEWORK',
          numberOfCharacters: 5000,
          assessmentType: 'CUSTOMER_SEGMENTATION',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.doubleMateriality'),
          mainPromptKey: 'AI_ANALYSIS_DOUBLE_MATERIALITY',
          numberOfCharacters: 5000,
          assessmentType: 'DOUBLE_MATERIALITY',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.stakeholderAnalysis'),
          mainPromptKey: 'AI_ANALYSIS_STAKEHOLDER_ANALYSIS',
          numberOfCharacters: 5000,
          assessmentType: 'STAKEHOLDER_ANALYSIS',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.ansoff'),
          mainPromptKey: 'AI_ANALYSIS_ANSOFF_MATRIX',
          numberOfCharacters: 5000,
          assessmentType: 'ANSOFF',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.blueOcean'),
          mainPromptKey: 'AI_ANALYSIS_BLUE_OCEAN',
          numberOfCharacters: 5000,
          assessmentType: 'BLUE_OCEAN',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.vrio'),
          mainPromptKey: 'AI_ANALYSIS_VRIO',
          numberOfCharacters: 5000,
          assessmentType: 'VRIO',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.competitiveStrategies'),
          mainPromptKey: 'AI_ANALYSIS_COMPETITIVE_STRATEGIES',
          numberOfCharacters: 5000,
          assessmentType: 'COMPETITIVE_STRATEGIES',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.mAndA'),
          mainPromptKey: 'AI_ANALYSIS_M_AND_A',
          numberOfCharacters: 5000,
          assessmentType: 'M_AND_A',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.valueChain'),
          mainPromptKey: 'AI_ANALYSIS_VALUE_CHAIN',
          numberOfCharacters: 5000,
          assessmentType: 'VALUE_CHAIN',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.playingToWin'),
          mainPromptKey: 'AI_ANALYSIS_PLAYING_TO_WIN',
          numberOfCharacters: 5000,
          assessmentType: 'PLAYING_TO_WIN',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.customerJourney'),
          mainPromptKey: 'AI_ANALYSIS_CUSTOMER_JOURNEY',
          numberOfCharacters: 5000,
          assessmentType: 'CUSTOMER_JOURNEY',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.threeHorizons'),
          mainPromptKey: 'AI_ANALYSIS_THREE_HORIZONS',
          numberOfCharacters: 5000,
          assessmentType: 'THREE_HORIZONS',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.swot'),
          mainPromptKey: 'AI_ANALYSIS_SWOT',
          numberOfCharacters: 5000,
          assessmentType: 'SWOT',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.bscSwot'),
          mainPromptKey: 'AI_ANALYSIS_BSC_SWOT',
          numberOfCharacters: 5000,
          assessmentType: 'BSC_SWOT',
          category: 'strategyAnalysis',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.portfolioAnalysis'),
          mainPromptKey: 'AI_ANALYSIS_PORTFOLIO_ANALYSIS',
          numberOfCharacters: 5000,
          assessmentType: 'GE_PORTFOLIO_ANALYSIS',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.bscStrategyMap'),
          mainPromptKey: 'AI_ANALYSIS_BSC_STRATEGY_MAP',
          numberOfCharacters: 5000,
          assessmentType: 'BSC_STRATEGY_MAP',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.bscStrategyBoard'),
          mainPromptKey: 'AI_ANALYSIS_BSC_STRATEGY_BOARD',
          numberOfCharacters: 5000,
          assessmentType: 'BSC_STRATEGY_BOARD',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.strategyDiamond'),
          mainPromptKey: 'AI_ANALYSIS_STRATEGY_DIAMOND',
          numberOfCharacters: 5000,
          assessmentType: 'STRATEGY_DIAMOND',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.businessCanvas'),
          mainPromptKey: 'AI_ANALYSIS_BUSINESS_CANVAS',
          numberOfCharacters: 5000,
          assessmentType: 'BUSINESS_CANVAS',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.mckinsey'),
          mainPromptKey: 'AI_ANALYSIS_MCKINSEY_7S',
          numberOfCharacters: 5000,
          assessmentType: 'MCKINSEY_7S',
          category: 'stressTest',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.riskFramework'),
          mainPromptKey: 'AI_ANALYSIS_RISK_FRAMEWORK',
          numberOfCharacters: 5000,
          assessmentType: 'RISK_FRAMEWORKS',
          category: 'risk',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.strategicRisks'),
          mainPromptKey: 'AI_ANALYSIS_STRATEGIC_RISKS',
          numberOfCharacters: 5000,
          assessmentType: 'STRATEGIC_RISKS',
          category: 'risk',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.objectiveRisks'),
          mainPromptKey: 'AI_ANALYSIS_OBJECTIVE_RISKS',
          numberOfCharacters: 5000,
          assessmentType: 'OBJECTIVE_RISKS',
          category: 'risk',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.riskMap'),
          mainPromptKey: 'AI_ANALYSIS_RISK_MAP',
          numberOfCharacters: 5000,
          assessmentType: 'RISK_MAP',
          category: 'risk',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.unsdg'),
          mainPromptKey: 'AI_ANALYSIS_UNSDG',
          numberOfCharacters: 5000,
          assessmentType: 'UNSDG',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.esg'),
          mainPromptKey: 'AI_ANALYSIS_ESG',
          numberOfCharacters: 5000,
          assessmentType: 'ESG',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.objectives'),
          mainPromptKey: 'AI_ANALYSIS_SUSTAINABILITY_STRATEGY_MAP',
          numberOfCharacters: 5000,
          assessmentType: 'OBJECTIVES',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.frameworks'),
          mainPromptKey: 'AI_ANALYSIS_SUSTAINABILITY_FRAMEWORKS',
          numberOfCharacters: 5000,
          assessmentType: 'FRAMEWORKS',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.taxonomy'),
          mainPromptKey: 'AI_ANALYSIS_EU_TAXONOMY',
          numberOfCharacters: 5000,
          assessmentType: 'TAXONOMY',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.csrd'),
          mainPromptKey: 'AI_ANALYSIS_CSRD',
          numberOfCharacters: 5000,
          assessmentType: 'CSRD',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.tcfd'),
          mainPromptKey: 'AI_ANALYSIS_TCFD',
          numberOfCharacters: 5000,
          assessmentType: 'TCFD',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.sbti'),
          mainPromptKey: 'AI_ANALYSIS_SBTI',
          numberOfCharacters: 5000,
          assessmentType: 'SB_TI',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.gri'),
          mainPromptKey: 'AI_ANALYSIS_GRI',
          numberOfCharacters: 5000,
          assessmentType: 'GRI',
          category: 'sustainability',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.futureScenario'),
          mainPromptKey: 'AI_ANALYSIS_TWO_BY_TWO_SCENARIO',
          numberOfCharacters: 5000,
          assessmentType: 'TWO_BY_TWO',
          category: 'future',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.futureOpportunities'),
          mainPromptKey: 'AI_ANALYSIS_FUTURE_OPPORTUNITIES',
          numberOfCharacters: 5000,
          assessmentType: 'FUTURE_OPPORTUNITIES',
          category: 'future',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.financialHealth'),
          mainPromptKey: 'AI_ANALYSIS_FINANCIAL_HEALTH',
          numberOfCharacters: 5000,
          assessmentType: 'FINANCIAL_HEALTH',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.financialBenchmark'),
          mainPromptKey: 'AI_ANALYSIS_FINANCIAL_BENCHMARK',
          numberOfCharacters: 5000,
          assessmentType: 'FINANCIAL_BENCHMARK',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.revenue'),
          mainPromptKey: 'AI_ANALYSIS_REVENUE_STREAMS',
          numberOfCharacters: 5000,
          assessmentType: 'REVENUE_ANALYSIS',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.financialGrowth'),
          mainPromptKey: 'AI_ANALYSIS_FINANCIAL_GROWTH',
          numberOfCharacters: 5000,
          assessmentType: 'FINANCIAL_GROWTH',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.financialRisk'),
          mainPromptKey: 'AI_ANALYSIS_FINANCIAL_RISK',
          numberOfCharacters: 5000,
          assessmentType: 'FINANCIAL_RISK',
          category: 'financial',
        } satisfies PromptMessage,
        {
          buttonLabel: t('ai.strategySummary.button.stratex'),
          mainPromptKey: 'AI_ANALYSIS_STRATEX',
          numberOfCharacters: 5000,
          assessmentType: 'STRATEX',
          category: 'financial',
        } satisfies PromptMessage,
      ].filter(Boolean),
    [t],
  );
}
