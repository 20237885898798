import { useTranslation } from 'react-i18next';

import { ReactComponent as CompletedStatusMarkIcon } from 'shared/static/icons/icon-status-mark-completed.svg';
import ToggleFilterButton from 'shared/components/ToggleFilterButton';

import { useCompletedFilter } from './useCompletedFilter';

const CompletedFilterButton = () => {
  const { t } = useTranslation();
  const { showCompleted, toggleShowCompleted } = useCompletedFilter();

  return (
    <ToggleFilterButton
      Icon={CompletedStatusMarkIcon}
      activeTooltip={t('filters.completedFilter.tooltip.hide')}
      inactiveTooltip={t('filters.completedFilter.tooltip.show')}
      isActive={showCompleted}
      onToggleActive={toggleShowCompleted}
    />
  );
};

export default CompletedFilterButton;
