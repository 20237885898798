import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import Heading from 'shared/components/Heading';
import Layout from 'shared/components/Layout';
import Text from 'shared/components/Text';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import WalkOfStrategyCreateCampaignButton from 'toolkit/WalkOfStrategyCreateCampaignButton';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';
import useCanPerformOrgAction from 'user/ability/useCanPerformOrgAction';

import { useWalkOfStrategyCampaignsQuery } from './WalkOfStrategyCampaigns.graphql.generated';
import CampaignsTable from './CampaignsTable';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 20px;
`;

const Top = styled.div`
  display: flex;
  padding: 24px;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: center;
`;

const WalkOfStrategyCampaigns = () => {
  const { t } = useTranslation();

  const onError = useHandleError();

  const { data } = useWalkOfStrategyCampaignsQuery({ onError });

  const canWriteOrgStrategy = useCanPerformOrgAction('WRITE_STRATEGY');

  if (!data) return <Spinner.Circle />;

  const campaigns = data.allCampaigns.edges
    .map((edge) => edge.node)
    .filter((campaign) =>
      campaign.orgUnit
        ? canPerformOrgUnitAction(campaign.orgUnit, 'WRITE')
        : canWriteOrgStrategy,
    );

  return (
    <Layout.Content hasPadding={false} hasBorder={true}>
      <Container>
        <Top>
          <Heading level={4} hasMargin={false}>
            {t('toolkit.toolPage.walkOfStrategy.campaigns.title')}
          </Heading>
          <div css={css({ flexGrow: 1 })} />
          <WalkOfStrategyCreateCampaignButton />
        </Top>

        <Bottom>
          {campaigns.length === 0 ? (
            <Text css={css({ padding: 24 })}>
              {t('toolkit.toolPage.walkOfStrategy.campaigns.empty')}
            </Text>
          ) : (
            <CampaignsTable campaigns={campaigns} />
          )}
        </Bottom>
      </Container>
    </Layout.Content>
  );
};

export default WalkOfStrategyCampaigns;
