import Spinner from 'shared/spinner/Spinner';
import ActivityTimeline from 'shared/components/ActivityTimeline';
import type { RenderActivityTimelineRowContentParams } from 'shared/components/ActivityTimeline/ActivityTimeline.type';
import useHandleError from 'shared/errors/useHandleError';

import { useContributionMyFollowUpsViewMoreQuery } from './ContributionMyFollowUpsViewMore.graphql.generated';
import type { FollowUpActivityItem } from './ContributionMyFollowUpsViewMore.type';
import TimelineEntry from './TimelineEntry';
import useMyFollowUpItems from './useMyFollowUpItems';
import NoFollowUps from './NoFollowUps';

const Content = () => {
  const onError = useHandleError();

  const { data } = useContributionMyFollowUpsViewMoreQuery({ onError });

  const items = useMyFollowUpItems(
    false,
    data?.currentUser.notifications.statusRequests,
    data?.currentUser.notifications.campaignRequests,
    data?.currentUser.notifications.oneThingCampaignRequests,
  );

  if (!data) {
    return <Spinner.Circle />;
  }

  if (items.length === 0) {
    const hasCompletedItems =
      data.currentUser.notifications.statusRequests.length > 0;

    return <NoFollowUps hasCompletedItems={hasCompletedItems} />;
  } else {
    return (
      <ActivityTimeline
        items={items}
        itemDateKey={'requestSubmitTime'}
        renderRowContent={renderRowContent}
      />
    );
  }
};

const renderRowContent = (
  row: RenderActivityTimelineRowContentParams<FollowUpActivityItem>,
) => (
  <TimelineEntry key={row.item.id} item={row.item} isLastRow={row.isLastRow} />
);

export default Content;
