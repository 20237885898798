import type { PropsWithChildren } from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useHandleError from 'shared/errors/useHandleError';
import { StrategyRoomThemesManageThemesDocument } from 'strategy/StrategyRoomThemesManageThemes/StrategyRoomThemesManageThemes.graphql.generated';
import { StrategyRoomThemesManageObjectivesDocument } from 'strategy/StrategyRoomThemesManageObjectives/StrategyRoomThemesManageObjectives.graphql.generated';

import type { ThemeContextValue } from './ThemeProvider.context';
import { ThemeContext } from './ThemeProvider.context';
import {
  useUpdateThemeMutation,
  useDeleteThemeMutation,
  useThemeQuery,
} from './ThemeProvider.graphql.generated';
import { useTeamAdapter } from '../../team/TeamAdapter';
import type { Theme } from '../../types.graphql.generated';
import { useToasts } from '../../shared/toast/useToasts';

type ThemesProviderProps = PropsWithChildren<object> & {
  themeId?: Maybe<Theme['id']>;
};

const ThemeProvider = ({ children, themeId }: ThemesProviderProps) => {
  const { teamAdapter } = useTeamAdapter();
  const { addToast } = useToasts();
  const { t } = useTranslation();

  const onError = useHandleError();

  const { data, loading } = useThemeQuery({
    variables: { themeId: themeId! },
    skip: !themeId,
    onError,
  });

  const [updateThemeMutation] = useUpdateThemeMutation({
    onError,
  });

  const [deleteThemeMutation] = useDeleteThemeMutation({
    onError,
    refetchQueries: [
      StrategyRoomThemesManageObjectivesDocument,
      StrategyRoomThemesManageThemesDocument,
    ],
  });

  const updateTheme = useCallback(
    async (delta: any) => {
      if (themeId) {
        await updateThemeMutation({
          variables: {
            input: {
              idToUpdate: themeId,
              ...teamAdapter.toInput(),
              name: delta.name,
              description: delta.description,
              isActive: delta.isActive,
              themeCategory: delta.themeCategory,
              orderNumber: delta.orderNumber,
            },
          },
        });
        addToast({
          children: t('theme.toast.edit.success', { themeName: delta.name }),
          variant: 'success',
        });
      }
    },
    [teamAdapter, themeId, updateThemeMutation, addToast, t],
  );

  const deleteTheme = useCallback(async () => {
    if (themeId) {
      await deleteThemeMutation({
        variables: { themeId: [themeId] },
      });
      addToast({
        children: t('theme.toast.delete.success'),
        variant: 'success',
      });
    }
  }, [themeId, deleteThemeMutation, addToast, t]);

  const contextValue = useMemo<ThemeContextValue | undefined>(
    () => ({
      updateTheme,
      deleteTheme,
      theme: data?.theme || null,
    }),
    [data?.theme, updateTheme, deleteTheme],
  );

  if (!contextValue || loading) {
    return null;
  }

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
