import type { ListState } from 'react-stately';
import { Trans, useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';
import { useMemo } from 'react';

import Space from 'shared/components/Space';
import Text from 'shared/components/Text';

import type {
  ActionStatusFilterSelectItem,
  ActionStatusFilterSelectItemId,
} from './ActionStatusFilter';

type Props = {
  state: ListState<ActionStatusFilterSelectItem>;
};

const ActionStatusFilterLabel = ({ state }: Props) => {
  const { t } = useTranslation();

  const content = useMemo<ReactNode>(() => {
    const selectedKeys = [
      ...state.selectionManager.selectedKeys,
    ] as ActionStatusFilterSelectItemId[];

    if (selectedKeys.length === 1 && selectedKeys.at(0) === 'ALL') {
      return (
        <Trans
          i18nKey={'actions.actionStatusMultiSelect.labelWithValue'}
          values={{ value: t('status.allStatus') }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      );
    }

    if (selectedKeys.length === 0) {
      return (
        <Trans
          i18nKey={'actions.actionStatusMultiSelect.labelWithValue'}
          values={{ value: t('none') }}
        >
          <Text variant={'emphasis'} />
        </Trans>
      );
    }

    return (
      <Trans
        i18nKey={'actions.actionStatusMultiSelect.labelWithStatusCount'}
        values={{ statusCount: selectedKeys.length }}
      >
        <Text variant={'emphasis'} />
      </Trans>
    );
  }, [state, t]);

  return (
    <Space size={'small'}>
      <Text>{content}</Text>
    </Space>
  );
};

export default ActionStatusFilterLabel;
