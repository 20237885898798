import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMultiSearchParamState from 'shared/hooks/useMultiSearchParamState';

import { useChipGroup } from '../ChipGroup';
import type {
  ActionStatusFilterContextValue,
  ActionStatusFilterValue,
} from './ActionStatusFilter.context';
import { getActionStatusLabel } from './ActionStatusFilter.utils';
import ActionStatusFilterContext from './ActionStatusFilter.context';

const filterName = 'actionStatus';

type Props = PropsWithChildren<{ storeToSession: boolean }>;

const ActionStatusFilterProvider = ({ storeToSession, children }: Props) => {
  const { t } = useTranslation();

  const [
    actionStatuses,
    setActionStatuses,
    clearActionStatuses,
    addActionStatus,
    removeActionStatus,
  ] = useMultiSearchParamState<ActionStatusFilterValue>(
    filterName,
    [],
    storeToSession,
  );

  const { setChipItems } = useChipGroup();

  useEffect(
    () =>
      setChipItems(
        filterName,
        clearActionStatuses,
        actionStatuses.map((status) => ({
          label: getActionStatusLabel(t, status),
          onRemove: () => removeActionStatus(status),
        })),
      ),
    [actionStatuses, clearActionStatuses, removeActionStatus, setChipItems, t],
  );

  const value = useMemo<ActionStatusFilterContextValue>(
    () => ({
      actionStatuses,
      setActionStatuses,
      clearActionStatuses,
      addActionStatus,
      removeActionStatus,
    }),
    [
      actionStatuses,
      addActionStatus,
      clearActionStatuses,
      removeActionStatus,
      setActionStatuses,
    ],
  );

  return (
    <ActionStatusFilterContext.Provider value={value}>
      {children}
    </ActionStatusFilterContext.Provider>
  );
};

export default ActionStatusFilterProvider;
