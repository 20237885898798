import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { sortBy, uniqBy } from 'lodash';

import Spinner from 'shared/spinner/Spinner';
import type { TeamAdapter } from 'team/TeamAdapter';
import useHandleError from 'shared/errors/useHandleError';
import {
  testStrategyItemAgainstCollaboratingTeams,
  testStrategyItemAgainstInterval,
} from 'strategy/strategy.utils';
import { useCalendarControls } from 'shared/filters/CalendarControls/useCalendarControls';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import {
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';
import { useCollaboratingTeamsFilter } from 'shared/filters/CollaboratingTeamsFilter';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useUserFilter } from 'shared/filters/UserFilter';
import { useThemeFilter } from 'shared/filters/ThemeFilter';
import {
  testStatusIndicatorAgainstIds,
  useStatusFilter,
} from 'shared/filters/StatusFilter';
import { useTimelineFilter } from 'shared/filters/TimelineFilter';
import {
  testPriorityFilters,
  usePriorityFilter,
} from 'shared/filters/PriorityFilter';
import { useCompletedFilter } from 'shared/filters/CompletedFilterButton/useCompletedFilter';

import type {
  SelectableStrategyItemsObjectiveFragment,
  SelectableStrategyItemsThemeFragment,
} from './SelectableStrategyItemsProvider.graphql.generated';
import {
  useSelectableStrategyItemsForOrgQuery,
  useSelectableStrategyItemsForOrgUnitQuery,
} from './SelectableStrategyItemsProvider.graphql.generated';
import type { SelectableStrategyItemsProviderContextValue } from './SelectableStrategyItemsProvider.context';
import { SelectableStrategyItemsProviderContext } from './SelectableStrategyItemsProvider.context';
import { getThemes } from '../SelectableStrategyItems.utils';

type SelectableStrategyItemsProviderProps = PropsWithChildren<{
  teamAdapter: TeamAdapter;
}>;

const SelectableStrategyItemsProvider = ({
  teamAdapter,
  children,
}: SelectableStrategyItemsProviderProps) => {
  const { orgUnit } = useOrgUnit();

  const { isPrioritiesLoading } = useStrategyProfilePriorities();

  const onError = useHandleError();

  const { data: orgData } = useSelectableStrategyItemsForOrgQuery({
    skip: teamAdapter.isOrgUnit,
    onError,
  });

  const { data: orgUnitData } = useSelectableStrategyItemsForOrgUnitQuery({
    skip: teamAdapter.isOrg,
    variables: { orgUnitId: teamAdapter.keyArg },
    onError,
  });

  const objectivesConnection = teamAdapter.isOrg
    ? orgData?.activeOrg.objectives
    : orgUnitData?.orgUnit.objectives;

  const objectives = objectivesConnection?.edges.map((edge) => edge.node);

  const { statuses } = useStatusFilter();

  const { selectedThemeIds, setAvailableThemes } = useThemeFilter();

  const { selectedUserIds, setAvailableUsers } = useUserFilter();

  const { startDate, endDate } = useTimelineFilter();

  const { priorities } = usePriorityFilter();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  const filteredObjectives = useMemo(
    () =>
      (objectives || []).filter(
        (objective) =>
          (selectedThemeIds.length === 0 ||
            (objective.theme &&
              selectedThemeIds.includes(objective.theme.id))) &&
          testStatusIndicatorAgainstIds(
            statuses,
            objective.currentObjectiveStatus?.statusIndicator,
          ) &&
          (showCompleted || !objective.currentObjectiveStatus?.complete) &&
          testPriorityFilters(priorities, objective.priority) &&
          (selectedUserIds.length === 0 ||
            (objective.owner &&
              selectedUserIds.includes(objective.owner.id))) &&
          testStrategyItemAgainstInterval(objective, {
            start: startDate,
            end: endDate,
          }) &&
          testStrategyItemAgainstInterval(
            objective,
            {
              start: calendarIntervalStartDate,
              end: calendarIntervalEndDate,
            },
            calendarCheck,
          ) &&
          testStrategyItemAgainstCollaboratingTeams(
            objective,
            showCollaboratingTeams,
            orgUnit?.id,
          ),
      ),
    [
      calendarCheck,
      calendarIntervalEndDate,
      calendarIntervalStartDate,
      endDate,
      objectives,
      orgUnit?.id,
      priorities,
      selectedThemeIds,
      selectedUserIds,
      showCollaboratingTeams,
      showCompleted,
      startDate,
      statuses,
    ],
  );

  const filteredObjectivesByThemes = useMemo(() => {
    const objectivesByThemes = groupObjectivesByTheme<
      SelectableStrategyItemsObjectiveFragment,
      SelectableStrategyItemsThemeFragment
    >(filteredObjectives);

    return sortObjectivesByTheme(
      objectivesByThemes,
      orgUnitData
        ? [
            ...orgUnitData.orgUnit.parentOrgUnitTree,
            orgUnitData.orgUnit,
          ].reverse()
        : [],
    );
  }, [filteredObjectives, orgUnitData]);

  const contextValue = useMemo<
    SelectableStrategyItemsProviderContextValue | undefined
  >(
    () =>
      !isPrioritiesLoading && objectives
        ? {
            objectives,
            filteredObjectives,
            filteredObjectivesByThemes,
          }
        : undefined,
    [
      filteredObjectives,
      filteredObjectivesByThemes,
      isPrioritiesLoading,
      objectives,
    ],
  );

  const themes = useMemo(
    () => (objectives ? getThemes(objectives) : []),
    [objectives],
  );

  const users = sortBy(
    uniqBy(
      (objectives || [])
        .filter((objective) => objective.owner)
        .map((objective) => objective.owner),
      (user) => user?.id,
    ),
    (user) => user?.displayName || user?.email,
  ).filter(Boolean);

  useEffect(() => {
    setAvailableThemes(themes);
  }, [setAvailableThemes, themes]);

  useEffect(() => {
    setAvailableUsers(users);
  }, [users, setAvailableUsers]);

  if (contextValue) {
    return (
      <SelectableStrategyItemsProviderContext.Provider value={contextValue}>
        {children}
      </SelectableStrategyItemsProviderContext.Provider>
    );
  }

  return <Spinner.Circle />;
};

export default SelectableStrategyItemsProvider;
