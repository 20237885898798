import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import type { ActionsSort } from 'actions/actions.type';

import type { ActionsLayoutType } from './ActionsLayoutProvider.type';

export type ActionsLayoutContextValue = {
  actionsSort: ActionsSort;
  layout: ActionsLayoutType;
  setActionsSort: Dispatch<SetStateAction<ActionsSort>>;
  toggleLayout: () => void;
};

export const ActionsLayoutContext = createContext<
  ActionsLayoutContextValue | undefined
>(undefined);
