import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import { useToasts } from 'shared/toast/useToasts';
import Dialog from 'shared/components/Dialog';
import useHandleError from 'shared/errors/useHandleError';
import type { UserInput } from 'types.graphql.generated';
import { userFormId } from 'user/UserProfileForm/UserProfileForm';
import {
  TeamMembersFromOrgDocument,
  TeamMembersFromOrgUnitDocument,
} from 'team/TeamMembers/TeamMembersProvider/TeamMembersProvider.graphql.generated';

import { useUpdateReportNameMutation } from './UpdateReportName.graphql.generated';
import { useEditReportNameDialogQuery } from './EditReportNameDialog.graphql.generated';
import ReportNameForm from './ReportNameForm';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  reportId: string;
};

const EditReportNameDialog = ({ reportId, isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();

  const onError = useHandleError();

  const { data } = useEditReportNameDialogQuery({
    variables: { reportId },
    onError,
    skip: !isOpen,
  });

  const [updateReportName, { loading }] = useUpdateReportNameMutation({
    onError,
    refetchQueries: [
      TeamMembersFromOrgDocument,
      TeamMembersFromOrgUnitDocument,
    ],
  });

  const handleSubmit = useCallback(
    async (input: UserInput) => {
      const result = await updateReportName({ variables: { input } });

      if (!result.errors) {
        addToast({
          children: t('report.editReportName.success'),
          variant: 'success',
        });
        onClose();
      }
    },
    [addToast, onClose, t, updateReportName],
  );

  const initialValues = useMemo(
    () =>
      data
        ? {
            id: data.report.id,
            name: data.report.name || '',
          }
        : undefined,
    [data],
  );

  return (
    <Dialog
      headerTitle={t('report.editReportName.title')}
      isOpen={isOpen}
      onClose={onClose}
      confirmLabel={t('update')}
      cancelLabel={t('cancel')}
      size={'small'}
      formId={userFormId}
      isConfirmDisabled={loading}
    >
      {initialValues ? (
        <ReportNameForm initialValues={initialValues} onSubmit={handleSubmit} />
      ) : (
        <Spinner.Circle />
      )}
    </Dialog>
  );
};

export default EditReportNameDialog;
