import { createContext } from 'react';

import type {
  StrategyBoardObjective,
  StrategyBoardTheme,
} from '../StrategyBoard.type';

export type StrategyBoardContextValue = {
  isStrategyBoardLoading: boolean;
  objectives: StrategyBoardObjective[];
  themes: StrategyBoardTheme[];
};

export const StrategyBoardContext = createContext<
  StrategyBoardContextValue | undefined
>(undefined);
