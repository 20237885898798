import type { PropsWithChildren } from 'react';
import { useEffect, useState, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { ActionsSort } from 'actions/actions.type';
import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';
import Spinner from 'shared/spinner/Spinner';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import { useUserFilter } from 'shared/filters/UserFilter';

import type { ActionsLayoutContextValue } from './ActionsLayoutProvider.context';
import { ActionsLayoutContext } from './ActionsLayoutProvider.context';
import type { ActionsLayoutType } from './ActionsLayoutProvider.type';
import { getActionsOwners } from './ActionsLayoutProvider.utils';

const defaultLayout = 'grid';

type ActionsProviderProps = PropsWithChildren<{
  objectives?: Maybe<ActionsObjectiveFragment[]>;
}>;

const ActionsLayoutProvider = ({
  children,
  objectives,
}: ActionsProviderProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { isPrioritiesLoading } = useStrategyProfilePriorities();

  const { setAvailableUsers } = useUserFilter();

  const [actionsSort, setActionsSort] = useState<ActionsSort>('deadlineAsc');

  useEffect(() => {
    if (!searchParams.has('view')) {
      setSearchParams({ view: defaultLayout }, { replace: true });
    }
  });

  useEffect(() => {
    if (objectives) {
      setAvailableUsers(getActionsOwners(objectives));
    }
  }, [objectives, setAvailableUsers]);

  const contextValue = useMemo<ActionsLayoutContextValue>(
    () => ({
      layout: (searchParams.get('view') ?? defaultLayout) as ActionsLayoutType,
      toggleLayout:
        searchParams.get('view') === defaultLayout
          ? () => setSearchParams({ view: 'list' })
          : () => setSearchParams({ view: defaultLayout }),
      actionsSort,
      setActionsSort,
    }),
    [actionsSort, searchParams, setSearchParams],
  );

  if (isPrioritiesLoading) return <Spinner.Circle />;

  return (
    <ActionsLayoutContext.Provider value={contextValue}>
      {children}
    </ActionsLayoutContext.Provider>
  );
};

export default ActionsLayoutProvider;
