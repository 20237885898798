import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { OrgUnit } from 'types.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';

import type { SelectThemeInputContextValue } from '../SelectThemeInput.context';
import { SelectThemeInputContext } from '../SelectThemeInput.context';
import { useSelectThemeInputForOrgUnitQuery } from './SelectThemeInputForOrgUnitProvider.graphql.generated';
import {
  getOptions,
  getEmptyOption,
} from './SelectThemeInputForOrgUnitProvider.utils';

type SelectThemeInputForOrgUnitProviderProps = PropsWithChildren<{
  orgUnitId: OrgUnit['id'];
}>;

const SelectThemeInputForOrgUnitProvider = ({
  children,
  orgUnitId,
}: SelectThemeInputForOrgUnitProviderProps) => {
  const onError = useHandleError();

  const { data } = useSelectThemeInputForOrgUnitQuery({
    variables: { orgUnitId },
    onError,
  });

  const contextValue = useMemo<SelectThemeInputContextValue>(() => {
    if (data) {
      return {
        options: getOptions({
          org: data.activeOrg,
          orgUnit: data.orgUnit,
        }),
      };
    }
    return {
      options: [getEmptyOption()],
    };
  }, [data]);

  return (
    <SelectThemeInputContext.Provider value={contextValue}>
      {children}
    </SelectThemeInputContext.Provider>
  );
};

export default SelectThemeInputForOrgUnitProvider;
