import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useHandleError from 'shared/errors/useHandleError';

import type { ActionsContextValue } from './ObjectiveActionsProvider.context';
import { ActionsContext } from './ObjectiveActionsProvider.context';
import { useObjectiveActionsQuery } from './ObjectiveActionsProvider.graphql.generated';

type ObjectiveActionsProviderProps = PropsWithChildren<object>;

const ObjectiveActionsProvider = ({
  children,
}: ObjectiveActionsProviderProps) => {
  const { objectiveId } = useParams() as { objectiveId: string };

  const onError = useHandleError();

  const { data, loading: isObjectiveActionsLoading } = useObjectiveActionsQuery(
    {
      variables: { objectiveId },
      onError,
    },
  );

  const contextValue = useMemo<ActionsContextValue>(
    () => ({
      objective: data?.objective,
      isObjectiveActionsLoading: isObjectiveActionsLoading && !data,
    }),

    [data, isObjectiveActionsLoading],
  );

  return (
    <ActionsContext.Provider value={contextValue}>
      {children}
    </ActionsContext.Provider>
  );
};

export default ObjectiveActionsProvider;
