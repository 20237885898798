import { useCallback, useEffect, useMemo, useState } from 'react';
import { uniqBy } from 'lodash';

import useSingleSearchParamState from './useSearchParamState';

const parseList = (serialized: string) =>
  serialized.split(',').filter((value) => value !== '');

const serializeList = (value: string[]) => value.join(',');

type ParseValue<T> = (serializedValue: string) => T;
type SerializeValue<T> = (value: T) => string;

export default function useMultiSearchParamState<T>(
  paramName: string,
  initialValue: T[],
  storeToSession: boolean,
  parseValue: ParseValue<T> = (value) => value as T,
  serializeValue: SerializeValue<T> = (value) => String(value),
): [
  T[],
  (newValue: T[]) => void,
  () => void,
  (newValue: T) => void,
  (valueToRemove: T) => void,
] {
  const [valuesToRemove, setValuesToRemove] = useState([] as T[]);

  const serializedInitialValue = useMemo(
    () => initialValue.map(serializeValue),
    [initialValue, serializeValue],
  );

  const [value, setValue, clearValue] = useSingleSearchParamState<string[]>(
    paramName,
    serializedInitialValue,
    storeToSession,
    parseList,
    serializeList,
  );

  const values = useMemo(
    () => (value ? value.map(parseValue) : []),
    [value, parseValue],
  );

  const setValues = useCallback(
    (newValue: T[]) => setValue(newValue.map(serializeValue)),
    [serializeValue, setValue],
  );

  const addValue = useCallback(
    (newValue: T) => setValues(uniqBy([...values, newValue], serializeValue)),
    [serializeValue, setValues, values],
  );

  const removeValue = useCallback(
    (valueToRemove: T) =>
      setValuesToRemove((prevValuesToRemove) => [
        ...prevValuesToRemove,
        valueToRemove,
      ]),
    [],
  );

  useEffect(() => {
    if (valuesToRemove.length === 0) return;

    const serializedValuesToRemove = valuesToRemove.map(serializeValue);

    setValues(
      values.filter(
        (value) => !serializedValuesToRemove.includes(serializeValue(value)),
      ),
    );

    setValuesToRemove([]);
  }, [serializeValue, setValues, values, valuesToRemove]);

  return [values, setValues, clearValue, addValue, removeValue];
}
