import type { Merge } from 'type-fest';
import { useMemo } from 'react';

import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';
import type { ActionWithObjective } from 'actions/actions.type';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider/useActionsLayout';
import {
  filterActionsByCollaboratingTeams,
  filterActionsByCompleted,
  filterActionsByDates,
  filterActionsByPriority,
  filterActionsByStatus,
  filterActionsByUser,
  sortActions,
} from 'actions/action.utils';
import { ReactComponent as FlagIcon } from 'shared/static/icons/icon-flag.svg';
import CollapsibleList from 'shared/components/CollapsibleList';
import { useCalendarControls } from 'shared/filters/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/filters/CompletedFilterButton/useCompletedFilter';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useCollaboratingTeamsFilter } from 'shared/filters/CollaboratingTeamsFilter';
import { useActionStatusFilter } from 'shared/filters/ActionStatusFilter';
import { usePriorityFilter } from 'shared/filters/PriorityFilter';
import { useUserFilter } from 'shared/filters/UserFilter';
import { useTimelineFilter } from 'shared/filters/TimelineFilter';

import ActionRow from '../ActionRow';

type ActionsListSectionProps = {
  expanded?: boolean;
  hasHeader?: boolean;
  objective: Merge<
    ActionsObjectiveFragment,
    { actions: ActionWithObjective[] }
  >;
};

const ActionsListSection = ({
  objective,
  hasHeader = true,
  expanded,
}: ActionsListSectionProps) => {
  const { orgUnit } = useOrgUnit();

  const { actionStatuses } = useActionStatusFilter();
  const { priorities } = usePriorityFilter();
  const { selectedUserIds } = useUserFilter();
  const { startDate, endDate } = useTimelineFilter();

  const { actionsSort } = useActionsLayout();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  const filteredActions = useMemo(
    () =>
      filterActionsByCollaboratingTeams(
        filterActionsByCompleted(
          filterActionsByStatus(
            filterActionsByDates(
              filterActionsByDates(
                filterActionsByPriority(
                  filterActionsByUser(objective.actions, selectedUserIds),
                  priorities,
                ),
                startDate,
                endDate,
              ),
              calendarIntervalStartDate,
              calendarIntervalEndDate,
              calendarCheck,
            ),
            actionStatuses,
          ),
          showCompleted,
        ),
        showCollaboratingTeams,
        orgUnit?.id,
      ),
    [
      actionStatuses,
      calendarCheck,
      calendarIntervalEndDate,
      calendarIntervalStartDate,
      endDate,
      objective.actions,
      orgUnit?.id,
      priorities,
      selectedUserIds,
      showCollaboratingTeams,
      showCompleted,
      startDate,
    ],
  );

  const sortedActions = useMemo(
    () => sortActions(filteredActions, actionsSort),
    [filteredActions, actionsSort],
  );

  return (
    <CollapsibleList
      title={objective.name}
      titleIcon={FlagIcon}
      type={'action'}
      hasHeader={hasHeader}
      expanded={expanded}
    >
      {sortedActions?.map((action) => (
        <ActionRow action={action} key={action.id} />
      ))}
    </CollapsibleList>
  );
};

export default ActionsListSection;
