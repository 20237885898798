import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ContextMenu from 'shared/components/ContextMenu';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as ToolcardsIcon } from 'shared/static/icons/icon-toolcards.svg';
import { ReactComponent as WalkOfStrategyIcon } from 'shared/static/icons/icon-walk-of-strategy.svg';
import { ReactComponent as ObjectiveIcon } from 'shared/static/icons/icon-flag.svg';
import { ReactComponent as CameraIcon } from 'shared/static/icons/icon-camera.svg';
import { ReactComponent as InviteUserIcon } from 'shared/static/icons/icon-invite-user.svg';
import { useTeamAdapter } from 'team/TeamAdapter';
import { teamRoutesPaths } from 'team/team.routing.paths';
import { toolkitRoutesPaths } from 'toolkit/toolkit.routing.paths';
import useFileUpload from 'shared/hooks/useFileUpload/useFileUpload';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import EditOrg from 'org/EditOrg';
import useHandleError from 'shared/errors/useHandleError';
import CreateUserProfileModal from 'user/CreateUserProfileModal';
import useCanPerformOrgAction from 'user/ability/useCanPerformOrgAction';

import type {
  OrgCardContextMenuItem,
  OrgCardContextMenuItemId,
  OrgCardContextMenuStrategy,
} from './OrgCardContextMenu.type';
import { useUpdateOrgBackgroundImageMutation } from './UpdateOrgBackgroundImage.graphql.generated';

type OrgCardContextMenuProps = {
  strategy?: Maybe<OrgCardContextMenuStrategy>;
};

const OrgCardContextMenu = ({ strategy }: OrgCardContextMenuProps) => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const navigate = useNavigate();
  const { activeOrg } = useActiveOrg();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isAddUserOpen, setIsAddUserOpen] = useState(false);

  const onError = useHandleError();

  const [updateBackgroundImage] = useUpdateOrgBackgroundImageMutation({
    onError,
  });

  const { openFilePicker } = useFileUpload({
    accept: 'image/*',
    objectType: 'BACKGROUND_IMAGE',
    maxFileSize: 2,
    onFileUploaded: (fileUrl) =>
      updateBackgroundImage({
        variables: { fileUrl, orgKey: activeOrg.orgKey },
      }),
  });

  const handleAction = useCallback(
    (action: OrgCardContextMenuItemId) => {
      switch (action) {
        case 'org':
          break;
        case 'editBackgroundImage': {
          openFilePicker();
          break;
        }
        case 'edit': {
          setIsEditOpen(true);
          break;
        }
        case 'addUser': {
          setIsAddUserOpen(true);
          break;
        }
        case 'strategy':
          break;
        case 'openStrategyRoom': {
          navigate(
            teamRoutesPaths.strategyRoom({
              params: {
                teamSlug: teamAdapter.toParam(),
              },
            }),
          );
          break;
        }
        case 'openWalkOfStrategy':
          {
            navigate(
              toolkitRoutesPaths.walkOfStrategy.overview({
                params: {
                  teamSlug: teamAdapter.toParam(),
                },
              }),
            );
          }
          break;
        case 'openOneThing':
          {
            navigate(
              toolkitRoutesPaths.oneThing.overview({
                params: {
                  teamSlug: teamAdapter.toParam(),
                },
              }),
            );
          }
          break;
      }
    },
    [navigate, openFilePicker, teamAdapter],
  );

  const canWriteAccount = useCanPerformOrgAction('WRITE_ACCOUNT');
  const canCreateUser = useCanPerformOrgAction('CREATE_USER');
  const canWriteStrategy = useCanPerformOrgAction('WRITE_STRATEGY');

  const items = useMemo<OrgCardContextMenuItem[]>(
    () =>
      [
        {
          id: 'org' as OrgCardContextMenuItemId,
          title: t('team.organization'),
          children: [
            canWriteAccount && {
              id: 'editBackgroundImage' as OrgCardContextMenuItemId,
              title: t('editBackgroundImage'),
              icon: CameraIcon,
            },
            canWriteAccount && {
              id: 'edit' as OrgCardContextMenuItemId,
              title: t('org.contextMenu.edit'),
              icon: EditIcon,
            },
            canCreateUser && {
              id: 'addUser' as OrgCardContextMenuItemId,
              title: t('org.contextMenu.addUser'),
              icon: InviteUserIcon,
            },
          ].filter(Boolean),
        },
        {
          id: 'strategy' as OrgCardContextMenuItemId,
          title: t('strategy.strategy'),
          children: [
            canWriteStrategy && {
              id: 'openStrategyRoom' as OrgCardContextMenuItemId,
              title: t('strategy.strategyRoom.openStrategyRoom'),
              icon: ToolcardsIcon,
            },
            canWriteStrategy &&
              strategy &&
              activeOrg.toolsEnabled.includes('WALK_OF_STRATEGY') && {
                id: 'openWalkOfStrategy' as OrgCardContextMenuItemId,
                title: t(
                  'toolkit.toolPage.walkOfStrategy.button.openWalkOfStrategy',
                ),
                icon: WalkOfStrategyIcon,
              },
            canWriteStrategy &&
              activeOrg.toolsEnabled.includes('ONE_THING') && {
                id: 'openOneThing' as OrgCardContextMenuItemId,
                title: t('toolkit.toolPage.oneThing.button.open'),
                icon: ObjectiveIcon,
              },
          ].filter(Boolean),
        },
      ].filter((items) => items.children.length > 0),
    [
      activeOrg.toolsEnabled,
      canCreateUser,
      canWriteAccount,
      canWriteStrategy,
      strategy,
      t,
    ],
  );

  if (items.length === 0) return null;

  return (
    <>
      <ContextMenu<OrgCardContextMenuItem>
        items={items}
        onAction={handleAction}
      />

      <EditOrg isOpen={isEditOpen} onClose={() => setIsEditOpen(false)} />

      <CreateUserProfileModal
        isOpen={isAddUserOpen}
        onClose={() => setIsAddUserOpen(false)}
      />
    </>
  );
};

export default OrgCardContextMenu;
