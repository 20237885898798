import { useCallback } from 'react';
import { formatISO, parseISO } from 'date-fns';

import useSearchParamState from './useSearchParamState';

export default function useDateSearchParamState(
  paramName: string,
  initialValue: Maybe<Date>,
  storeToSession: boolean,
) {
  const parseValue = useCallback(
    (serializedValue: string) => parseISO(serializedValue),
    [],
  );

  const serializeValue = useCallback(
    (value: Date) => formatISO(value, { representation: 'date' }),
    [],
  );

  return useSearchParamState(
    paramName,
    initialValue,
    storeToSession,
    parseValue,
    serializeValue,
  );
}
