import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash';

import { useTeamAdapter } from 'team/TeamAdapter';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { useCalendarControls } from 'shared/filters/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/filters/CompletedFilterButton/useCompletedFilter';
import { useShowChildrenFilter } from 'shared/filters/ShowChildrenFilterButton/useShowChildrenFilter';
import {
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useCollaboratingTeamsFilter } from 'shared/filters/CollaboratingTeamsFilter';
import { useTimelineFilter } from 'shared/filters/TimelineFilter';
import { usePriorityFilter } from 'shared/filters/PriorityFilter';
import { useUserFilter } from 'shared/filters/UserFilter';
import { useStatusFilter } from 'shared/filters/StatusFilter';

import type { StrategyObjectivesInitiativesContextValue } from './StrategyInitiativesProvider.context';
import { StrategyObjectivesInitiativesContext } from './StrategyInitiativesProvider.context';
import type { StrategyInitiativesThemeFragment } from './StrategyInitiativesProvider.graphql.generated';
import {
  useStrategyInitiativesOrgQuery,
  useStrategyInitiativesOrgUnitQuery,
} from './StrategyInitiativesProvider.graphql.generated';
import {
  filterObjectivesByStatusIndicator,
  filterObjectivesByDates,
  filterObjectivesByUser,
  getObjectivesOwners,
  filterObjectiveInitiativesByPriority,
  filterObjectivesByCollaboratingTeams,
  filterObjectivesByCompleted,
} from './StrategyInitiativesProvider.utils';
import type { StrategyInitiativesObjective } from './StrategyInitiativesProvider.type';

type StrategyInitiativesProviderProps = PropsWithChildren<object>;

const StrategyInitiativesProvider = ({
  children,
}: StrategyInitiativesProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const { orgUnit } = useOrgUnit();

  const { isPrioritiesLoading } = useStrategyProfilePriorities();

  const onError = useHandleError();

  const { data: dataOrg, loading: isDataLoadingOrg } =
    useStrategyInitiativesOrgQuery({
      skip: teamAdapter.isOrgUnit,
      onError,
    });

  const { data: dataOrgUnit, loading: isDataLoadingOrgUnit } =
    useStrategyInitiativesOrgUnitQuery({
      variables: {
        orgUnitId: teamAdapter.keyArg,
      },
      skip: teamAdapter.isOrg,
      onError,
    });

  const allObjectives = useMemo(
    () =>
      (teamAdapter.isOrg
        ? dataOrg?.activeOrg
        : dataOrgUnit?.orgUnit
      )?.objectives.edges.map((n) => n.node) ?? [],
    [dataOrg?.activeOrg, dataOrgUnit?.orgUnit, teamAdapter.isOrg],
  );

  const { statuses } = useStatusFilter();

  const { selectedUserIds, setAvailableUsers } = useUserFilter();

  const { startDate, endDate } = useTimelineFilter();

  const { priorities } = usePriorityFilter();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();
  const { showChildren } = useShowChildrenFilter();

  useEffect(() => {
    setAvailableUsers(getObjectivesOwners(allObjectives));
  }, [allObjectives, setAvailableUsers]);

  const contextValue =
    useMemo<StrategyObjectivesInitiativesContextValue>(() => {
      const objectives = showChildren
        ? uniqBy(
            [
              ...allObjectives,
              ...allObjectives.flatMap(
                (objective) => objective.childObjectives,
              ),
            ],
            (objective) => objective.id,
          )
        : allObjectives;

      const filteredObjectives = filterObjectivesByCollaboratingTeams(
        filterObjectivesByDates(
          filterObjectivesByDates(
            filterObjectivesByUser(
              filterObjectivesByCompleted(
                filterObjectivesByStatusIndicator(
                  filterObjectiveInitiativesByPriority(objectives, priorities),
                  statuses,
                ),
                showCompleted,
              ),
              selectedUserIds,
            ),
            startDate,
            endDate,
          ),
          calendarIntervalStartDate,
          calendarIntervalEndDate,
          calendarCheck,
        ),
        showCollaboratingTeams,
        orgUnit?.id,
      ).filter((objectives) => objectives.initiatives.length > 0);

      const objectivesByTheme = groupObjectivesByTheme<
        StrategyInitiativesObjective,
        StrategyInitiativesThemeFragment
      >(filteredObjectives);

      const sortedObjectivesByTheme = sortObjectivesByTheme(
        objectivesByTheme,
        [
          ...(dataOrg?.activeOrg.orgUnits ||
            dataOrgUnit?.activeOrg.orgUnits ||
            []),
        ].reverse(),
      );

      return {
        objectives: filteredObjectives,
        objectivesByTheme: sortedObjectivesByTheme,

        isStrategyInitiativesLoading: teamAdapter.isOrg
          ? isDataLoadingOrg && !dataOrg
          : isDataLoadingOrgUnit && !dataOrgUnit,
      };
    }, [
      allObjectives,
      calendarCheck,
      calendarIntervalEndDate,
      calendarIntervalStartDate,
      dataOrg,
      dataOrgUnit,
      endDate,
      isDataLoadingOrg,
      isDataLoadingOrgUnit,
      orgUnit?.id,
      priorities,
      selectedUserIds,
      showChildren,
      showCollaboratingTeams,
      showCompleted,
      startDate,
      statuses,
      teamAdapter.isOrg,
    ]);

  const isLoading =
    (teamAdapter.isOrg
      ? !dataOrg && isDataLoadingOrg
      : !dataOrgUnit && isDataLoadingOrgUnit) || isPrioritiesLoading;

  if (isLoading) return <Spinner.Circle />;

  return (
    <StrategyObjectivesInitiativesContext.Provider value={contextValue}>
      {children}
    </StrategyObjectivesInitiativesContext.Provider>
  );
};

export default StrategyInitiativesProvider;
