import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import CollapsibleList from 'shared/components/CollapsibleList';
import { ReactComponent as ThemeIcon } from 'shared/static/icons/icon-theme.svg';

import type { StrategyInitiativesObjectivesByTheme } from '../StrategyInitiativesProvider/StrategyInitiativesProvider.type';
import InitiativesListThemesRow from './InitiativesListThemesRow';

type Props = {
  isExpanded: boolean;
  objectivesByTheme: StrategyInitiativesObjectivesByTheme;
};

const InitiativesListThemesSection = ({
  objectivesByTheme,
  isExpanded,
}: Props) => {
  const { t } = useTranslation();
  const { theme, objectives } = objectivesByTheme;

  const initiatives = objectives.flatMap((objective) => objective.initiatives);

  return (
    <CollapsibleList
      key={theme?.id || '-1'}
      title={theme?.name || t('theme.noTheme')}
      type={'initiative'}
      childrenCount={initiatives.length}
      titleIcon={ThemeIcon}
      expanded={isExpanded}
    >
      <div css={css({ margin: '0 24px' })}>
        {objectives.flatMap((objective) =>
          objective.initiatives.map((initiative) => (
            <InitiativesListThemesRow
              key={`${objective.id}-${initiative.id}`}
              objective={objective}
              initiative={initiative}
            />
          )),
        )}
      </div>
    </CollapsibleList>
  );
};

export default InitiativesListThemesSection;
