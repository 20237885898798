import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useHandleError from 'shared/errors/useHandleError';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useStrategy } from 'strategy/StrategyProvider';
import AskSohpieButton from 'ai/AskSohpieButton';
import useDialogState from 'shared/hooks/useDialogState';
import type { PromptMessage } from 'ai/AskSophieChat';

import { useStrategyOverviewAskSophieButtonQuery } from './StrategyOverviewAskSophieButton.graphql.generated';

const StrategyOverviewAskSophieButton = () => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const { strategy } = useStrategy();

  const { isOpen, onOpen } = useDialogState();

  const onError = useHandleError();

  const { data } = useStrategyOverviewAskSophieButtonQuery({
    onError,
    variables: {
      filter: {
        orgUnitIds: teamAdapter.isOrgUnit
          ? {
              operator: 'IN',
              values: [teamAdapter.keyArg],
            }
          : { operator: 'IS_NULL' },
        status: {
          isComplete: false,
        },
      },
    },
    skip: !strategy || !isOpen,
  });

  const objectiveIds = data?.allObjectives.edges.map((edge) => edge.node.id);

  const promptMessages = useMemo<PromptMessage[]>(
    () => [
      {
        buttonLabel: t('ai.strategySummary.button.story'),
        mainPrompt: t('ai.strategySummary.button.story'),
        numberOfCharacters: 2000,
      },
      {
        buttonLabel: t('ai.strategySummary.button.fairytale'),
        mainPrompt: 'Tell my strategy as a fairy tale story.',
        numberOfCharacters: 3000,
      },
      {
        buttonLabel: t('ai.strategySummary.button.stressTest'),
        mainPrompt: 'Please do a stress test of our strategy.',
        maxNumberOfCharacters: 5000,
        hints: [
          'Use the following frameworks to analyse our strategy information (info in roadmap): Playing to Win, Strategy Maps, Strategy Diamond, Blue Ocean Strategy, McKinsey 7S framework, The Ansoff Matrix, VRIO Framework, BCG Growth Share Matrix. For each framework, group the results into a) well covered, b) potential gaps.',
        ],
      },
    ],
    [t],
  );

  return (
    <AskSohpieButton
      promptMessages={promptMessages}
      strategyId={strategy?.id}
      objectiveIds={objectiveIds}
      infoText={t('ai.strategySummary.start.subHeader.overview')}
      onOpen={onOpen}
    />
  );
};

export default StrategyOverviewAskSophieButton;
