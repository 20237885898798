import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { uniqBy } from 'lodash';

import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { useCalendarControls } from 'shared/filters/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/filters/CompletedFilterButton/useCompletedFilter';
import { useShowChildrenFilter } from 'shared/filters/ShowChildrenFilterButton/useShowChildrenFilter';
import {
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useCollaboratingTeamsFilter } from 'shared/filters/CollaboratingTeamsFilter';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useTimelineFilter } from 'shared/filters/TimelineFilter';
import { usePriorityFilter } from 'shared/filters/PriorityFilter';
import { useStatusFilter } from 'shared/filters/StatusFilter';
import { useUserFilter } from 'shared/filters/UserFilter';

import type { StrategyObjectivesMetricsContextValue } from './StrategyMetricsProvider.context';
import { StrategyObjectivesMetricsContext } from './StrategyMetricsProvider.context';
import type { StrategyMetricsThemeFragment } from './StrategyMetricsProvider.graphql.generated';
import {
  useStrategyMetricsOrgQuery,
  useStrategyMetricsOrgUnitQuery,
} from './StrategyMetricsProvider.graphql.generated';
import {
  filterObjectivesByStatusIndicator,
  filterObjectivesByUser,
  filterObjectivesByDates,
  getObjectivesOwners,
  filterObjectiveMetricsByPriority,
  filterObjectivesByCollaboratingTeams,
  filterObjectivesByCompleted,
} from './StrategyMetricsProvider.utils';
import type { StrategyMetricsObjective } from './StrategyMetricsProvider.type';

type StrategyMetricsProviderProps = PropsWithChildren<object>;

const StrategyMetricsProvider = ({
  children,
}: StrategyMetricsProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const { orgUnit } = useOrgUnit();

  const onError = useHandleError();

  const { data: dataOrg, loading: isDataLoadingOrg } =
    useStrategyMetricsOrgQuery({
      skip: teamAdapter.isOrgUnit,
      onError,
    });

  const { data: dataOrgUnit, loading: isDataLoadingOrgUnit } =
    useStrategyMetricsOrgUnitQuery({
      variables: {
        orgUnitId: teamAdapter.keyArg,
      },
      skip: teamAdapter.isOrg,
      onError,
    });

  const allObjectives = useMemo(
    () =>
      (teamAdapter.isOrg
        ? dataOrg?.activeOrg
        : dataOrgUnit?.orgUnit
      )?.objectives.edges.map((n) => n.node) ?? [],
    [dataOrg?.activeOrg, dataOrgUnit?.orgUnit, teamAdapter.isOrg],
  );

  const { isPrioritiesLoading } = useStrategyProfilePriorities();

  const { statuses } = useStatusFilter();

  const { selectedUserIds, setAvailableUsers } = useUserFilter();

  const { startDate, endDate } = useTimelineFilter();

  const { priorities } = usePriorityFilter();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();
  const { showChildren } = useShowChildrenFilter();

  useEffect(() => {
    setAvailableUsers(getObjectivesOwners(allObjectives));
  }, [allObjectives, setAvailableUsers]);

  const contextValue = useMemo<StrategyObjectivesMetricsContextValue>(() => {
    const objectives = showChildren
      ? uniqBy(
          [
            ...allObjectives,
            ...allObjectives.flatMap((objective) => objective.childObjectives),
          ],
          (objective) => objective.id,
        )
      : allObjectives;

    const filteredObjectives = filterObjectivesByCollaboratingTeams(
      filterObjectivesByDates(
        filterObjectivesByDates(
          filterObjectivesByUser(
            filterObjectivesByCompleted(
              filterObjectivesByStatusIndicator(
                filterObjectiveMetricsByPriority(objectives, priorities),
                statuses,
              ),
              showCompleted,
            ),
            selectedUserIds,
          ),
          startDate,
          endDate,
        ),
        calendarIntervalStartDate,
        calendarIntervalEndDate,
        calendarCheck,
      ),
      showCollaboratingTeams,
      orgUnit?.id,
    ).filter((objectives) => objectives.metrics.length > 0);

    const objectivesByTheme = groupObjectivesByTheme<
      StrategyMetricsObjective,
      StrategyMetricsThemeFragment
    >(filteredObjectives);

    const sortedObjectivesByTheme = sortObjectivesByTheme(
      objectivesByTheme,
      [
        ...(dataOrg?.activeOrg.orgUnits ||
          dataOrgUnit?.activeOrg.orgUnits ||
          []),
      ].reverse(),
    );

    return {
      objectives: filteredObjectives,
      objectivesByTheme: sortedObjectivesByTheme,

      isStrategyMetricsLoading: teamAdapter.isOrg
        ? isDataLoadingOrg && !dataOrg
        : isDataLoadingOrgUnit && !dataOrgUnit,
    };
  }, [
    allObjectives,
    calendarCheck,
    calendarIntervalEndDate,
    calendarIntervalStartDate,
    dataOrg,
    dataOrgUnit,
    endDate,
    isDataLoadingOrg,
    isDataLoadingOrgUnit,
    orgUnit?.id,
    priorities,
    selectedUserIds,
    showChildren,
    showCollaboratingTeams,
    showCompleted,
    startDate,
    statuses,
    teamAdapter.isOrg,
  ]);

  const isLoading =
    (teamAdapter.isOrg
      ? !dataOrg && isDataLoadingOrg
      : !dataOrgUnit && isDataLoadingOrgUnit) || isPrioritiesLoading;

  if (isLoading) return <Spinner.Circle />;

  return (
    <StrategyObjectivesMetricsContext.Provider value={contextValue}>
      {children}
    </StrategyObjectivesMetricsContext.Provider>
  );
};

export default StrategyMetricsProvider;
