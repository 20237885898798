import { useToggle } from 'react-use';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';

import Layout from 'shared/components/Layout';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import CalendarControls, {
  CalendarControlsProvider,
} from 'shared/filters/CalendarControls';
import Flex from 'shared/components/Flex';
import CreateObjectiveButton from 'objective/CreateObjectiveButton';
import CompletedFilterProvider from 'shared/filters/CompletedFilterButton/CompletedFilterProvider';
import CompletedFilterButton from 'shared/filters/CompletedFilterButton';
import CollaboratingTeamsFilterProvider from 'shared/filters/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';
import { ChipGroupProvider } from 'shared/filters/ChipGroup/ChipGroupProvider';
import ThemeFilter, { ThemeFilterProvider } from 'shared/filters/ThemeFilter';
import StatusFilter, {
  StatusFilterProvider,
} from 'shared/filters/StatusFilter';
import PriorityFilter, {
  PriorityFilterProvider,
} from 'shared/filters/PriorityFilter';
import UserFilter, { UserFilterProvider } from 'shared/filters/UserFilter';
import TimelineFilter, {
  TimelineFilterProvider,
} from 'shared/filters/TimelineFilter';
import CollaboratingTeamsFilter from 'shared/filters/CollaboratingTeamsFilter';
import FiltersButton from 'shared/filters/FiltersButton';

import StrategyObjectivesHeader from './StrategyObjectivesHeader';
import StrategyObjectivesContent from './StrategyObjectivesContent';
import StrategyObjectivesProvider from './StrategyObjectivesProvider';
import StrategyObjectivesAskSophieButton from './StrategyObjectivesAskSophieButton';

const StrategyObjectives = () => {
  const { t } = useTranslation();
  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  return (
    <CalendarControlsProvider storeToSession={true}>
      <CompletedFilterProvider storeToSession={true}>
        <ChipGroupProvider>
          <CollaboratingTeamsFilterProvider storeToSession={true}>
            <ThemeFilterProvider storeToSession={true}>
              <StatusFilterProvider storeToSession={true}>
                <PriorityFilterProvider storeToSession={true}>
                  <UserFilterProvider storeToSession={true}>
                    <TimelineFilterProvider storeToSession={true}>
                      <StrategyObjectivesProvider>
                        <Layout.Content
                          hasPadding={false}
                          variant={'contained'}
                        >
                          <StrategyObjectivesHeader>
                            <CalendarControls />

                            <Flex
                              gap={8}
                              css={css({ flex: '1' })}
                              justifyContent={'flex-end'}
                            >
                              <CompletedFilterButton />

                              <FiltersButton>
                                <ThemeFilter />
                                <StatusFilter />
                                <PriorityFilter />
                                <UserFilter />
                                <TimelineFilter />
                                <CollaboratingTeamsFilter />
                              </FiltersButton>

                              <ToggleExpandIconButton
                                onToggle={toggleAreAllSectionsExpanded}
                                isExpanded={areAllSectionsExpanded}
                              />

                              <StrategyObjectivesAskSophieButton />

                              <CreateObjectiveButton
                                variant={'contained'}
                                showSuccessDialog={true}
                              >
                                {t(
                                  'strategy.strategyObjectives.addNewObjective',
                                )}
                              </CreateObjectiveButton>
                            </Flex>
                          </StrategyObjectivesHeader>

                          <StrategyObjectivesContent
                            areAllSectionsExpanded={areAllSectionsExpanded}
                          />
                        </Layout.Content>
                      </StrategyObjectivesProvider>
                    </TimelineFilterProvider>
                  </UserFilterProvider>
                </PriorityFilterProvider>
              </StatusFilterProvider>
            </ThemeFilterProvider>
          </CollaboratingTeamsFilterProvider>
        </ChipGroupProvider>
      </CompletedFilterProvider>
    </CalendarControlsProvider>
  );
};

export default StrategyObjectives;
