import { useDefinedContext } from 'shared/utils/context.utils';

import PriorityFilterContext from './PriorityFilter.context';
import PriorityFilterProvider from './PriorityFilterProvider';

export const usePriorityFilter = () =>
  useDefinedContext(PriorityFilterContext, {
    hookName: usePriorityFilter.name,
    providerName: PriorityFilterProvider.name,
  });
