import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';

import { TeamAdapter } from 'team/TeamAdapter';
import useHandleError from 'shared/errors/useHandleError';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';
import { useErrorPage } from 'shared/errors/ErrorPage/useErrorPage';

import { useOrgUnitQuery } from './OrgUnitProvider.graphql.generated';
import { OrgUnitProviderContext } from './OrgUnitProvider.context';

type OrgUnitProviderProps = PropsWithChildren<{
  pathsToSkipOrgUnitAccessCheck: string[];
}>;

const OrgUnitProvider = ({
  pathsToSkipOrgUnitAccessCheck,
  children,
}: OrgUnitProviderProps) => {
  const { teamSlug } = useParams() as {
    teamSlug?: string;
  };

  const teamAdapter = useMemo<Maybe<TeamAdapter>>(
    () => (teamSlug ? TeamAdapter.fromParam(teamSlug) : undefined),
    [teamSlug],
  );

  const onError = useHandleError();

  const { data: orgUnitData } = useOrgUnitQuery({
    variables: {
      orgUnitId: teamAdapter?.keyArg as string,
    },
    skip: !teamAdapter?.isOrgUnit,
    onError,
  });

  const contextValue = useMemo(
    () => ({
      orgUnit:
        teamAdapter?.isOrgUnit && orgUnitData ? orgUnitData.orgUnit : undefined,
    }),
    [orgUnitData, teamAdapter],
  );

  const { pathname } = useLocation();
  const { onErrorCode } = useErrorPage();

  useEffect(() => {
    if (!teamAdapter?.isOrgUnit || !orgUnitData) return;

    const shouldSkipOrgUnitAccessCheck = pathsToSkipOrgUnitAccessCheck.some(
      (pathToSkip) => matchPath({ path: pathToSkip, end: false }, pathname),
    );

    if (shouldSkipOrgUnitAccessCheck) return;

    if (!canPerformOrgUnitAction(orgUnitData.orgUnit, 'READ'))
      onErrorCode('ACCESS_DENIED');
  }, [
    onErrorCode,
    orgUnitData,
    orgUnitData?.orgUnit,
    pathname,
    pathsToSkipOrgUnitAccessCheck,
    teamAdapter?.isOrgUnit,
  ]);

  return (
    <OrgUnitProviderContext.Provider value={contextValue}>
      {children}
    </OrgUnitProviderContext.Provider>
  );
};

export default OrgUnitProvider;
