import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import CreateUserProfileModal from 'user/CreateUserProfileModal';
import IfCanPerformOrgAction from 'user/ability/IfCanPerformOrgAction';

const CreateUserButton = () => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = useCallback(() => setIsOpen(true), []);
  const handleClose = useCallback(() => setIsOpen(false), []);

  return (
    <IfCanPerformOrgAction action={'CREATE_USER'}>
      <Button
        onPress={handleOpen}
        variant={'contained'}
        icon={PlusIcon}
        iconPosition={'start'}
      >
        {t('org.contextMenu.addUser')}
      </Button>

      <CreateUserProfileModal isOpen={isOpen} onClose={handleClose} />
    </IfCanPerformOrgAction>
  );
};

export default CreateUserButton;
