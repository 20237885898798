import { createContext } from 'react';

export type TimelineFilterContextValue = {
  clearEndDate: () => void;
  clearStartDate: () => void;
  endDate: Maybe<Date>;
  setEndDate: (startDate: Maybe<Date>) => void;
  setStartDate: (startDate: Maybe<Date>) => void;
  startDate: Maybe<Date>;
};

const TimelineFilterContext = createContext<
  TimelineFilterContextValue | undefined
>(undefined);

export default TimelineFilterContext;
