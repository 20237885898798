import { createContext } from 'react';

import type {
  StrategyMetricsObjective,
  StrategyMetricsObjectivesByTheme,
} from './StrategyMetricsProvider.type';

export type StrategyObjectivesMetricsContextValue = {
  isStrategyMetricsLoading: boolean;
  objectives: Maybe<StrategyMetricsObjective[]>;
  objectivesByTheme: StrategyMetricsObjectivesByTheme[];
};

export const StrategyObjectivesMetricsContext = createContext<
  StrategyObjectivesMetricsContextValue | undefined
>(undefined);
