import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { date } from 'shared/services/date.service';
import useDateSearchParamState from 'shared/hooks/useDateSearchParamState';

import type { TimelineFilterContextValue } from './TimelineFilter.context';
import StatusFilterContext from './TimelineFilter.context';
import { useChipGroup } from '../ChipGroup';

const filterName = 'timeline';

type Props = PropsWithChildren<{ storeToSession: boolean }>;

const TimelineFilterProvider = ({ storeToSession, children }: Props) => {
  const { t } = useTranslation();

  const [startDate, setStartDate, clearStartDate] = useDateSearchParamState(
    'startDate',
    undefined,
    storeToSession,
  );

  const [endDate, setEndDate, clearEndDate] = useDateSearchParamState(
    'endDate',
    undefined,
    storeToSession,
  );

  const clearTimeline = useCallback(() => {
    clearStartDate();
    clearEndDate();
  }, [clearEndDate, clearStartDate]);

  const { setChipItems } = useChipGroup();

  useEffect(
    () =>
      setChipItems(
        filterName,
        clearTimeline,
        [
          startDate && {
            label: `${t('from')}: ${date.formatShort(startDate)}`,
            onRemove: clearStartDate,
          },
          endDate && {
            label: `${t('to')}: ${date.formatShort(endDate)}`,
            onRemove: clearEndDate,
          },
        ].filter(Boolean),
      ),
    [
      clearEndDate,
      clearStartDate,
      clearTimeline,
      endDate,
      setChipItems,
      startDate,
      t,
    ],
  );

  const value = useMemo<TimelineFilterContextValue>(
    () => ({
      startDate,
      setStartDate,
      clearStartDate,
      endDate,
      setEndDate,
      clearEndDate,
    }),
    [
      clearEndDate,
      clearStartDate,
      endDate,
      setEndDate,
      setStartDate,
      startDate,
    ],
  );

  return (
    <StatusFilterContext.Provider value={value}>
      {children}
    </StatusFilterContext.Provider>
  );
};

export default TimelineFilterProvider;
