import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react';

import Flex from 'shared/components/Flex';
import DatePicker from 'shared/components/DatePicker';

import { useTimelineFilter } from './useTimelineFilter';

const TimelineFilter = () => {
  const { t } = useTranslation();

  const { startDate, setStartDate, endDate, setEndDate } = useTimelineFilter();

  return (
    <Flex gap={'1rem'}>
      <DatePicker
        value={startDate}
        onChange={setStartDate}
        labelPrefix={t('from')}
        placeholder={t('allDates')}
        maxDate={endDate}
        css={css({ width: '100%', flexGrow: 1 })}
        grow={true}
      />
      <DatePicker
        value={endDate}
        onChange={setEndDate}
        labelPrefix={t('to')}
        placeholder={t('allDates')}
        minDate={startDate}
        grow={true}
      />
    </Flex>
  );
};

export default TimelineFilter;
