import { OAuthProvider, signInWithPopup } from 'firebase/auth';
import { useCallback } from 'react';

import { auth } from './AuthProvider';

const providers = {
  microsoft: new OAuthProvider('microsoft.com'),
  google: new OAuthProvider('google.com'),
};

providers.google.addScope('https://www.googleapis.com/auth/userinfo.email');
providers.google.addScope('https://www.googleapis.com/auth/userinfo.profile');

export type Provider = keyof typeof providers;

export const useSignUpWithProvider = () =>
  useCallback(
    (provider: Provider) =>
      signInWithPopup(auth, providers[provider]).then((signInResult) => {
        const credential = OAuthProvider.credentialFromResult(signInResult);

        const accessToken = credential?.accessToken;

        if (!accessToken) throw new Error('Missing access token');

        return { accessToken };
      }),
    [],
  );
