import { createContext } from 'react';

import type {
  StrategyObjectivesByTheme,
  StrategyObjectivesObjective,
} from '../StrategyObjectives.type';

export type StrategyObjectivesProviderContextValue = {
  activeObjectives: StrategyObjectivesObjective[];
  activeThemesCount: number;
  completedObjectives: StrategyObjectivesObjective[];
  objectives: StrategyObjectivesObjective[];
  objectivesByThemes: StrategyObjectivesByTheme[];
};

export const StrategyObjectivesProviderContext = createContext<
  StrategyObjectivesProviderContextValue | undefined
>(undefined);
