import { createContext } from 'react';

import type { ObjectivesByTheme } from 'objective/objective.utils';

import type {
  TeamInsightsOverviewObjective,
  TeamInsightsOverviewTheme,
} from './TeamInsightsOverviewProvider.type';

export type TeamInsightsOverviewProviderContextValue = {
  activeTheme?: Maybe<TeamInsightsOverviewTheme>;
  activeThemeObjectives: TeamInsightsOverviewObjective[];
  filteredObjectives: TeamInsightsOverviewObjective[];
  objectives: TeamInsightsOverviewObjective[];
  objectivesByTheme: Array<
    ObjectivesByTheme<TeamInsightsOverviewObjective, TeamInsightsOverviewTheme>
  >;
};

export const TeamInsightsOverviewProviderContext =
  createContext<Maybe<TeamInsightsOverviewProviderContextValue>>(undefined);
