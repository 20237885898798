import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useMemo } from 'react';

import OpenTextInputField from 'shared/components/OpenTextInput/OpenTextInput.field';
import FieldBox from 'shared/form/FieldBox';
import { DatePickerField } from 'shared/components/DatePicker';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import { useTeamAdapter } from 'team/TeamAdapter';
import {
  SelectObjectiveInputField,
  SelectObjectiveInputProvider,
} from 'objective/SelectObjectiveInput';
import Flex from 'shared/components/Flex';
import { PrioritySelectField } from 'strategy/PrioritySelect';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import Spinner from 'shared/spinner/Spinner';
import AttributesFields from 'shared/components/AttributesFields';
import ExpandableFormContainer from 'shared/form/FormContainer/ExpandableFormContainer';
import InfoFormContainer from 'shared/form/FormContainer/InfoFormContainer';
import { TextInputField } from 'shared/components/TextInput';
import MultiFieldRow from 'shared/form/MultiFieldRow';
import { SuggestionsTextInputField } from 'shared/components/SuggestionsTextInput';

import type { MetricFormValues } from '../MetricForm';
import MetricTargetsFormFields from './MetricTargetsFormFields';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const DetailsTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.color.strokeLight};
  border-radius: 20px 20px 0 0;
`;

const DetailsBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.color.strokeLight};
  border-top: 0;
  border-radius: 0 0 20px 20px;
  background-color: ${(props) => props.theme.color.backgroundLight};
`;

const MetricFormFields = () => {
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();
  const { values, getFieldMeta } = useFormikContext<MetricFormValues>();

  const hasError = (field: string) => getFieldMeta(field).error !== undefined;

  const { priorities, isPrioritiesLoading } = useStrategyProfilePriorities();

  const unitOfMeasureOptions = useMemo(
    () => [
      t('metric.metricForm.values.unitOfMeasure.option.index'),
      t('metric.metricForm.values.unitOfMeasure.option.percentage'),
      t('metric.metricForm.values.unitOfMeasure.option.rank'),
      t('metric.metricForm.values.unitOfMeasure.option.score'),
      t('metric.metricForm.values.unitOfMeasure.option.money'),
      t('metric.metricForm.values.unitOfMeasure.option.volume'),
      t('metric.metricForm.values.unitOfMeasure.option.number'),
    ],
    [t],
  );

  if (isPrioritiesLoading) return <Spinner.Circle />;

  return (
    <Container>
      <div>
        <DetailsTop>
          <Field
            component={OpenTextInputField}
            name={'name'}
            hasError={hasError('name')}
            placeholder={t('metric.metricForm.name.placeholder')}
            fontSize={'big'}
            maxLength={100}
            autoFocus={true}
          />
          <Field
            component={OpenTextInputField}
            name={'description'}
            hasError={hasError('description')}
            placeholder={t('metric.metricForm.description.placeholder')}
            maxLength={1000}
            inputType={'textarea'}
          />
        </DetailsTop>

        <DetailsBottom>
          <Flex gap={20} css={css(`> div { flex-grow: 1 }`)}>
            <FieldBox
              name={'timeLine.startDate'}
              label={t('metric.metricForm.timeline.startDate.label')}
              hasError={hasError('startDate')}
              hasMargin={false}
            >
              <Field
                name={'timeLine.startDate'}
                component={DatePickerField}
                maxDate={values.timeLine.endDate}
                triggerFullWidth={true}
              />
            </FieldBox>
            <FieldBox
              name={'timeLine.endDate'}
              label={t('metric.metricForm.timeline.endDate.label')}
              hasError={hasError('timeLine.endDate')}
              hasMargin={false}
            >
              <Field
                name={'timeLine.endDate'}
                component={DatePickerField}
                minDate={values.timeLine.startDate}
                triggerFullWidth={true}
              />
            </FieldBox>
          </Flex>

          <FieldBox
            name={'owner'}
            label={t('metric.metricForm.owner.label')}
            hasError={hasError('owner')}
          >
            <UserEmailInputProvider teamAdapter={teamAdapter}>
              <Field
                component={UserEmailInputField}
                name={'owner'}
                hasError={hasError('owner')}
                autoComplete={'false'}
                placeholder={t('actions.form.owner.placeholder')}
              />
            </UserEmailInputProvider>
          </FieldBox>

          <FieldBox
            name={'objective'}
            label={t('metric.metricForm.objective.label')}
            hasMargin={false}
          >
            <SelectObjectiveInputProvider
              teamAdapter={teamAdapter}
              filterByAction={'WRITE'}
            >
              <Field
                name={'objective'}
                component={SelectObjectiveInputField}
                size={'full'}
              />
            </SelectObjectiveInputProvider>
          </FieldBox>

          {priorities && (
            <FieldBox
              name={'priority'}
              label={t('metric.metricForm.priority.label')}
            >
              <Field
                name={'priority'}
                component={PrioritySelectField}
                priorities={priorities}
                fullWidth={true}
              />
            </FieldBox>
          )}
        </DetailsBottom>
      </div>

      <InfoFormContainer
        title={t('metric.metricForm.values.heading')}
        infoContent={t('metric.metricForm.values.info')}
      >
        <MultiFieldRow>
          <FieldBox
            name={'unitOfMeasure'}
            label={t('metric.metricForm.values.unitOfMeasure.label')}
            hasMargin={false}
          >
            <Field
              name={'unitOfMeasure'}
              component={SuggestionsTextInputField}
              options={unitOfMeasureOptions}
              placeholder={t(
                'metric.metricForm.values.unitOfMeasure.placeholder',
              )}
            />
          </FieldBox>
          <FieldBox
            name={'startValue'}
            label={t('metric.metricForm.values.startValue.label')}
            hasMargin={false}
          >
            <Field
              name={'startValue'}
              component={TextInputField}
              type={'number'}
            />
          </FieldBox>
          <FieldBox
            name={'targetValue'}
            label={t('metric.metricForm.values.targetValue.label')}
            hasMargin={false}
          >
            <Field
              name={'targetValue'}
              component={TextInputField}
              type={'number'}
            />
          </FieldBox>
        </MultiFieldRow>
      </InfoFormContainer>

      <ExpandableFormContainer
        title={t('metric.metricForm.periodicTargets.heading')}
        infoContent={t('metric.metricForm.periodicTargets.info')}
        isExpandable={false}
        hasContentPadding={false}
      >
        <MetricTargetsFormFields />
      </ExpandableFormContainer>

      {values.attributes.length > 0 && (
        <ExpandableFormContainer
          title={t('attributes.attributes')}
          isOptional={true}
        >
          <AttributesFields name={'attributes'} />
        </ExpandableFormContainer>
      )}
    </Container>
  );
};

export default MetricFormFields;
