import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { getYear } from 'date-fns';

import { DatePickerField } from 'shared/components/DatePicker';
import Space from 'shared/components/Space';
import { TextInputField } from 'shared/components/TextInput';
import FieldBox from 'shared/form/FieldBox';
import { useForm } from 'shared/form/Form';
import { useTeamAdapter } from 'team/TeamAdapter';
import {
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import Spinner from 'shared/spinner/Spinner';
import SelectField from 'shared/components/Select/Select.field';
import useHandleError from 'shared/errors/useHandleError';
import { canPerformOrgUnitAction } from 'user/ability/canPerformOrgUnitAction';
import useCanPerformOrgAction from 'user/ability/useCanPerformOrgAction';

import { useStrategySelectQuery } from './StrategySelect.graphql.generated';
import type { CampaignFormValues } from './WalkOfStrategyCampaignForm';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const DateFieldBox = styled(FieldBox)`
  flex-grow: 1;
`;

const CampaignFields = () => {
  const { values, hasError } = useForm<CampaignFormValues>();
  const { t } = useTranslation();
  const { teamAdapter } = useTeamAdapter();

  const isCreate = !values.id;
  const isUpdate = !isCreate;

  const onError = useHandleError();

  const { data } = useStrategySelectQuery({ onError });

  const orgStrategy = data?.activeOrg.currentStrategy;
  const orgUnitStrategies =
    data?.allOrgUnits.edges
      .map((edge) => edge.node)
      .map((orgUnit) => orgUnit.currentStrategy)
      .filter(Boolean) || [];

  const allStrategies = (
    isCreate ? [orgStrategy, ...orgUnitStrategies] : [values.strategy]
  ).filter(Boolean);

  const canWriteOrgStrategy = useCanPerformOrgAction('WRITE_STRATEGY');

  const filteredStrategies = allStrategies.filter(
    (strategy) =>
      isUpdate ||
      (strategy.orgUnit
        ? canPerformOrgUnitAction(strategy.orgUnit, 'WRITE')
        : canWriteOrgStrategy),
  );

  const strategyOptions = filteredStrategies.map((strategy) => ({
    id: strategy.id,
    title: strategy.orgUnit?.name || data?.activeOrg.displayName,
  }));

  if (isCreate && !data) {
    return <Spinner.Circle />;
  }

  const namePlaceholder = `${t('eg')} ${t(
    'toolkit.tools.walkOfStrategy.name',
  )} ${getYear(new Date())} ${t('quarterQ')}${getQuarter(new Date())}`;

  return (
    <Container>
      <section
        css={css`
          & > div > div > div > div > div {
            width: auto;
          }
        `}
      >
        <FieldBox name={'strategy'} label={t('team.team')}>
          <Field
            name={'strategy'}
            component={SelectField}
            items={strategyOptions}
            isDisabled={isUpdate}
            triggerStyle={css({ width: '100%' })}
          />
        </FieldBox>
      </section>
      <section>
        <FieldBox
          name={'name'}
          label={t('toolkit.toolPage.walkOfStrategy.campaignForm.name')}
        >
          <Field
            name={'name'}
            component={TextInputField}
            hasError={hasError('name')}
            maxLength={100}
            placeholder={namePlaceholder}
          />
        </FieldBox>
      </section>
      <section>
        <Space css={css({ flexGrow: 1 })}>
          <DateFieldBox
            name={'startDate'}
            label={t('toolkit.toolPage.walkOfStrategy.campaignForm.startDate')}
            hasMargin={false}
            css={css({ flexBasis: 0 })}
          >
            <Field
              name={'startDate'}
              component={DatePickerField}
              maxDate={values.deadline || values.endDate}
            />
          </DateFieldBox>
          <DateFieldBox
            name={'deadline'}
            label={t('toolkit.toolPage.walkOfStrategy.campaignForm.deadline')}
            hasMargin={false}
            isOptional={true}
            css={css({ flexBasis: 0 })}
          >
            <Field
              name={'deadline'}
              component={DatePickerField}
              minDate={values.startDate}
              maxDate={values.endDate}
            />
          </DateFieldBox>
          <DateFieldBox
            name={'endDate'}
            label={t('toolkit.toolPage.walkOfStrategy.campaignForm.endDate')}
            hasMargin={false}
            isOptional={true}
            css={css({ flexBasis: 0 })}
          >
            <Field
              name={'endDate'}
              component={DatePickerField}
              minDate={values.deadline || values.startDate}
            />
          </DateFieldBox>
        </Space>
      </section>
      <section>
        <UserEmailInputProvider teamAdapter={teamAdapter}>
          <FieldBox
            name={'owner'}
            label={t('toolkit.toolPage.walkOfStrategy.campaignForm.owner')}
          >
            <Field component={UserEmailInputField} name={'owner'} />
          </FieldBox>
        </UserEmailInputProvider>
      </section>
    </Container>
  );
};

export default CampaignFields;

const getQuarter = (date = new Date()) => Math.floor(date.getMonth() / 3 + 1);
