import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSessionStorage } from 'react-use';

import { useBatchedSearchParams } from './useBatchedSearchParams';

type ParseValue<T> = (serializedValue: string) => T;
type SerializeValue<T> = (value: T) => string;

export default function useSearchParamState<T>(
  paramName: string,
  initialValue: Maybe<T>,
  storeValueInSession: boolean,
  parseValue: ParseValue<T> = (value) => value as T,
  serializeValue: SerializeValue<T> = (value) => String(value),
): [Maybe<T>, (newValue: Maybe<T>) => void, () => void] {
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const serializedInitialValue = useMemo(
    () =>
      initialValue !== undefined && initialValue !== null
        ? serializeValue(initialValue)
        : undefined,
    [initialValue, serializeValue],
  );

  const [sessionValue, _setSessionValue] = useSessionStorage<
    string | undefined
  >(paramName, undefined);

  const clearSessionValue = useCallback(() => {
    if (storeValueInSession) {
      _setSessionValue(undefined);
    }
  }, [_setSessionValue, storeValueInSession]);

  const setSessionValue = useCallback(
    (newSerializedValue: Maybe<string>) => {
      if (newSerializedValue === undefined || newSerializedValue === null) {
        clearSessionValue();
      } else if (storeValueInSession && newSerializedValue !== sessionValue) {
        _setSessionValue(newSerializedValue);
      }
    },
    [_setSessionValue, clearSessionValue, sessionValue, storeValueInSession],
  );

  const { searchParams, setSearchParam, clearSearchParam } =
    useBatchedSearchParams();

  const searchParamValue = searchParams.get(paramName);

  const value = useMemo(() => {
    if (searchParamValue !== null) {
      return parseValue(searchParamValue);
    } else if (storeValueInSession && sessionValue !== undefined) {
      return parseValue(sessionValue);
    } else {
      return initialValue;
    }
  }, [
    initialValue,
    parseValue,
    searchParamValue,
    sessionValue,
    storeValueInSession,
  ]);

  const clearValue = useCallback(() => {
    clearSearchParam(paramName);
    clearSessionValue();
  }, [clearSearchParam, clearSessionValue, paramName]);

  const setValue = useCallback(
    (newValue: Maybe<T>) => {
      if (newValue === undefined || newValue === null) {
        clearValue();
      } else {
        const serializedNewValue = serializeValue(newValue);

        if (serializedNewValue === serializedInitialValue) {
          clearValue();
        } else if (serializedNewValue !== searchParamValue) {
          setSearchParam(paramName, serializedNewValue);
        }

        setSessionValue(serializedNewValue);
      }
    },
    [
      clearValue,
      paramName,
      searchParamValue,
      serializeValue,
      serializedInitialValue,
      setSearchParam,
      setSessionValue,
    ],
  );

  useEffect(() => {
    if (isInitialLoad) {
      setValue(value);
      setIsInitialLoad(false);
    }
  }, [isInitialLoad, setValue, value]);

  return [value, setValue, clearValue];
}
