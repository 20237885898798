import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import { Field } from 'formik';

import Form from 'shared/form/Form';
import type { FormProps, OnSubmitFn } from 'shared/form/Form';
import type { Org, OrgUnit } from 'types.graphql.generated';
import type { UserEmailInputProviderProps } from 'user/UserEmailInput';
import {
  resolveUserFilterInput,
  UserEmailInputField,
  UserEmailInputProvider,
} from 'user/UserEmailInput';
import FieldBox from 'shared/form/FieldBox';
import { SelectField } from 'shared/components/__DEPRECATED__/Select';
import { TeamAdapter } from 'team/TeamAdapter';
import useHandleError from 'shared/errors/useHandleError';
import {
  TeamMembersFromOrgDocument,
  TeamMembersFromOrgUnitDocument,
} from 'team/TeamMembers/TeamMembersProvider/TeamMembersProvider.graphql.generated';
import { useActiveOrg } from 'org/ActiveOrgProvider';

import { createValidationSchema } from './AddMemberToOrgUnitForm.schema';
import {
  useAddMemberToOrgUnitFormUpdateOrgUnitMutation,
  useAddMemberToOrgUnitFormOrgUnitQuery,
} from './AddMemberToOrgUnitForm.graphql.generated';
import {
  createInitialValues,
  getOrgUnitOptions,
  getOrgUnitOptionLabel,
} from './AddMemberToOrgUnitForm.utils';
import type { AddMemberToOrgUnitFormValues } from './AddMemberToOrgUnitForm.type';

export type AddMemberToOrgUnitFormProps = Pick<
  FormProps<AddMemberToOrgUnitFormValues>,
  'id' | 'className'
> &
  Pick<UserEmailInputProviderProps, 'usersToExclude'> & {
    onSuccess?: () => void;
    orgKey: Org['orgKey'];
    orgUnitFieldLabel?: string;
    orgUnitId: OrgUnit['id'];
  };

const AddMemberToOrgUnitForm = ({
  onSuccess,
  orgUnitId,
  orgKey,
  usersToExclude,
  id,
  orgUnitFieldLabel,
  className,
}: AddMemberToOrgUnitFormProps) => {
  const onError = useHandleError();

  const { activeOrg } = useActiveOrg();

  const { t } = useTranslation();

  const { data: orgUnitData } = useAddMemberToOrgUnitFormOrgUnitQuery({
    variables: { id: orgUnitId },
    onError,
  });

  const orgUnitOptions = useMemo(() => {
    if (orgUnitData) {
      return getOrgUnitOptions({ orgUnit: orgUnitData?.orgUnit });
    }
    return [];
  }, [orgUnitData]);

  const initialValues = useMemo(
    () => createInitialValues({ orgUnitOptions }),
    [orgUnitOptions],
  );

  const validationSchema = useMemo(() => createValidationSchema, []);

  const [addMemberToOrgUnit] = useAddMemberToOrgUnitFormUpdateOrgUnitMutation({
    onError,
    refetchQueries: [
      TeamMembersFromOrgDocument,
      TeamMembersFromOrgUnitDocument,
    ],
  });

  const handleSubmit = useCallback<OnSubmitFn<AddMemberToOrgUnitFormValues>>(
    (values) => {
      addMemberToOrgUnit({
        variables: {
          idToUpdate: orgUnitId,
          setUserInput: { emailToSet: values.user },
          userFilterInput: resolveUserFilterInput(values.user),
        },
        onCompleted: onSuccess,
      });
    },
    [onSuccess, orgUnitId, addMemberToOrgUnit],
  );

  const teamAdapter = useMemo(
    () =>
      TeamAdapter.fromOrg({
        orgKey,
      }),
    [orgKey],
  );

  return (
    <UserEmailInputProvider
      teamAdapter={teamAdapter}
      usersToExclude={usersToExclude}
    >
      <Form<AddMemberToOrgUnitFormValues>
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        id={id}
        className={className}
      >
        {({ hasError }) => (
          <>
            <FieldBox
              name={'orgUnit'}
              label={orgUnitFieldLabel || t('orgUnit.orgUnit')}
              hasError={hasError('orgUnit')}
            >
              <Field
                name={'orgUnit'}
                component={SelectField}
                options={orgUnitOptions}
                getOptionLabel={getOrgUnitOptionLabel}
                isDisabled={true}
                hasError={hasError('orgUnit')}
                size={'full'}
              />
            </FieldBox>
            <FieldBox
              name={'user'}
              label={t('user.nameOrEmail')}
              hasError={hasError('user')}
            >
              <Field
                name={'user'}
                component={UserEmailInputField}
                hasError={hasError('user')}
                forceTextInput={
                  !activeOrg.systemPreferences.scimUserProvisioningEnabled
                }
              />
            </FieldBox>
          </>
        )}
      </Form>
    </UserEmailInputProvider>
  );
};

export default AddMemberToOrgUnitForm;
