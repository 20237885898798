import type { TFunction } from 'react-i18next';

import type { Priority } from 'types.graphql.generated';

import type { PriorityFilterSelectItemId } from './PriorityFilter';
import type { PriorityFilterValue } from './PriorityFilter.context';

export const getPriorityLabel = (
  t: TFunction,
  itemId: PriorityFilterSelectItemId,
  allPriorities?: Pick<Priority, 'number' | 'label'>[],
) => {
  switch (itemId) {
    case 'ALL':
      return t('priority.allPriority');
    case 'UNKNOWN':
      return t('priority.noPriority');
    default:
      return (
        allPriorities?.find((priority) => priority.number === itemId)?.label ||
        ''
      );
  }
};

export const testPriorityFilter = (
  filterPriority: PriorityFilterValue,
  priority?: Pick<Priority, 'number'>,
) => {
  if (filterPriority === 'UNKNOWN') {
    return !priority;
  }

  return priority?.number === filterPriority;
};

export const testPriorityFilters = (
  filterPriorities: PriorityFilterValue[],
  priority?: Pick<Priority, 'number'>,
) => {
  return (
    filterPriorities.length === 0 ||
    filterPriorities.some((filterPriority) =>
      testPriorityFilter(filterPriority, priority),
    )
  );
};
