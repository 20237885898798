import { css } from '@emotion/react';

import Flex from 'shared/components/Flex';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import FiltersButton from 'shared/filters/FiltersButton';
import CalendarControls from 'shared/filters/CalendarControls';
import CompletedFilterButton from 'shared/filters/CompletedFilterButton';
import CollaboratingTeamsFilter from 'shared/filters/CollaboratingTeamsFilter';
import StatusFilter from 'shared/filters/StatusFilter';
import PriorityFilter from 'shared/filters/PriorityFilter';
import UserFilter from 'shared/filters/UserFilter';
import TimelineFilter from 'shared/filters/TimelineFilter';

type StrategyBoardFiltersProps = {
  areAllSectionsExpanded: boolean;
  toggleAreAllSectionsExpanded: () => void;
};

const StrategyBoardFilters = ({
  areAllSectionsExpanded,
  toggleAreAllSectionsExpanded,
}: StrategyBoardFiltersProps) => {
  return (
    <>
      <CalendarControls />

      <Flex gap={8} css={css({ flex: '1', justifyContent: 'flex-end' })}>
        <CompletedFilterButton />

        <FiltersButton>
          <StatusFilter />
          <PriorityFilter />
          <UserFilter />
          <TimelineFilter />
          <CollaboratingTeamsFilter />
        </FiltersButton>

        <ToggleExpandIconButton
          isExpanded={areAllSectionsExpanded}
          onToggle={toggleAreAllSectionsExpanded}
        />
      </Flex>
    </>
  );
};

export default StrategyBoardFilters;
