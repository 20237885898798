import { useDefinedContext } from 'shared/utils/context.utils';

import UserFilterContext from './UserFilter.context';
import UserFilterProvider from './UserFilterProvider';

export const useUserFilter = () =>
  useDefinedContext(UserFilterContext, {
    hookName: useUserFilter.name,
    providerName: UserFilterProvider.name,
  });
