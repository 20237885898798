import type { FunctionComponent, SVGProps } from 'react';
import {
  endOfDay,
  getOverlappingDaysInIntervals,
  isWithinInterval,
  min,
  max,
} from 'date-fns';
import { startOfDay } from 'date-fns/esm';

import type { StrategyItemType } from 'strategy/strategy.type';
import { getObjectiveIcon } from 'objective/objective.utils';
import { getInitiativeIcon } from 'initiative/initiative.utils';
import { getMetricIcon } from 'metric/metric.utils';
import type { AuditRecord, TimeLine } from 'types.graphql.generated';
import type { PartialInterval } from 'shared/services/date.service';
import { date } from 'shared/services/date.service';
import {
  timeLineToInterval,
  isTimeLineEmpty,
} from 'shared/utils/timeLine.utils';
import type { CalendarCheck } from 'shared/filters/CalendarControls/CalendarControls.context';

import type { ThemeFragment } from '../themes/ThemesProvider/ThemesProvider.graphql.generated';
import type { StrategyRoadmapStrategyFieldsFragment } from './StrategyRoadmap/StrategyRoadmapProvider/StrategyRoadmapProvider.graphql.generated';
import type { StrategyProviderStrategyFragment } from './StrategyProvider';

export const getStrategyItemIcon = (
  strategyItemType: StrategyItemType,
): FunctionComponent<SVGProps<SVGSVGElement>> => {
  switch (strategyItemType) {
    case 'objective':
      return getObjectiveIcon();
    case 'metric':
      return getMetricIcon();
    case 'initiative':
      return getInitiativeIcon();
  }
};

type Strategy =
  | StrategyProviderStrategyFragment
  | StrategyRoadmapStrategyFieldsFragment;

export const hasMission = (strategy: Strategy): boolean =>
  !!(strategy.mission?.name && strategy.mission.name.length > 0);

export const hasVision = (strategy: Strategy): boolean =>
  !!(strategy.vision?.name && strategy.vision.name.length > 0);

export const hasGoals = (strategy: Strategy): boolean =>
  !!(
    strategy.goals &&
    strategy.goals.length > 0 &&
    strategy.goals.some((goal) => goal?.name && goal.name.length > 0)
  );

export const hasChoices = (strategy: Strategy): boolean =>
  !!(strategy.choices && strategy.choices.length > 0) &&
  strategy.choices.some((choice) => choice?.name && choice.name.length > 0);

export const hasThemes = (themes: ThemeFragment[]): boolean =>
  themes.length > 0;

export const hasBehaviors = (strategy: Strategy): boolean =>
  getBehaviorItems(strategy).length > 0;

export const getBehaviorItems = (strategy: Strategy) =>
  strategy.behaviors
    .flatMap((behaviour) => behaviour.behaviorSetGroups)
    .filter((group) => group.name && group.name !== '')
    .map((group) => ({ id: group.id, children: group.name! }));

export const hasValues = (strategy: Strategy): boolean =>
  !!(strategy.values.length && strategy.values.length > 0) &&
  strategy.values.some((value) => value?.name && value.name.length > 0);

export const testStrategyItemAgainstInterval = <
  StrategyItem extends {
    auditRecord: Pick<AuditRecord, 'createDateTime'>;
    timeLine: Pick<TimeLine, 'startDate' | 'endDate' | 'completeDateTime'>;
  },
>(
  strategyItem: StrategyItem,
  interval?: PartialInterval,
  check: CalendarCheck = 'overlap',
) => {
  if (!interval || date.isPartialIntervalEmpty(interval)) {
    return true;
  }

  const {
    timeLine,
    auditRecord: { createDateTime },
  } = strategyItem;

  const strategyItemDates = [
    timeLine.startDate || createDateTime,
    timeLine.endDate,
    timeLine.completeDateTime,
  ].filter(Boolean);

  const strategItemTimeline = {
    startDate: startOfDay(min(strategyItemDates)),
    endDate: endOfDay(max(strategyItemDates)),
  };

  if (isTimeLineEmpty(strategItemTimeline)) return false;

  const strategyItemInterval = timeLineToInterval(strategItemTimeline);
  const filterInterval = date.fillPartialInterval(interval);

  const overlappingDays = getOverlappingDaysInIntervals(
    strategyItemInterval,
    filterInterval,
  );

  switch (check) {
    case 'overlap':
      return overlappingDays > 0;
    case 'include':
      return (
        isWithinInterval(strategyItemInterval.start, filterInterval) &&
        isWithinInterval(strategyItemInterval.end, filterInterval)
      );
  }
};

export const testStrategyItemAgainstCollaboratingTeams = <
  StrategyItem extends { orgUnit?: { id: string } },
>(
  strategyItem: StrategyItem,
  showCollaboratingTeams: boolean,
  currentOrgUnitId?: string,
) =>
  showCollaboratingTeams ||
  !currentOrgUnitId ||
  strategyItem.orgUnit?.id === currentOrgUnitId;
