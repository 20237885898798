import { useDefinedContext } from 'shared/utils/context.utils';

import ThemeFilterContext from './ThemeFilter.context';
import ThemeFilterProvider from './ThemeFilterProvider';

export const useThemeFilter = () =>
  useDefinedContext(ThemeFilterContext, {
    hookName: useThemeFilter.name,
    providerName: ThemeFilterProvider.name,
  });
