import { useCallback, useEffect, type PropsWithChildren } from 'react';
import { useInterval } from 'react-use';
import { addWeeks, isAfter, startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useAuth } from 'auth/AuthProvider';
import useSignOutWithErrorMessage from 'auth/useSignOutWithErrorMessage';

const AuthExpiration = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const signOutWithErrorMessage = useSignOutWithErrorMessage();

  const checkSessionExpiration = useCallback(() => {
    if (!user) return;

    user.getIdTokenResult().then((idTokenResult) => {
      const authTime = new Date(idTokenResult.authTime);
      const expirationTime = startOfDay(addWeeks(authTime, 2));
      const now = new Date();

      if (isAfter(now, expirationTime)) {
        signOutWithErrorMessage(t('auth.sessionExpired'));
      }
    });
  }, [signOutWithErrorMessage, t, user]);

  useEffect(() => {
    checkSessionExpiration();
  }, [checkSessionExpiration]);

  useInterval(() => {
    checkSessionExpiration();
  }, 10000);

  return <>{children}</>;
};

export default AuthExpiration;
