import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { uniq } from 'lodash';
import { css } from '@emotion/react';

import type { Initiative, Metric, Objective } from 'types.graphql.generated';
import Space from 'shared/components/Space';
import type { TeamAdapter } from 'team/TeamAdapter';
import { useInsightsReportWizard } from 'report/InsightReportWizard/InsightReportWizardProvider/useInsightsReportWizard';
import Flex from 'shared/components/Flex';
import { CalendarControlsProvider } from 'shared/filters/CalendarControls';
import CompletedFilterProvider from 'shared/filters/CompletedFilterButton/CompletedFilterProvider';
import Text from 'shared/components/Text';
import CollaboratingTeamsFilterProvider from 'shared/filters/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';
import { ChipGroupProvider } from 'shared/filters/ChipGroup/ChipGroupProvider';
import { ThemeFilterProvider } from 'shared/filters/ThemeFilter';
import { StatusFilterProvider } from 'shared/filters/StatusFilter';
import { PriorityFilterProvider } from 'shared/filters/PriorityFilter';
import { UserFilterProvider } from 'shared/filters/UserFilter';
import { TimelineFilterProvider } from 'shared/filters/TimelineFilter';
import ChipGroup from 'shared/filters/ChipGroup';

import type {
  SelectableStrategyItemsValues,
  SubmitData,
} from './SelectableStrategyItems.type';
import styles from './SelectableStrategyItems.module.scss';
import SelectableStrategyItemsProvider from './SelectableStrategyItemsProvider';
import SelectableStrategyItemsSection from './SelectableStrategyItemsSection';
import {
  getAllIds,
  getObjectiveItemsFromObjective,
  resolveSubmitData,
} from './SelectableStrategyItems.utils';
import { useSelectableStrategyItems } from './SelectableStrategyItemsProvider/useSelectableStrategyItems';
import SelectableStrategyItemsHeader from './SelectableStrategyItemsHeader';

export type SelectableStrategyItemsProps = {
  id?: string;
  onChangeSelectedCount?: (params: {
    initiativesCount: number;
    metricsCount: number;
    objectivesCount: number;
  }) => void;
  onInitiativeChange: (values: string[]) => void;
  onMetricChange: (values: string[]) => void;
  onObjectiveChange: (values: string[]) => void;
  onSubmit?: (data: SubmitData) => void;
  teamAdapter: TeamAdapter;
  values: SelectableStrategyItemsValues;
};

const SelectableStrategyItems = ({
  onChangeSelectedCount,
  onSubmit,
  id,
  onObjectiveChange,
  onInitiativeChange,
  onMetricChange,
  values,
}: SelectableStrategyItemsProps) => {
  const { t } = useTranslation();

  const { objectives, filteredObjectives, filteredObjectivesByThemes } =
    useSelectableStrategyItems();

  const { setHasSelection } = useInsightsReportWizard();

  const objectivesCount = values.objectiveIds.length;
  const metricsCount = values.metricIds.length;
  const initiativesCount = values.initiativeIds.length;

  const totalCount = objectivesCount + metricsCount + initiativesCount;
  const isNoneSelected = totalCount === 0;

  useEffect(() => {
    setHasSelection(!isNoneSelected);
  }, [isNoneSelected, setHasSelection]);

  useEffect(() => {
    onChangeSelectedCount?.({
      objectivesCount,
      metricsCount,
      initiativesCount,
    });
  }, [initiativesCount, metricsCount, objectivesCount, onChangeSelectedCount]);

  const addSelectedMetric = useCallback(
    (...metricIds: Metric['id'][]) =>
      onMetricChange(uniq([...values.metricIds, ...metricIds])),
    [onMetricChange, values.metricIds],
  );

  const removeSelectedMetric = useCallback(
    (...metricIds: Metric['id'][]) =>
      onMetricChange(
        values.metricIds.filter(
          (eachMetricId) => !metricIds.includes(eachMetricId),
        ),
      ),
    [onMetricChange, values.metricIds],
  );

  const addSelectedInitiative = useCallback(
    (...initiativeIds: Initiative['id'][]) =>
      onInitiativeChange(uniq([...values.initiativeIds, ...initiativeIds])),
    [onInitiativeChange, values.initiativeIds],
  );

  const removeSelectedInitiative = useCallback(
    (...initiativeIds: Metric['id'][]) =>
      onInitiativeChange(
        values.initiativeIds.filter(
          (eachInitiativeId) => !initiativeIds.includes(eachInitiativeId),
        ),
      ),
    [onInitiativeChange, values.initiativeIds],
  );

  const addSelectedObjective = useCallback(
    (...objectiveIds: Objective['id'][]) =>
      onObjectiveChange(uniq([...values.objectiveIds, ...objectiveIds])),
    [onObjectiveChange, values.objectiveIds],
  );

  const removeSelectedObjective = useCallback(
    (...objectiveIds: Objective['id'][]) =>
      onObjectiveChange(
        values.objectiveIds.filter(
          (eachObjectiveId) => !objectiveIds.includes(eachObjectiveId),
        ),
      ),
    [onObjectiveChange, values.objectiveIds],
  );

  const handleToggleSelectedObjective = useCallback(
    (objectiveId: Objective['id']) => {
      const isObjectiveSelected = values.objectiveIds.includes(objectiveId);
      const shouldSelect = !isObjectiveSelected;

      const { metrics, initiatives } = getObjectiveItemsFromObjective(
        objectiveId,
        filteredObjectives,
      );

      const metricIds = metrics.map((metric) => metric.id);
      const initiativeIds = initiatives.map((initiative) => initiative.id);

      if (shouldSelect) {
        addSelectedObjective(objectiveId);
        addSelectedMetric(...metricIds);
        addSelectedInitiative(...initiativeIds);
      } else {
        removeSelectedObjective(objectiveId);
        removeSelectedMetric(...metricIds);
        removeSelectedInitiative(...initiativeIds);
      }
    },
    [
      addSelectedInitiative,
      addSelectedMetric,
      addSelectedObjective,
      filteredObjectives,
      removeSelectedInitiative,
      removeSelectedMetric,
      removeSelectedObjective,
      values.objectiveIds,
    ],
  );

  const handleToggleSelectedMetric = useCallback(
    (objectiveId: Objective['id'], metricId: Metric['id']) => {
      const isObjectiveSelected = values.objectiveIds.includes(objectiveId);
      const isMetricSelected = values.metricIds.includes(metricId);

      if (!isObjectiveSelected) {
        addSelectedObjective(objectiveId);
      }

      if (isMetricSelected) {
        removeSelectedMetric(metricId);
      } else {
        addSelectedMetric(metricId);
      }
    },
    [
      addSelectedMetric,
      addSelectedObjective,
      removeSelectedMetric,
      values.metricIds,
      values.objectiveIds,
    ],
  );

  const handleToggleSelectedInitiative = useCallback(
    (objectiveId: Objective['id'], initiativeId: Initiative['id']) => {
      const isObjectiveSelected = values.objectiveIds.includes(objectiveId);
      const isInitiativeSelected = values.initiativeIds.includes(initiativeId);

      if (!isObjectiveSelected) {
        addSelectedObjective(objectiveId);
      }

      if (isInitiativeSelected) {
        removeSelectedInitiative(initiativeId);
      } else {
        addSelectedInitiative(initiativeId);
      }
    },
    [
      addSelectedInitiative,
      addSelectedObjective,
      removeSelectedInitiative,
      values.initiativeIds,
      values.objectiveIds,
    ],
  );

  if (objectives.length === 0) return <div>{t('objective.noObjectives')}</div>;

  return (
    <form
      className={styles.container}
      id={id}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit?.(
          resolveSubmitData({
            objectives,
            initiativeIds: values.initiativeIds,
            metricIds: values.metricIds,
            objectiveIds: values.objectiveIds,
          }),
        );
      }}
    >
      <Flex direction={'column'} gap={14}>
        <SelectableStrategyItemsHeader
          values={values}
          onObjectiveChange={onObjectiveChange}
          onMetricChange={onMetricChange}
          onInitiativeChange={onInitiativeChange}
        />

        <ChipGroup />

        <Space direction={'vertical'} className={styles.selection}>
          {filteredObjectives.length === 0 && (
            <Flex justifyContent={'center'} css={css({ margin: '2rem' })}>
              <Text>{t('strategy.selectableStrategyItems.empty')}</Text>
            </Flex>
          )}

          {filteredObjectivesByThemes.map(
            ({ theme, objectives: themeObjectives }) => (
              <SelectableStrategyItemsSection
                objectives={themeObjectives}
                theme={theme}
                key={theme?.id || '-1'}
                selectedObjectivesIds={values.objectiveIds}
                selectedMetricsIds={values.metricIds}
                selectedInitiativesIds={values.initiativeIds}
                onToggleSelectedObjective={handleToggleSelectedObjective}
                onToggleSelectedInitiative={handleToggleSelectedInitiative}
                onToggleSelectedMetric={handleToggleSelectedMetric}
                onSelectAll={() => {
                  const ids = getAllIds(themeObjectives);
                  addSelectedObjective(...ids.objectivesIds);
                  addSelectedMetric(...ids.metricsIds);
                  addSelectedInitiative(...ids.initiativesIds);
                }}
                onDeselectAll={() => {
                  const ids = getAllIds(themeObjectives);
                  removeSelectedObjective(...ids.objectivesIds);
                  removeSelectedMetric(...ids.metricsIds);
                  removeSelectedInitiative(...ids.initiativesIds);
                }}
              />
            ),
          )}
        </Space>
      </Flex>
    </form>
  );
};

export default (props: SelectableStrategyItemsProps) => (
  <CalendarControlsProvider storeToSession={false}>
    <CompletedFilterProvider storeToSession={false}>
      <ChipGroupProvider>
        <CollaboratingTeamsFilterProvider storeToSession={false}>
          <ThemeFilterProvider storeToSession={false}>
            <StatusFilterProvider storeToSession={false}>
              <PriorityFilterProvider storeToSession={false}>
                <UserFilterProvider storeToSession={false}>
                  <TimelineFilterProvider storeToSession={false}>
                    <SelectableStrategyItemsProvider
                      teamAdapter={props.teamAdapter}
                    >
                      <SelectableStrategyItems {...props} />
                    </SelectableStrategyItemsProvider>
                  </TimelineFilterProvider>
                </UserFilterProvider>
              </PriorityFilterProvider>
            </StatusFilterProvider>
          </ThemeFilterProvider>
        </CollaboratingTeamsFilterProvider>
      </ChipGroupProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
