import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';

import type { SummaryForEdit } from 'ai/AskSophieChat';
import AskSophieChat, { AskSophieProvider } from 'ai/AskSophieChat';
import Modal from 'shared/components/Modal';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useActiveOrg } from 'org/ActiveOrgProvider';
import Flex from 'shared/components/Flex';
import Button from 'shared/components/Button';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';
import { ReactComponent as EditIcon } from 'shared/static/icons/icon-edit.svg';
import { ReactComponent as RefreshIcon } from 'shared/static/icons/icon-refresh.svg';
import ConfirmationDialog from 'shared/components/ConfirmationDialog';

import { useAssesmentWizardModalQuery } from './AssesmentWizardModal.graphql.generated';
import useAssessmentPromptMessages from './useAssessmentPromptMessages';
import AssessmentFormFields from './AssessmentFormFields';
import type { AssessmentFormValues } from './AssessmentForm';

type Props = {
  formId: string;
  isOpen: boolean;
  isSubmitting: boolean;
  onClose: () => void;
};

const AssesmentWizardModal = ({
  isOpen,
  onClose,
  isSubmitting,
  formId,
}: Props) => {
  const { t } = useTranslation();

  const { activeOrg } = useActiveOrg();
  const { orgUnit } = useOrgUnit();
  const [modalOverlayElement, setModalOverlayElement] =
    useState<HTMLDivElement | null>(null);

  const [isEditOpen, setIsEditOpen] = useState(false);

  const { values, setValues, isValid, resetForm } =
    useFormikContext<AssessmentFormValues>();

  useEffect(() => {
    if (isOpen) {
      setIsEditOpen(values.content.length > 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  const onError = useHandleError();

  const { data } = useAssesmentWizardModalQuery({
    variables: {
      filter: {
        orgUnitIds: orgUnit
          ? { operator: 'IN', value: [orgUnit.id] }
          : { operator: 'IS_NULL' },
      },
    },
    onError,
    skip: !isOpen,
  });

  const promptMessages = useAssessmentPromptMessages();

  const strategyId = orgUnit
    ? orgUnit.currentStrategy?.id
    : activeOrg.currentStrategy?.id;

  const objectiveIds = data?.allObjectives.edges.map((edge) => edge.node.id);
  const metricIds = data?.allObjectives.edges.flatMap((edge) =>
    edge.node.metrics.map((metric) => metric.id),
  );
  const initiativeIds = data?.allObjectives.edges.flatMap((edge) =>
    edge.node.initiatives.map((initiative) => initiative.id),
  );
  const actionIds = data?.allObjectives.edges.flatMap((edge) =>
    edge.node.actions.map((action) => action.id),
  );

  const [summaryForEditConfirmation, setSummaryForEditConfirmation] =
    useState<SummaryForEdit>();

  const editAssessment = useCallback(
    (summary: SummaryForEdit) => {
      setValues((values) => ({ ...values, ...summary }));
      setIsEditOpen(true);
    },
    [setValues],
  );

  const isSubmitDisabled = isSubmitting || !isValid || !isEditOpen;

  return (
    <>
      <Modal
        headerTitle={t('assessment.createAssessment.title')}
        isOpen={isOpen}
        onClose={onClose}
        hasPadding={false}
        fullWidth={true}
        overlayRef={setModalOverlayElement}
        confirmLabel={t('publish')}
        isConfirmDisabled={isSubmitDisabled}
        formId={formId}
      >
        {data ? (
          <Flex
            justifyContent={'center'}
            grow={1}
            css={css({ height: '100%' })}
          >
            <div
              css={css({
                flexGrow: 1,
                flexBasis: 0,
                overflow: 'hidden',
                overflowY: 'auto',
              })}
            >
              <AskSophieProvider
                strategyId={strategyId}
                objectiveIds={objectiveIds}
                metricIds={metricIds}
                initiativeIds={initiativeIds}
                actionIds={actionIds}
                summaryFooter={(
                  summary: SummaryForEdit,
                  regenerate: () => void,
                ) => (
                  <Flex gap={8}>
                    <Button
                      icon={RefreshIcon}
                      iconPosition={'start'}
                      variant={'outlined'}
                      onPress={regenerate}
                    >
                      {t('assessment.summary.regenerateButton')}
                    </Button>
                    <Button
                      icon={EditIcon}
                      iconPosition={'start'}
                      variant={isEditOpen ? 'outlined' : 'contained'}
                      onPress={() =>
                        isEditOpen
                          ? setSummaryForEditConfirmation(summary)
                          : editAssessment(summary)
                      }
                    >
                      {t('assessment.summary.editButton')}
                    </Button>
                  </Flex>
                )}
              >
                <AskSophieChat
                  infoText={''}
                  isOpen={isOpen}
                  promptMessages={promptMessages}
                  hideFreeText={true}
                />
              </AskSophieProvider>
            </div>

            <Flex
              css={css({
                flexGrow: 1,
                flexBasis: 0,
                display: isEditOpen ? 'flex' : 'none',
                overflowY: 'auto',
                padding: 20,
              })}
            >
              <AssessmentFormFields overlayElement={modalOverlayElement} />
            </Flex>
          </Flex>
        ) : (
          <Spinner.Circle />
        )}
      </Modal>

      <ConfirmationDialog
        isOpen={!!summaryForEditConfirmation}
        onClose={() => setSummaryForEditConfirmation(undefined)}
        onConfirm={() => {
          if (summaryForEditConfirmation) {
            editAssessment(summaryForEditConfirmation);
          }
        }}
      >
        {t('assessment.summary.editConfirmation')}
      </ConfirmationDialog>
    </>
  );
};

export default AssesmentWizardModal;
