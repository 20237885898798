import Space from 'shared/components/Space';
import { CalendarControlsProvider } from 'shared/filters/CalendarControls';
import CompletedFilterProvider from 'shared/filters/CompletedFilterButton/CompletedFilterProvider';
import CollaboratingTeamsFilterProvider from 'shared/filters/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';
import StatusFilterProvider from 'shared/filters/StatusFilter/StatusFilterProvider';
import ChipGroup from 'shared/filters/ChipGroup';
import { PriorityFilterProvider } from 'shared/filters/PriorityFilter';
import { ChipGroupProvider } from 'shared/filters/ChipGroup/ChipGroupProvider';
import { UserFilterProvider } from 'shared/filters/UserFilter';
import { ThemeFilterProvider } from 'shared/filters/ThemeFilter';
import { TimelineFilterProvider } from 'shared/filters/TimelineFilter';

import TeamInsightsOverviewProvider from '../TeamInsightsOverviewProvider';
import TeamInsightsLayout from '../TeamInsightsLayout';
import TeamInsightsOverviewThemes from '../TeamInsightsOverviewThemes';
import TeamInsightsOverviewOperationalItems from '../TeamInsightsOverviewOperationalItems';
import TeamInsightsOverviewChart from '../TeamInsightsOverviewChart';
import TeamInsightsOverviewFilters from './TeamInsightsOverviewFilters';
import styles from './TeamInsightsOverview.module.scss';

const TeamInsightsOverview = () => (
  <CalendarControlsProvider storeToSession={true}>
    <CompletedFilterProvider storeToSession={true}>
      <ChipGroupProvider>
        <CollaboratingTeamsFilterProvider storeToSession={true}>
          <ThemeFilterProvider storeToSession={false}>
            <StatusFilterProvider storeToSession={true}>
              <PriorityFilterProvider storeToSession={true}>
                <UserFilterProvider storeToSession={true}>
                  <TimelineFilterProvider storeToSession={true}>
                    <TeamInsightsOverviewProvider>
                      <TeamInsightsLayout
                        controls={<TeamInsightsOverviewFilters />}
                        subHeader={<ChipGroup />}
                      >
                        <Space direction={'vertical'}>
                          <Space
                            size={'big'}
                            className={
                              styles.themesAndOperationalItemsContainer
                            }
                          >
                            <TeamInsightsOverviewThemes />
                            <Space
                              direction={'vertical'}
                              className={
                                styles.chartAndOperationalItemsContainer
                              }
                            >
                              <TeamInsightsOverviewOperationalItems>
                                <TeamInsightsOverviewChart />
                              </TeamInsightsOverviewOperationalItems>
                            </Space>
                          </Space>
                        </Space>
                      </TeamInsightsLayout>
                    </TeamInsightsOverviewProvider>
                  </TimelineFilterProvider>
                </UserFilterProvider>
              </PriorityFilterProvider>
            </StatusFilterProvider>
          </ThemeFilterProvider>
        </CollaboratingTeamsFilterProvider>
      </ChipGroupProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);

export default TeamInsightsOverview;
