import type { PropsWithChildren } from 'react';
import { useEffect, useMemo, useState } from 'react';

import useMultiSearchParamState from 'shared/hooks/useMultiSearchParamState';

import type {
  ThemeFilterContextValue,
  ThemeFilterTheme,
} from './ThemeFilter.context';
import ThemeFilterContext from './ThemeFilter.context';
import { useChipGroup } from '../ChipGroup';

const filterName = 'theme';

type Props = PropsWithChildren<{
  storeToSession: boolean;
}>;

const ThemeFilterProvider = ({ storeToSession, children }: Props) => {
  const [availableThemes, setAvailableThemes] = useState<ThemeFilterTheme[]>(
    [],
  );

  const [
    selectedThemeIds,
    setSelectedThemes,
    clearSelectedThemes,
    addSelectedTheme,
    removeSelectedTheme,
  ] = useMultiSearchParamState<string>(filterName, [], storeToSession);

  const selectedThemes = useMemo(
    () =>
      availableThemes.filter((theme) => selectedThemeIds.includes(theme.id)),
    [availableThemes, selectedThemeIds],
  );

  const { setChipItems } = useChipGroup();

  useEffect(
    () =>
      setChipItems(
        filterName,
        clearSelectedThemes,
        selectedThemes.map((theme) => ({
          label: theme.name || theme.id,
          onRemove: () => removeSelectedTheme(theme.id),
        })),
      ),
    [clearSelectedThemes, removeSelectedTheme, selectedThemes, setChipItems],
  );

  const value = useMemo<ThemeFilterContextValue>(
    () => ({
      selectedThemeIds,
      setSelectedThemes,
      clearSelectedThemes,
      addSelectedTheme,
      removeSelectedTheme,
      availableThemes,
      setAvailableThemes,
    }),
    [
      addSelectedTheme,
      availableThemes,
      clearSelectedThemes,
      removeSelectedTheme,
      selectedThemeIds,
      setSelectedThemes,
    ],
  );

  return (
    <ThemeFilterContext.Provider value={value}>
      {children}
    </ThemeFilterContext.Provider>
  );
};

export default ThemeFilterProvider;
