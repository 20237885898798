import { useMemo } from 'react';

import useHandleError from 'shared/errors/useHandleError';

import { flattenData } from './PreviousInsightsSidecard.utils';
import { usePreviousInsightsQuery } from './PreviousInsights.graphql.generated';
import { usePreviousInsights } from './usePreviousInsights';

export function usePreviousInsightsData() {
  const { objectiveId, metricId, initiativeId } = usePreviousInsights();

  const onError = useHandleError();

  const { data } = usePreviousInsightsQuery({
    variables: { objectiveId: objectiveId || '' },
    onError,
    skip: !objectiveId, // missing if data is inconsistent
  });

  return useMemo(() => {
    const {
      objectives,
      metrics,
      metricsOlder,
      initiatives,
      initiativesOlder,
      childObjectives,
      childObjectivesOlder,
      childrenMetrics,
      childrenMetricsOlder,
      childrenInitiatives,
      childrenInitiativesOlder,
    } = data
      ? flattenData(data.objective)
      : {
          objectives: [],
          metrics: [],
          metricsOlder: [],
          initiatives: [],
          initiativesOlder: [],
          childObjectives: [],
          childObjectivesOlder: [],
          childrenMetrics: [],
          childrenMetricsOlder: [],
          childrenInitiatives: [],
          childrenInitiativesOlder: [],
        };

    if (metricId) {
      return {
        previousInsights: [...metrics, ...metricsOlder].filter(
          (item) => item.strategyElementId === metricId,
        ),
        relatedInsights: metrics.filter(
          (item) => item.strategyElementId !== metricId,
        ),
        relatedInsightsOlder: metricsOlder.filter(
          (item) => item.strategyElementId !== metricId,
        ),
        childrenInsights: childrenMetrics,
        childrenInsightsOlder: childrenMetricsOlder,
      };
    } else if (initiativeId) {
      return {
        previousInsights: [...initiatives, ...initiativesOlder].filter(
          (item) => item.strategyElementId === initiativeId,
        ),
        relatedInsights: initiatives.filter(
          (item) => item.strategyElementId !== initiativeId,
        ),
        relatedInsightsOlder: initiativesOlder.filter(
          (item) => item.strategyElementId !== initiativeId,
        ),
        childrenInsights: childrenInitiatives,
        childrenInsightsOlder: childrenInitiativesOlder,
      };
    } else if (objectiveId) {
      return {
        previousInsights: objectives,
        relatedInsights: [...metrics, ...initiatives],
        relatedInsightsOlder: [...metricsOlder, ...initiativesOlder],
        childrenInsights: childObjectives,
        childrenInsightsOlder: childObjectivesOlder,
      };
    } else {
      return {
        previousInsights: undefined,
        relatedInsights: undefined,
        relatedInsightsOlder: undefined,
        childrenInsights: undefined,
        childrenInsightsOlder: undefined,
      };
    }
  }, [data, initiativeId, metricId, objectiveId]);
}
