import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import { useTeamAdapter } from 'team/TeamAdapter';
import { getNodesFromConnection } from 'shared/graphql/utils';
import useHandleError from 'shared/errors/useHandleError';

import {
  useStrategyInsightsReportsForOrgQuery,
  useStrategyInsightsReportsForOrgUnitQuery,
} from './StrategyInsightsReportsProvider.graphql.generated';
import type { StrategyInsightsReportsProviderContextValue } from './StrategyInsightsReportsProvider.context';
import { StrategyInsightsReportsProviderContext } from './StrategyInsightsReportsProvider.context';

type StrategyInsightsReportsProviderProps = PropsWithChildren<object>;

const StrategyInsightsReportsProvider = ({
  children,
}: StrategyInsightsReportsProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const onError = useHandleError();

  const {
    data: orgData,
    loading: orgLoading,
    refetch: refetchOrg,
  } = useStrategyInsightsReportsForOrgQuery({
    skip: teamAdapter.isOrgUnit,
    onError,
  });

  const {
    data: orgUnitData,
    loading: orgUnitLoading,
    refetch: refetchOrgUnit,
  } = useStrategyInsightsReportsForOrgUnitQuery({
    skip: teamAdapter.isOrg,
    variables: { orgUnitId: teamAdapter.keyArg },
    onError,
  });

  const data = teamAdapter.isOrg ? orgData?.activeOrg : orgUnitData?.orgUnit;
  const refetch = teamAdapter.isOrg ? refetchOrg : refetchOrgUnit;
  const loading = teamAdapter.isOrg ? orgLoading : orgUnitLoading;

  const contextValue = useMemo<
    Maybe<StrategyInsightsReportsProviderContextValue>
  >(() => {
    if (data) {
      const reports = getNodesFromConnection(data.reports).flat();
      return {
        reports,
        refetchReports: refetch,
        areReportsLoading: loading,
      };
    }
    return undefined;
  }, [loading, data, refetch]);

  if (contextValue) {
    return (
      <StrategyInsightsReportsProviderContext.Provider value={contextValue}>
        {children}
      </StrategyInsightsReportsProviderContext.Provider>
    );
  }

  return <Spinner.Circle />;
};

export default StrategyInsightsReportsProvider;
