import { useCallback, useMemo } from 'react';
import type { ListState, Selection } from 'react-stately';

import MultiSelect from '../../components/MultiSelect';
import ThemeFilterLabel from './ThemeFilterLabel';
import { useThemeFilter } from './useThemeFilter';

export type ThemeFilterSelectItem = { id: string; title: string };

const renderLabel = (state: ListState<ThemeFilterSelectItem>) => (
  <ThemeFilterLabel state={state} />
);

const ThemeFilter = () => {
  const {
    availableThemes,
    selectedThemeIds: themeIds,
    setSelectedThemes: setThemes,
    clearSelectedThemes: clearThemes,
  } = useThemeFilter();

  const items = useMemo(
    () =>
      availableThemes.map((theme) => ({
        id: theme.id,
        title: theme.name || theme.id,
      })),
    [availableThemes],
  );

  const onSelectionChange = useCallback(
    (selectedKeys: Selection) =>
      selectedKeys === 'all'
        ? clearThemes()
        : setThemes(Array.from(selectedKeys) as string[]),
    [clearThemes, setThemes],
  );

  return (
    <MultiSelect<ThemeFilterSelectItem>
      items={items}
      selectedKeys={themeIds}
      onSelectionChange={onSelectionChange}
      label={renderLabel}
    />
  );
};

export default ThemeFilter;
