import type { TFunction } from 'react-i18next';

import type { StatusIndicator } from 'shared/status';

import type { StatusFilterSelectItemId } from './StatusFilter';
import type { StatusFilterValue } from './StatusFilter.context';

export const getStatusLabel = (
  t: TFunction,
  itemId: StatusFilterSelectItemId,
) => {
  switch (itemId) {
    case 'ALL':
      return t('status.allStatus');
    case 'UNKNOWN':
      return t('statusIndicator.UNKNOWN.name');
    default:
      return t(`statusIndicator.${itemId}.name`);
  }
};

export const testStatusIndicatorAgainstId = (
  id: StatusFilterValue,
  statusIndicator?: Maybe<StatusIndicator>,
) => {
  if (id === 'UNKNOWN') {
    return !statusIndicator?.value;
  }

  return statusIndicator?.value === id;
};

export const testStatusIndicatorAgainstIds = (
  ids: StatusFilterValue[],
  statusIndicator?: Maybe<StatusIndicator>,
) => {
  return (
    ids.length === 0 ||
    ids.some((id) => testStatusIndicatorAgainstId(id, statusIndicator))
  );
};
