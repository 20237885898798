import { useCallback } from 'react';
import type { ListState, Selection } from 'react-stately';

import MultiSelect from '../../components/MultiSelect';
import { useUserFilter } from './useUserFilter';
import UserFilterLabel from './UserFilterLabel';
import UserFilterRow from './UserFilterRow';
import type { UserFilterSelectItem } from './UserFilter.context';

const renderLabel = (state: ListState<UserFilterSelectItem>) => (
  <UserFilterLabel state={state} />
);

const UserFilter = () => {
  const {
    availableUsers,
    selectedUserIds: userIds,
    setSelectedUsers: setUsers,
    clearSelectedUsers: clearUsers,
  } = useUserFilter();

  const onSelectionChange = useCallback(
    (selectedKeys: Selection) =>
      selectedKeys === 'all'
        ? clearUsers()
        : setUsers(Array.from(selectedKeys) as string[]),
    [clearUsers, setUsers],
  );

  return (
    <MultiSelect<UserFilterSelectItem>
      items={availableUsers}
      selectedKeys={userIds}
      onSelectionChange={onSelectionChange}
      label={renderLabel}
      ListBoxRow={UserFilterRow}
    />
  );
};

export default UserFilter;
