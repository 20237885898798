import { Trans, useTranslation } from 'react-i18next';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import ActionsLayoutToggle from 'actions/ActionsLayout/ActionsLayoutToggle';
import Stats from 'shared/components/Stats';
import Stat from 'shared/components/Stats/Stat';
import Heading from 'shared/components/Heading';
import CreateActionButton from 'actions/CreateActionButton';
import Flex from 'shared/components/Flex';
import { getActionsContext } from 'actions/action.utils';
import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';
import type { ActionsLayoutContext } from 'actions/actions.type';
import ChipGroup from 'shared/filters/ChipGroup';

import { getActionsStats } from './ActionsHeader.utils';

const UsersStats = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.backgroundLight};
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: space-between;
  padding: 16px;
`;

const UsersStatsText = styled.div`
  max-width: 230px;
`;

const UsersStatsActiveChart = styled.div`
  flex-basis: 460px;
`;

const UsersStatsOverdueChart = styled.div`
  margin-left: auto;
`;

type ActionsHeaderProps = {
  areAllSectionsExpanded: boolean;
  context: ActionsLayoutContext;
  controls?: ReactNode;
  filters?: ReactNode;
  isToggleExpandButtonDisplayed?: boolean;
  objectives: Maybe<ActionsObjectiveFragment[]>;
  toggleAreAllSectionsExpanded: () => void;
};

const ActionsHeader = ({
  objectives,
  context,
  controls,
  filters,
}: ActionsHeaderProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isObjectiveContext = getActionsContext(pathname) === 'objective';

  const { allActionsLength, hasActions, statsActive, statOverdue } = useMemo(
    () => getActionsStats(objectives, t),
    [objectives, t],
  );

  const statsTextKey = hasActions
    ? `actions.stats.summary.${context}.nrOfActions`
    : `actions.stats.summary.${context}.noActions`;

  return (
    <Flex direction={'column'} gap={12} css={css({ padding: '24px' })}>
      <Flex alignItems={'center'} justifyContent={'space-between'} gap={8}>
        <Heading level={3} hasMargin={false} css={css({ flex: '1' })}>
          {t('actions.heading')}
        </Heading>

        {controls}

        <Flex
          gap={8}
          alignItems={'center'}
          justifyContent={'flex-end'}
          css={css({ flex: '1' })}
        >
          {filters}

          <ActionsLayoutToggle />

          <CreateActionButton
            objective={isObjectiveContext ? objectives?.[0] : undefined}
            showSuccessDialog={!isObjectiveContext}
          >
            {t('actions.buttons.createAction')}
          </CreateActionButton>
        </Flex>
      </Flex>

      <UsersStats>
        <UsersStatsText>
          <Trans
            i18nKey={statsTextKey}
            values={{
              actionsCount: allActionsLength,
              objectivesCount: objectives?.length,
            }}
          />
        </UsersStatsText>
        <UsersStatsOverdueChart>
          <Stat stat={statOverdue} />
        </UsersStatsOverdueChart>
        <UsersStatsActiveChart>
          <Stats stats={statsActive} />
        </UsersStatsActiveChart>
      </UsersStats>

      <ChipGroup />
    </Flex>
  );
};

export default ActionsHeader;
