import { css } from '@emotion/react';

import FiltersButton from 'shared/filters/FiltersButton';
import CalendarControls from 'shared/filters/CalendarControls';
import Flex from 'shared/components/Flex';
import CompletedFilterButton from 'shared/filters/CompletedFilterButton';
import SendMultipleStatusRequestButton from 'status/SendMultipleStatusRequestsButton';
import StatusFilter from 'shared/filters/StatusFilter';
import PriorityFilter from 'shared/filters/PriorityFilter';
import UserFilter from 'shared/filters/UserFilter';
import TimelineFilter from 'shared/filters/TimelineFilter';
import CollaboratingTeamsFilter from 'shared/filters/CollaboratingTeamsFilter';

import TeamInsightsCreateReportButton from '../TeamInsightsCreateReportButton';
import TeamInsightsOverviewAskSophieButton from './TeamInsightsOverviewAskSophieButton';
import { useTeamInsightsOverview } from '../TeamInsightsOverviewProvider';

const TeamInsightsOverviewFilters = () => {
  const { filteredObjectives } = useTeamInsightsOverview();

  const statusRequestElements = [
    ...filteredObjectives.filter(
      (objective) =>
        objective.isFiltersMatch && !objective.currentObjectiveStatus?.complete,
    ),
    ...filteredObjectives
      .flatMap((objective) => objective.metrics)
      .filter((metric) => !metric.currentMetricStatus?.complete),
    ...filteredObjectives
      .flatMap((objective) => objective.initiatives)
      .filter((initiative) => !initiative.currentInitiativeStatus?.complete),
  ];

  return (
    <>
      <CalendarControls />

      <Flex gap={8} css={css({ flex: '1', justifyContent: 'flex-end' })}>
        <CompletedFilterButton />

        <FiltersButton>
          <StatusFilter />
          <PriorityFilter />
          <UserFilter />
          <TimelineFilter />
          <CollaboratingTeamsFilter />
        </FiltersButton>

        <TeamInsightsOverviewAskSophieButton />
        <SendMultipleStatusRequestButton
          strategyElements={statusRequestElements}
        />
        <TeamInsightsCreateReportButton />
      </Flex>
    </>
  );
};

export default TeamInsightsOverviewFilters;
