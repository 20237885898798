import { useDefinedContext } from 'shared/utils/context.utils';

import { ChipGroupContext } from './ChipGroup.context';
import { ChipGroupProvider } from './ChipGroupProvider';

export const useChipGroup = () =>
  useDefinedContext(ChipGroupContext, {
    hookName: useChipGroup.name,
    providerName: ChipGroupProvider.name,
  });
