import { createContext } from 'react';

import type { StatusIndicator } from 'types.graphql.generated';

export type StatusFilterValue = StatusIndicator | 'UNKNOWN';

export type StatusFilterContextValue = {
  addStatus: (status: StatusFilterValue) => void;
  clearStatuses: () => void;
  removeStatus: (status: StatusFilterValue) => void;
  setStatuses: (statuses: StatusFilterValue[]) => void;
  statuses: StatusFilterValue[];
};

const StatusFilterContext = createContext<StatusFilterContextValue | undefined>(
  undefined,
);

export default StatusFilterContext;
