import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ListState, Selection } from 'react-stately';
import { last, without } from 'lodash';

import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';

import MultiSelect from '../../components/MultiSelect';
import type { PriorityFilterValue } from './PriorityFilter.context';
import { usePriorityFilter } from './usePriorityFilter';
import { getPriorityLabel } from './PriorityFilter.utils';
import PriorityFilterLabel from './PriorityFilterLabel';

export type PriorityFilterSelectItemId = PriorityFilterValue | 'ALL';

export type PriorityFilterSelectItem = { id: PriorityFilterSelectItemId };

const renderLabel = (state: ListState<PriorityFilterSelectItem>) => (
  <PriorityFilterLabel state={state} />
);

const PriorityFilter = () => {
  const { t } = useTranslation();

  const { priorities } = useStrategyProfilePriorities();

  const {
    priorities: selectedPriorities,
    setPriorities,
    clearPriorities,
  } = usePriorityFilter();

  const items = useMemo(
    () =>
      (
        [
          'ALL',
          'UNKNOWN',
          ...(priorities || []).map((priority) => priority.number),
        ] satisfies PriorityFilterSelectItemId[]
      ).map((id) => ({ id, title: getPriorityLabel(t, id, priorities) })),
    [priorities, t],
  );

  const selectedKeys = useMemo(
    () => (selectedPriorities.length === 0 ? ['ALL'] : selectedPriorities),
    [selectedPriorities],
  );

  const onSelectionChange = useCallback(
    (selectedKeys: Selection) =>
      selectedKeys === 'all' || last(Array.from(selectedKeys)) === 'ALL'
        ? clearPriorities()
        : setPriorities(
            without([...selectedKeys], 'ALL') as PriorityFilterValue[],
          ),

    [clearPriorities, setPriorities],
  );

  return (
    <MultiSelect<PriorityFilterSelectItem>
      items={items}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      label={renderLabel}
    />
  );
};

export default PriorityFilter;
