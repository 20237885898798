import { useTranslation } from 'react-i18next';
import type { Dispatch, SetStateAction } from 'react';

import Space from 'shared/components/Space';
import TimelineProgress from 'shared/components/TimelineProgress';
import Text from 'shared/components/Text';
import StatusCard from 'shared/status/StatusCard';
import { ReactComponent as MilestoneIcon } from 'shared/static/icons/icon-milestone.svg';
import { StatusIndicator } from 'shared/status/StatusIndicator';
import { ReactComponent as PencilIcon } from 'shared/static/icons/icon-pencil.svg';
import type { MilestoneStatusFormSubmitValues } from 'initiative/MilestoneStatusForm';
import StatusCompletionRateProgress from 'shared/status/StatusCompletionRateProgress';

import type { InitiativeStatusMilestone } from '../InitiativeStatusWizard.type';
import styles from './InitiativeStatusWizardMilestonesList.module.scss';

type InitiativeStatusWizardMilestonesListProps = {
  getMilestoneStatusData: (
    key: string,
  ) => MilestoneStatusFormSubmitValues | null;
  milestones: InitiativeStatusMilestone[];
  setCurrentMilestoneId: Dispatch<SetStateAction<string | undefined>>;
};

const InitiativeStatusWizardMilestonesList = ({
  milestones,
  setCurrentMilestoneId,
  getMilestoneStatusData,
}: InitiativeStatusWizardMilestonesListProps) => {
  const { t } = useTranslation();

  return milestones.length ? (
    <>
      {milestones.map((milestone) => {
        const milestoneStatusData = getMilestoneStatusData(milestone.id);
        const completionRate =
          milestoneStatusData?.completionRate ??
          milestone.currentMilestoneStatus?.completionRate;
        const comment =
          milestoneStatusData?.comment ||
          milestone.currentMilestoneStatus?.comment;
        const timeline = milestone.timeLine;
        const statusIndicator =
          milestoneStatusData?.statusIndicator ||
          milestone.currentMilestoneStatus?.statusIndicator.value;
        const isCompleted =
          milestoneStatusData?.complete ||
          milestone.currentMilestoneStatus?.complete;
        return (
          <StatusCard
            heading={milestone.name || ''}
            comment={milestone.description || comment || ''}
            icon={MilestoneIcon}
            key={milestone.id}
            onClick={() => setCurrentMilestoneId(milestone.id)}
            buttons={[
              {
                icon: PencilIcon,
                iconPosition: 'start',
                variant: 'outlined',
                children: t('update'),
                iconSize: 'small',
                onPress: () => setCurrentMilestoneId(milestone.id),
              },
            ]}
            statusIndicator={
              new StatusIndicator(statusIndicator, {
                isCompleted,
              })
            }
            alwaysShowButtons={true}
          >
            <Space className={styles.milestoneCardIndicators}>
              {timeline ? (
                <TimelineProgress
                  label={t(
                    'initiative.initiativeStatusWizard.steps.milestones.milestones.milestone.deadline',
                  )}
                  timeline={timeline as any}
                />
              ) : null}
              <StatusCompletionRateProgress completionRate={completionRate} />
            </Space>
          </StatusCard>
        );
      })}
    </>
  ) : (
    <Text variant={'emphasis'}>{t('initiative.noMilestones')}</Text>
  );
};

export default InitiativeStatusWizardMilestonesList;
