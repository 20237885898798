import { createContext } from 'react';

import type { User } from 'types.graphql.generated';

export type UserFilterSelectItem = Pick<
  User,
  'id' | 'displayName' | 'email' | 'photoUrl' | 'isActive' | 'isInActiveOrg'
>;

export type UserFilterContextValue = {
  addSelectedUser: (userId: string) => void;
  availableUsers: UserFilterSelectItem[];
  clearSelectedUsers: () => void;
  removeSelectedUser: (userId: string) => void;
  selectedUserIds: string[];
  setAvailableUsers: (users: UserFilterSelectItem[]) => void;
  setSelectedUsers: (userIds: string[]) => void;
};

const UserFilterContext = createContext<UserFilterContextValue | undefined>(
  undefined,
);

export default UserFilterContext;
