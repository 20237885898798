import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import useMultiSearchParamState from 'shared/hooks/useMultiSearchParamState';

import type {
  StatusFilterContextValue,
  StatusFilterValue,
} from './StatusFilter.context';
import StatusFilterContext from './StatusFilter.context';
import { useChipGroup } from '../ChipGroup';
import { getStatusLabel } from './StatusFilter.utils';

const filterName = 'status';

type Props = PropsWithChildren<{ storeToSession: boolean }>;

const StatusFilterProvider = ({ storeToSession, children }: Props) => {
  const { t } = useTranslation();

  const [statuses, setStatuses, clearStatuses, addStatus, removeStatus] =
    useMultiSearchParamState<StatusFilterValue>(filterName, [], storeToSession);

  const { setChipItems } = useChipGroup();

  useEffect(
    () =>
      setChipItems(
        filterName,
        clearStatuses,
        statuses.map((status) => ({
          label: getStatusLabel(t, status),
          onRemove: () => removeStatus(status),
        })),
      ),
    [clearStatuses, removeStatus, setChipItems, statuses, t],
  );

  const value = useMemo<StatusFilterContextValue>(
    () => ({ statuses, setStatuses, clearStatuses, addStatus, removeStatus }),
    [addStatus, clearStatuses, removeStatus, setStatuses, statuses],
  );

  return (
    <StatusFilterContext.Provider value={value}>
      {children}
    </StatusFilterContext.Provider>
  );
};

export default StatusFilterProvider;
