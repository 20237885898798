import { useTranslation } from 'react-i18next';
import type { Dispatch, PropsWithChildren, SetStateAction } from 'react';

import Button from 'shared/components/Button';
import Drawer from 'shared/components/Drawer';
import { ReactComponent as ArrowRightIcon } from 'shared/static/icons/icon-arrow-right.svg';
import { milestoneStatusFormId } from 'initiative/MilestoneStatusForm';
import TimelineProgress from 'shared/components/TimelineProgress';
import Text from 'shared/components/Text';

import styles from './InitiativeStatusWizardMilestonesDrawer.module.scss';
import type { InitiativeStatusMilestone } from '../InitiativeStatusWizard.type';

type InitiativeStatusWizardMilestonesDrawerProps = PropsWithChildren<{
  currentMilestone: Maybe<InitiativeStatusMilestone>;
  milestones: InitiativeStatusMilestone[];
  setCurrentMilestoneId: Dispatch<SetStateAction<string | undefined>>;
}>;

const InitiativeStatusWizardMilestonesDrawer = ({
  milestones,
  currentMilestone,
  setCurrentMilestoneId,
  children,
}: InitiativeStatusWizardMilestonesDrawerProps) => {
  const { t } = useTranslation();

  return (
    <Drawer
      heading={t(
        'initiative.initiativeStatusWizard.steps.milestones.drawer.heading',
        {
          index: milestones.indexOf(currentMilestone!) + 1,
        },
      )}
      placement={'right'}
      isOpen={!!currentMilestone}
      onClose={() => setCurrentMilestoneId(undefined)}
      width={'medium'}
      renderFooterContent={() => (
        <Button
          type={'submit'}
          form={milestoneStatusFormId}
          icon={ArrowRightIcon}
          variant={'contained'}
          className={styles.milestoneStatusDrawerSubmitButton}
        >
          {t(
            `initiative.initiativeStatusWizard.steps.milestones.drawer.controls.nextButton`,
          )}
        </Button>
      )}
      className={styles.milestoneStatusDrawer}
      scrollbarGutter={'auto'}
      scrollType={'outer'}
    >
      {currentMilestone?.name ? (
        <Text variant={'emphasis'}>{currentMilestone.name}</Text>
      ) : null}
      {currentMilestone?.timeLine ? (
        <TimelineProgress
          label={t(
            'initiative.initiativeStatusWizard.steps.milestones.drawer.timelineProgressLabel',
          )}
          timeline={currentMilestone.timeLine as any}
          className={styles.milestoneStatusDrawerTimelineProgress}
        />
      ) : null}
      {children}
    </Drawer>
  );
};

export default InitiativeStatusWizardMilestonesDrawer;
