import { createContext } from 'react';

export type PriorityFilterValue = number | 'UNKNOWN';

export type PriorityFilterContextValue = {
  addPriority: (priority: PriorityFilterValue) => void;
  clearPriorities: () => void;
  priorities: PriorityFilterValue[];
  removePriority: (priority: PriorityFilterValue) => void;
  setPriorities: (priorities: PriorityFilterValue[]) => void;
};

const PriorityFilterContext = createContext<
  PriorityFilterContextValue | undefined
>(undefined);

export default PriorityFilterContext;
