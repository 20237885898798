import { Suspense } from 'react';
import { useToggle } from 'react-use';

import ActionsHeader from 'actions/ActionsLayout/ActionsHeader';
import ActionsLayoutProvider from 'actions/ActionsLayout/ActionsLayoutProvider';
import { useActionsLayout } from 'actions/ActionsLayout/ActionsLayoutProvider/useActionsLayout';
import type { ActionsObjectiveFragment } from 'actions/actions.fragment.graphql.generated';
import type { ActionsLayoutContext } from 'actions/actions.type';
import Spinner from 'shared/spinner/Spinner';
import Layout from 'shared/components/Layout';
import ToggleExpandIconButton from 'shared/components/ToggleExpandIconButton';
import SortActionsControl from 'actions/ObjectiveActions/SortActionsControl';
import ActionsGrid from 'actions/ActionsGrid';
import ActionsList from 'actions/ActionsList';
import CalendarControls, {
  CalendarControlsProvider,
} from 'shared/filters/CalendarControls';
import CompletedFilterProvider from 'shared/filters/CompletedFilterButton/CompletedFilterProvider';
import CompletedFilterButton from 'shared/filters/CompletedFilterButton';
import CollaboratingTeamsFilterProvider from 'shared/filters/CollaboratingTeamsFilter/CollaboratingTeamsFilterProvider';
import { ChipGroupProvider } from 'shared/filters/ChipGroup/ChipGroupProvider';
import { PriorityFilterProvider } from 'shared/filters/PriorityFilter';
import UserFilter, { UserFilterProvider } from 'shared/filters/UserFilter';
import TimelineFilter, {
  TimelineFilterProvider,
} from 'shared/filters/TimelineFilter';
import FiltersButton from 'shared/filters/FiltersButton';
import CollaboratingTeamsFilter from 'shared/filters/CollaboratingTeamsFilter';
import ActionStatusFilter from 'shared/filters/ActionStatusFilter';
import ActionStatusFilterProvider from 'shared/filters/ActionStatusFilter/ActionStatusFilterProvider';

import styles from './ActionsLayout.module.scss';

type ActionsLayoutProps = {
  context: ActionsLayoutContext;
  objectives: Maybe<ActionsObjectiveFragment[]>;
  showCalendarControls: boolean;
  showCollaboratingTeamsFilter: boolean;
  storeFiltersToSession: boolean;
};

const ActionsLayout = ({
  objectives,
  context,
  showCalendarControls,
  showCollaboratingTeamsFilter,
}: ActionsLayoutProps) => {
  const { layout } = useActionsLayout();

  const [areAllSectionsExpanded, toggleAreAllSectionsExpanded] =
    useToggle(true);

  return (
    <Layout.Content
      hasPadding={false}
      className={styles.layout}
      variant={'contained'}
    >
      <ActionsHeader
        objectives={objectives}
        context={context}
        areAllSectionsExpanded={areAllSectionsExpanded}
        toggleAreAllSectionsExpanded={toggleAreAllSectionsExpanded}
        isToggleExpandButtonDisplayed={layout === 'list'}
        controls={showCalendarControls && <CalendarControls />}
        filters={
          <>
            <CompletedFilterButton />

            <FiltersButton>
              <ActionStatusFilter />
              <UserFilter />
              <TimelineFilter />
              {showCollaboratingTeamsFilter && <CollaboratingTeamsFilter />}
            </FiltersButton>

            <ToggleExpandIconButton
              isExpanded={areAllSectionsExpanded}
              onToggle={toggleAreAllSectionsExpanded}
              isDisabled={layout !== 'list'}
            />

            <SortActionsControl />
          </>
        }
      />
      <Suspense fallback={<Spinner.Circle />}>
        {layout === 'grid' ? (
          <ActionsGrid objectives={objectives} />
        ) : (
          <ActionsList
            objectives={objectives}
            areAllSectionsExpanded={areAllSectionsExpanded}
          />
        )}
      </Suspense>
    </Layout.Content>
  );
};

export default (props: ActionsLayoutProps) => (
  <CalendarControlsProvider
    initialCalendarInterval={props.showCalendarControls ? undefined : 'all'}
    storeToSession={props.showCalendarControls}
  >
    <CompletedFilterProvider storeToSession={props.showCalendarControls}>
      <ChipGroupProvider>
        <CollaboratingTeamsFilterProvider
          storeToSession={props.showCalendarControls}
        >
          <ActionStatusFilterProvider
            storeToSession={props.showCalendarControls}
          >
            <PriorityFilterProvider storeToSession={props.showCalendarControls}>
              <UserFilterProvider storeToSession={props.showCalendarControls}>
                <TimelineFilterProvider
                  storeToSession={props.showCalendarControls}
                >
                  <ActionsLayoutProvider objectives={props.objectives}>
                    <ActionsLayout {...props} />
                  </ActionsLayoutProvider>
                </TimelineFilterProvider>
              </UserFilterProvider>
            </PriorityFilterProvider>
          </ActionStatusFilterProvider>
        </CollaboratingTeamsFilterProvider>
      </ChipGroupProvider>
    </CompletedFilterProvider>
  </CalendarControlsProvider>
);
