import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

import type { Initiative, Milestone } from 'types.graphql.generated';
import { useToasts } from 'shared/toast/useToasts';
import useHandleError from 'shared/errors/useHandleError';
import type { StrategyElementWithAuthorizedActions } from 'user/ability/canPerformStrategyElementAction';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';

import { useRemoveInitiativeMilestoneMutation } from './useRemoveInitiativeMilestone.graphql.generated';

export type UseRemoveInitiativeMilestoneParams = {
  initiative: Pick<Initiative, 'id'> & StrategyElementWithAuthorizedActions;
  milestone: Pick<Milestone, 'id'>;
};

export type UseRemoveInitiativeMilestoneReturnType = {
  canRemoveInitiativeMilestone: boolean;
  removeInitiativeMilestone: (params?: {
    onSuccess?: () => void;
  }) => Promise<void>;
};

export const useRemoveInitiativeMilestone = ({
  milestone,
  initiative,
}: UseRemoveInitiativeMilestoneParams): UseRemoveInitiativeMilestoneReturnType => {
  const { t } = useTranslation();

  const onError = useHandleError();

  const { addToast } = useToasts();

  const canRemoveInitiativeMilestone = canPerformStrategyElementAction(
    initiative,
    'WRITE',
  );

  const [removeInitiativeMilestone] = useRemoveInitiativeMilestoneMutation({
    variables: {
      initiativeIdToUpdate: initiative.id,
      milestoneIdToRemove: milestone.id,
    },
    onError,
    onCompleted: () => {
      addToast({
        id: 'removeInitiativeMilestoneSuccessToast',
        children: t('updateSuccessToast'),
        variant: 'success',
      });
    },
    update: (cache) =>
      setTimeout(() => cache.evict({ id: `Milestone:${milestone.id}` }), 100),
  });

  const handleRemoveInitiativeMilestone = useCallback<
    UseRemoveInitiativeMilestoneReturnType['removeInitiativeMilestone']
  >(
    async ({ onSuccess } = {}) => {
      if (canRemoveInitiativeMilestone) {
        await removeInitiativeMilestone({
          onCompleted: () => {
            onSuccess?.();
          },
        });
      }
    },
    [canRemoveInitiativeMilestone, removeInitiativeMilestone],
  );

  return {
    canRemoveInitiativeMilestone,
    removeInitiativeMilestone: handleRemoveInitiativeMilestone,
  };
};
