import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';

import { useThemeFormQuery } from './ThemeFormProvider.graphql.generated';
import type { ThemeFormContextValue } from './ThemeFormProvider.context';
import { ThemeFormContext } from './ThemeFormProvider.context';

type ThemeFormProviderProps = PropsWithChildren<object>;

const ThemeFormProvider = ({ children }: ThemeFormProviderProps) => {
  const onError = useHandleError();

  const { data, loading } = useThemeFormQuery({ onError });

  const contextValue = useMemo<ThemeFormContextValue>(
    () =>
      data?.activeOrg.currentStrategyProfile?.themeCategories.map(
        (themeCategory) => ({
          id: themeCategory.id,
          title: themeCategory.name!,
        }),
      ) || [],
    [data?.activeOrg.currentStrategyProfile?.themeCategories],
  );

  if (loading) {
    return <Spinner.Circle />;
  }

  return (
    <ThemeFormContext.Provider value={contextValue}>
      {children}
    </ThemeFormContext.Provider>
  );
};

export default ThemeFormProvider;
