import { css } from '@emotion/react';

import type { ListBoxRowComponentProps } from 'shared/components/ListBox';
import Text from 'shared/components/Text';
import Space from 'shared/components/Space';
import UserAvatar from 'user/UserAvatar';

import type { UserFilterSelectItem } from './UserFilter.context';

type Props = ListBoxRowComponentProps<UserFilterSelectItem>;

const UserFilterRow = ({ item }: Props) => (
  <Space size={'small'} isCentered={true}>
    <UserAvatar
      user={item}
      showTooltip={false}
      css={css({ pointerEvents: 'none' })}
    />
    <Text variant={'emphasis'}>{item.displayName || item.email}</Text>
  </Space>
);

export default UserFilterRow;
