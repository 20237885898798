import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import type { MembersProps } from 'user/Members';
import { useToasts } from 'shared/toast/useToasts';
import { UserEmailInputOrgEmployeesDocument } from 'user/UserEmailInput';
import Members from 'user/Members';
import ObjectiveLink from 'objective/ObjectiveLink';
import SiderDescription from 'shared/components/Description';
import DescriptionDates from 'shared/components/DescriptionDates';
import { useTeamAdapter } from 'team/TeamAdapter';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';
import { useUpdateInitiativeMutation } from 'initiative/EditInitiative/EditInitiative.graphql.generated';
import useHandleError from 'shared/errors/useHandleError';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import Heading from 'shared/components/Heading';
import Flex from 'shared/components/Flex';

import styles from './InitiativeOverviewSider.module.scss';
import { useInitiativeOverview } from '../InitiativeOverviewProvider';
import { InitiativeOverviewDocument } from '../InitiativeOverviewProvider/InitiativeOverviewProvider.graphql.generated';

const InitiativeSider = () => {
  const { t } = useTranslation();

  const { addToast } = useToasts();

  const { teamAdapter } = useTeamAdapter();
  const { initiative } = useInitiativeOverview();

  const onError = useHandleError();

  const [updateInitiative] = useUpdateInitiativeMutation({
    onError,
    refetchQueries: [
      UserEmailInputOrgEmployeesDocument,
      {
        query: InitiativeOverviewDocument,
        variables: { initiativeId: initiative?.id },
      },
      ContributionMyContributionDocument,
    ],
  });

  const usersToExcludeFromAddingToTeam = useMemo(() => {
    const ownerUser = initiative?.owner
      ? { id: initiative?.owner.id }
      : undefined;
    return initiative?.teamMembers
      ? [
          ownerUser,
          ...initiative.teamMembers.map((teamMember) => {
            const userId = teamMember?.user?.id;
            if (userId) return { id: userId };
          }),
        ].filter(Boolean)
      : [];
  }, [initiative]);

  const handleAddTeamMember: NonNullable<
    MembersProps['onAddNewMember']
  > = async (data, closeModal) => {
    if (initiative) {
      await updateInitiative({
        variables: {
          input: {
            idToUpdate: initiative.id,
            teamMembers: {
              add: [
                {
                  user: { emailToSet: data.user },
                  ...(data.role && {
                    role: data.role,
                  }),
                },
              ],
            },
          },
        },
        onCompleted: () => {
          closeModal();
          addToast({
            children: t(
              'initiative.initiativeLayout.addTeamMemberSuccessToast',
            ),
            variant: 'success',
          });
        },
      });
    }
  };

  const handleRemoveTeamMember: NonNullable<
    MembersProps['onRemoveMember']
  > = async (member) => {
    if (initiative) {
      await updateInitiative({
        variables: {
          input: {
            idToUpdate: initiative.id,
            teamMembers: {
              idsToDelete: [member.id],
            },
          },
        },
        onCompleted: () => {
          addToast({
            children: t(
              'initiative.initiativeLayout.removeTeamMemberSuccessToast',
              {
                name: member.user?.displayName || member.user?.email,
              },
            ),
            variant: 'success',
          });
        },
      });
    }
  };

  if (!initiative) return null;

  const canWrite = canPerformStrategyElementAction(initiative, 'WRITE');

  return (
    <div className={styles.container}>
      <SiderDescription description={initiative.description} />
      <DescriptionDates
        startDate={initiative.timeLine.startDate}
        endDate={initiative.timeLine.endDate}
        completeDateTime={initiative.timeLine.completeDateTime}
      />
      <Heading level={3} as={5} hasMargin={false}>
        {t('objective.objective')}
      </Heading>
      {initiative.objective && (
        <ObjectiveLink objective={initiative.objective} isLink={false} />
      )}
      {initiative.linkedObjectives.length > 0 && (
        <Heading level={3} as={5} hasMargin={false}>
          {t('objective.linkedObjective_other')}
        </Heading>
      )}
      <Flex direction={'column'} gap={8}>
        {initiative.linkedObjectives.map((objective) => (
          <ObjectiveLink
            key={objective.id}
            objective={objective}
            isLink={true}
          />
        ))}
      </Flex>
      <Members
        owner={initiative.owner}
        members={initiative.teamMembers.map((teamMember) => ({
          id: teamMember.id,
          role: teamMember.role,
          ...(teamMember.user && {
            user: {
              id: teamMember.user?.id,
              email: teamMember.user?.email,
              displayName: teamMember.user?.displayName,
              photoUrl: teamMember.user?.photoUrl,
              isActive: teamMember.user?.isActive,
              isInActiveOrg: teamMember.user?.isInActiveOrg,
            },
          }),
        }))}
        teamAdapter={teamAdapter}
        usersToExclude={usersToExcludeFromAddingToTeam}
        canAddNewMember={canWrite}
        onAddNewMember={handleAddTeamMember}
        canRemoveMember={canWrite}
        onRemoveMember={handleRemoveTeamMember}
      />
    </div>
  );
};

export default InitiativeSider;
