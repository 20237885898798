import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';

import Tooltip from 'shared/components/Tooltip';
import { ReactComponent as CloseIcon } from 'shared/static/icons/icon-close.svg';
import useHandleError from 'shared/errors/useHandleError';
import { useToasts } from 'shared/toast/useToasts';
import Dialog from 'shared/components/Dialog';
import { ContributionMyContributionDocument } from 'contribution/ContributionMyContribution/ContributionMyContribution.graphql.generated';

import { useDeleteStatusRequestMutation } from './DeleteStatusRequest.graphql.generated';
import { ContributionMyFollowUpsViewMoreDocument } from '../ContributionMyFollowUpsViewMore.graphql.generated';

const Container = styled.div`
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;

  :hover {
    background-color: ${(props) => props.theme.color.backgroundLight};
  }

  svg {
    width: 16px;
    height: 16px;
  }
`;

type Props = {
  statusRequestId: string;
};

const DeleteStatusRequestButton = ({ statusRequestId }: Props) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const onError = useHandleError();

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const [deleteStatusRequest, { loading }] = useDeleteStatusRequestMutation({
    variables: { statusRequestId },
    onError,
    refetchQueries: [
      ContributionMyContributionDocument,
      ContributionMyFollowUpsViewMoreDocument,
    ],
  });

  const handleClose = useCallback(() => {
    setIsConfirmationOpen(false);
  }, []);

  const handleConfirm = useCallback(() => {
    handleClose();

    deleteStatusRequest().then((result) => {
      if (result.data?.deleteStatusRequests) {
        addToast({
          variant: 'success',
          children: t(
            'followUps.timeline.insightRequest.archive.confirmation.successMessage',
          ),
        });
      }
    });
  }, [addToast, deleteStatusRequest, handleClose, t]);

  return (
    <>
      <Tooltip
        content={t('followUps.timeline.insightRequest.archive.buttonTooltip')}
      >
        <Container onClick={() => setIsConfirmationOpen(true)}>
          <CloseIcon />
        </Container>
      </Tooltip>

      <Dialog
        headerTitle={t(
          'followUps.timeline.insightRequest.archive.confirmation.title',
        )}
        isOpen={isConfirmationOpen}
        onClose={handleClose}
        onConfirm={handleConfirm}
        size={'tiny'}
        confirmLabel={t(
          'followUps.timeline.insightRequest.archive.confirmation.confirmButton',
        )}
        cancelLabel={t('no')}
        isConfirmDisabled={loading}
      >
        {t('followUps.timeline.insightRequest.archive.confirmation.content')}
      </Dialog>
    </>
  );
};

export default DeleteStatusRequestButton;
