import { createContext } from 'react';

import type { Theme } from 'types.graphql.generated';
import type { ObjectivesByTheme } from 'objective/objective.utils';

import type { SelectableStrategyItemsObjectiveFragment } from './SelectableStrategyItemsProvider.graphql.generated';

type SelectableTheme = Pick<Theme, 'id' | 'name' | 'orderNumber'>;

export type SelectableStrategyItemsProviderContextValue = {
  filteredObjectives: SelectableStrategyItemsObjectiveFragment[];
  filteredObjectivesByThemes: ObjectivesByTheme<
    SelectableStrategyItemsObjectiveFragment,
    SelectableTheme
  >[];
  objectives: SelectableStrategyItemsObjectiveFragment[];
};

export const SelectableStrategyItemsProviderContext = createContext<
  SelectableStrategyItemsProviderContextValue | undefined
>(undefined);
