import type { PropsWithChildren } from 'react';
import { useState, useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';

import {
  ChipGroupContext,
  type ChipGroupContextValue,
  type ChipGroupItem,
} from './ChipGroup.context';

export const ChipGroupProvider = ({ children }: PropsWithChildren) => {
  const [itemsByScope, setItemsByScope] = useState<
    Record<string, ChipGroupItem[]>
  >({});

  const [onRemoveAllByScope, setOnRemoveAllByScope] = useState<
    Record<string, () => void>
  >({});

  const chipItems = useMemo(
    () => Object.values(itemsByScope).flat(),
    [itemsByScope],
  );

  const setChipItems = useCallback(
    (scopeId: string, onRemoveAll: () => void, items: ChipGroupItem[]) => {
      if (
        isEqual(
          itemsByScope[scopeId]?.map((item) => item.label).sort(),
          items.map((item) => item.label).sort(),
        )
      ) {
        return;
      }

      setItemsByScope((prevScopedItems) => ({
        ...prevScopedItems,
        [scopeId]: items,
      }));

      setOnRemoveAllByScope((prevOnRemoveAllByScope) => ({
        ...prevOnRemoveAllByScope,
        [scopeId]: onRemoveAll,
      }));
    },
    [itemsByScope],
  );

  const clearAllChipItems = useCallback(
    () =>
      Object.values(onRemoveAllByScope).forEach((onRemoveAll) => onRemoveAll()),
    [onRemoveAllByScope],
  );

  const value = useMemo<ChipGroupContextValue>(
    () => ({
      chipItems,
      setChipItems,
      clearAllChipItems,
    }),
    [chipItems, clearAllChipItems, setChipItems],
  );

  return (
    <ChipGroupContext.Provider value={value}>
      {children}
    </ChipGroupContext.Provider>
  );
};
