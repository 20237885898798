import type { PropsWithChildren } from 'react';
import { useMemo } from 'react';

import type { Strategy } from 'types.graphql.generated';
import Spinner from 'shared/spinner/Spinner';
import useHandleError from 'shared/errors/useHandleError';

import type { EditStrategyWizardProviderContextValue } from './EditStrategyWizardProvider.context';
import { EditStrategyWizardProviderContext } from './EditStrategyWizardProvider.context';
import { useEditStrategyWizardStrategyQuery } from './EditStrategyWizardProvider.graphql.generated';

type EditStrategyWizardProviderProps = PropsWithChildren<{
  strategy: Pick<Strategy, 'id'>;
}>;

const EditStrategyWizardProvider = ({
  strategy,
  children,
}: EditStrategyWizardProviderProps) => {
  const onError = useHandleError();

  const { data, loading } = useEditStrategyWizardStrategyQuery({
    variables: { strategyId: strategy.id },
    onError,
  });

  const contextValue = useMemo<
    EditStrategyWizardProviderContextValue | undefined
  >(() => {
    if (data) {
      return {
        strategy: data.strategy,
      };
    }
    return undefined;
  }, [data]);

  if (contextValue) {
    return (
      <EditStrategyWizardProviderContext.Provider value={contextValue}>
        {children}
      </EditStrategyWizardProviderContext.Provider>
    );
  }

  if (loading) {
    return <Spinner.Circle isCentered={true} />;
  }

  return null;
};

export default EditStrategyWizardProvider;
