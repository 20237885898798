import Chip from 'shared/filters/Chip';

import ClearAllButton from '../../components/ClearAllButton';
import Flex from '../../components/Flex';
import { useChipGroup } from './useChipGroup';

const ChipGroup = () => {
  const { chipItems, clearAllChipItems } = useChipGroup();

  if (chipItems.length === 0) return null;

  return (
    <Flex gap={'0.5rem'} wrap={'wrap'}>
      {chipItems.map((item) => (
        <Chip key={item.label} onRemove={item.onRemove}>
          {item.label}
        </Chip>
      ))}

      <ClearAllButton onClear={clearAllChipItems} />
    </Flex>
  );
};

export default ChipGroup;
