import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ListState, Selection } from 'react-stately';
import { last, without } from 'lodash';

import MultiSelect from '../../components/MultiSelect';
import { getStatusLabel } from './StatusFilter.utils';
import { useStatusFilter } from './useStatusFilter';
import StatusFilterLabel from './StatusFilterLabel';
import type { StatusFilterValue } from './StatusFilter.context';

export type StatusFilterSelectItemId = StatusFilterValue | 'ALL';

export type StatusFilterSelectItem = { id: StatusFilterSelectItemId };

const itemIds = [
  'ALL',
  'UNKNOWN',
  'AT_RISK',
  'BLOCKED',
  'ON_TRACK',
] satisfies StatusFilterSelectItemId[];

const renderLabel = (state: ListState<StatusFilterSelectItem>) => (
  <StatusFilterLabel state={state} />
);

const StatusFilter = () => {
  const { t } = useTranslation();

  const { statuses, setStatuses, clearStatuses } = useStatusFilter();

  const items = useMemo(
    () => itemIds.map((id) => ({ id, title: getStatusLabel(t, id) })),
    [t],
  );

  const selectedKeys = useMemo(
    () => (statuses.length === 0 ? ['ALL'] : statuses),
    [statuses],
  );

  const onSelectionChange = useCallback(
    (selectedKeys: Selection) =>
      selectedKeys === 'all' || last(Array.from(selectedKeys)) === 'ALL'
        ? clearStatuses()
        : setStatuses(without([...selectedKeys], 'ALL') as StatusFilterValue[]),
    [clearStatuses, setStatuses],
  );

  return (
    <MultiSelect<StatusFilterSelectItem>
      items={items}
      selectedKeys={selectedKeys}
      onSelectionChange={onSelectionChange}
      label={renderLabel}
    />
  );
};

export default StatusFilter;
