import { css, useTheme } from '@emotion/react';

import Flex from 'shared/components/Flex';
import StatusMark from 'shared/status/StatusMark';
import UserAvatar from 'user/UserAvatar';
import TimelineRange from 'shared/components/TimelineRange';
import { ReactComponent as ChevronRightIcon } from 'shared/static/icons/icon-chevron-right.svg';
import LockedLink from 'shared/components/LockedLink';
import { objectiveRoutesPaths } from 'objective/objective.routing.paths';
import { canPerformStrategyElementAction } from 'user/ability/canPerformStrategyElementAction';
import { useTeamSlug } from 'team/TeamAdapter/useTeamSlug';
import { ReactComponent as LinkIconSVG } from 'shared/static/icons/icon-link.svg';
import MetricContextMenu from 'metric/MetricContextMenu/MetricContextMenu';
import TeamAvatar from 'team/TeamAvatar/TeamAvatar';
import MetricScore from 'metric/MetricScore';

import type { StrategyMetricsMetricFragment } from '../StrategyMetricsProvider/StrategyMetricsProvider.graphql.generated';
import type { StrategyMetricsObjective } from '../StrategyMetricsProvider/StrategyMetricsProvider.type';

type Props = {
  metric: StrategyMetricsMetricFragment;
  objective: StrategyMetricsObjective;
};

const MetricsListThemesRow = ({ metric, objective }: Props) => {
  const styleTheme = useTheme();
  const { teamSlug } = useTeamSlug();

  const isLink = metric.objective?.id !== objective.id;

  return (
    <Flex
      alignItems={'center'}
      gap={14}
      css={css`
        height: 50px;
        border-top: 1px solid ${styleTheme.color.strokeLight};

        &:first-of-type {
          border-top: none;
        }
      `}
    >
      <StatusMark
        statusIndicator={metric.currentMetricStatus?.statusIndicator}
      />
      <div
        css={css`
          flex-grow: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        `}
      >
        <LockedLink
          isInline={true}
          isActive={canPerformStrategyElementAction(metric, 'READ')}
          to={objectiveRoutesPaths.metrics.metric.root({
            params: {
              metricId: metric.id,
              objectiveId: objective.id,
              teamSlug,
            },
          })}
        >
          {metric.name}
        </LockedLink>
        <span
          css={css`
            margin-left: 14px;
            color: ${styleTheme.color.neutral2};
          `}
        >
          {isLink ? (
            <LinkIconSVG
              width={12}
              css={css({ marginTop: -2, marginRight: 2 })}
            />
          ) : (
            <ChevronRightIcon width={12} css={css({ marginTop: -2 })} />
          )}{' '}
          <LockedLink
            isInline={true}
            isActive={canPerformStrategyElementAction(objective, 'READ')}
            color={styleTheme.color.neutral2}
            to={objectiveRoutesPaths.root({
              params: { objectiveId: objective.id, teamSlug },
            })}
          >
            {objective.name}
          </LockedLink>
        </span>
      </div>
      <MetricScore metric={metric} />
      <TimelineRange timeLine={metric.timeLine} />
      <TeamAvatar orgUnit={objective.orgUnit} size={'small'} />
      <UserAvatar user={metric.owner} />
      <MetricContextMenu metric={metric} contextObjective={objective} />
    </Flex>
  );
};

export default MetricsListThemesRow;
