import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'shared/components/__DEPRECATED__/Select';

import { useUserEmailInput } from './UserEmailInputProvider';

export type Option = {
  label: string;
  value: string;
};

type Props = {
  hasError?: boolean;
  name?: string;
  onChange: (value: string) => void;
  value: string;
};

const UserEmailSelectInput = ({ name, value, onChange, hasError }: Props) => {
  const { t } = useTranslation();

  const { items } = useUserEmailInput();

  const hasValue = value !== '';
  const isValueInItems = items.find((user) => user.email === value);

  const options = [
    hasValue && !isValueInItems && { label: value, value },
    ...items.map((user) => ({
      label:
        user.displayName && user.displayName !== ''
          ? user.displayName
          : user.email,
      value: user.email,
    })),
  ].filter(Boolean);

  const optionForValue = options.find((option) => option.value === value);

  const handleChange = useCallback(
    (newOption: Option | null) => onChange(newOption?.value ?? ''),
    [onChange],
  );

  return (
    <Select<Option, false>
      name={name}
      value={optionForValue}
      options={options}
      onChange={handleChange}
      isSearchable={true}
      hasError={hasError}
      size={'full'}
      placeholder={t('selectUserInput.placeholder')}
    />
  );
};

export default UserEmailSelectInput;
