import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import ThemeForm from 'themes/ThemeForm/ThemeForm';
import { useTeamAdapter } from 'team/TeamAdapter';
import { useUpdateThemeMutation } from 'themes/ThemeProvider/ThemeProvider.graphql.generated';
import type { ThemeFormValues } from 'themes/ThemeForm/ThemeForm.type';
import { useToasts } from 'shared/toast/useToasts';
import useHandleError from 'shared/errors/useHandleError';

import { useTheme } from '../../ThemeProvider';

export type CreateThemeFormProps = {
  id: string;
  onSuccess: () => void;
};

const EditThemeForm = ({ id, onSuccess }: CreateThemeFormProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const { teamAdapter } = useTeamAdapter();
  const { theme } = useTheme();

  const onError = useHandleError();

  const [updateTheme] = useUpdateThemeMutation({ onError });

  const handleSubmit = useCallback(
    async (values: ThemeFormValues) => {
      if (!theme) return;

      const themeCategory = values.themeCategory
        ? { idToSet: values.themeCategory.id }
        : theme.themeCategory
        ? { idToRemove: theme.themeCategory.id }
        : undefined;

      await updateTheme({
        variables: {
          input: {
            idToUpdate: theme.id,
            ...teamAdapter.toInput(),
            name: values.name,
            description: values.description,
            themeCategory,
          },
        },
      });

      addToast({
        children: t('theme.toast.edit.success', { themeName: theme.name }),
        variant: 'success',
      });

      onSuccess();
    },
    [theme, updateTheme, teamAdapter, addToast, t, onSuccess],
  );

  return (
    <ThemeForm
      id={id}
      onSubmit={handleSubmit}
      initialValues={
        theme
          ? {
              description: theme.description || '',
              name: theme.name || '',
              themeCategory: theme.themeCategory
                ? {
                    id: theme.themeCategory.id,
                    title: theme.themeCategory.name!,
                  }
                : undefined,
            }
          : undefined
      }
    />
  );
};

export default EditThemeForm;
