import SuggestionsTextInput from 'shared/components/SuggestionsTextInput';

import { useUserEmailInput } from './UserEmailInputProvider';

type Props = {
  hasError?: boolean;
  name?: string;
  onChange: (value: string) => void;
  value: string;
};

const UserEmailTextInput = ({ name, value, onChange, hasError }: Props) => {
  const { items } = useUserEmailInput();

  const options = items.map((item) => ({
    value: item.email,
    label:
      item.displayName && item.displayName !== ''
        ? item.displayName
        : item.email,
  }));

  return (
    <SuggestionsTextInput
      name={name}
      value={value}
      options={options}
      onChange={onChange}
      filterOptions={true}
      hasError={hasError}
    />
  );
};

export default UserEmailTextInput;
