import { createContext } from 'react';

export type ActionStatusFilterValue = 'ACTIVE' | 'INACTIVE';

export type ActionStatusFilterContextValue = {
  actionStatuses: ActionStatusFilterValue[];
  addActionStatus: (status: ActionStatusFilterValue) => void;
  clearActionStatuses: () => void;
  removeActionStatus: (status: ActionStatusFilterValue) => void;
  setActionStatuses: (statuses: ActionStatusFilterValue[]) => void;
};

const ActionStatusFilterContext = createContext<
  ActionStatusFilterContextValue | undefined
>(undefined);

export default ActionStatusFilterContext;
