import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import UserEmailInput from 'user/UserEmailInput';
import Button from 'shared/components/Button';
import { ReactComponent as PlusIcon } from 'shared/static/icons/icon-plus.svg';
import Chip from 'shared/filters/Chip';

import Flex from '../Flex';
import Tooltip from '../Tooltip';

type Value = string[];

type Props = {
  hasError?: boolean;
  onChange: (value: Value) => void;
  value: Value;
};

const MultiUserInput = ({ value: values, onChange, hasError }: Props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');

  const isInputValueValid = yup
    .string()
    .email()
    .required()
    .isValidSync(inputValue);

  /*
   * Adds a blank string to values when
   * there is a valid email in the input.
   * This can be used to validate that an
   * email was entered but not added.
   */
  useEffect(() => {
    if (isInputValueValid && !values.includes('')) {
      onChange([...values, '']);
    }

    if (!isInputValueValid && values.includes('')) {
      onChange(values.filter((eachValue) => eachValue !== ''));
    }
  }, [isInputValueValid, onChange, values]);

  const onAdd = useCallback(() => {
    onChange(uniq([...values, inputValue]));
    setInputValue('');
  }, [inputValue, onChange, values]);

  const onRemove = useCallback(
    (email: string) => {
      onChange(values.filter((eachEmail) => eachEmail !== email));
    },
    [onChange, values],
  );

  return (
    <Flex direction={'column'} gap={8}>
      <Flex alignItems={'center'} gap={8}>
        <div css={css({ flexGrow: '1', width: '100%' })}>
          <UserEmailInput
            value={inputValue}
            onChange={setInputValue}
            hasError={hasError}
          />
        </div>
        <Tooltip
          content={t('multiUserInput.addButtonTooltip')}
          placement={'right'}
          isOpen={isInputValueValid}
          avoidButton={true}
        >
          <Button
            icon={PlusIcon}
            variant={'icon'}
            color={'mono'}
            onPress={onAdd}
            isDisabled={!isInputValueValid}
          />
        </Tooltip>
      </Flex>
      <Flex gap={8} wrap={'wrap'}>
        {values.filter(Boolean).map((email) => (
          <Chip key={email} onRemove={() => onRemove(email)}>
            {email}
          </Chip>
        ))}
      </Flex>
    </Flex>
  );
};

export default MultiUserInput;
