import type { PropsWithChildren } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import useSingleSearchParamState from 'shared/hooks/useSearchParamState';

import type { CollaboratingTeamsFilterValue } from './CollaboratingTeamsFilter.context';
import CollaboratingTeamsFilterContext from './CollaboratingTeamsFilter.context';
import { useChipGroup } from '../ChipGroup';

const filterName = 'collaboratingTeams';

type Props = PropsWithChildren<{
  storeToSession: boolean;
}>;

const CollaboratingTeamsFilterProvider = ({
  storeToSession,
  children,
}: Props) => {
  const { t } = useTranslation();

  const { orgUnit } = useOrgUnit();

  const [
    showCollaboratingTeams,
    setShowCollaboratingTeams,
    clearShowCollaboratingTeams,
  ] = useSingleSearchParamState<boolean>(
    filterName,
    false,
    storeToSession,
    (serializedValue) => serializedValue === 'true',
    (value) => (value ? 'true' : 'false'),
  );

  const toggleShowCollaboratingTeams = useCallback(() => {
    setShowCollaboratingTeams(!showCollaboratingTeams);
  }, [setShowCollaboratingTeams, showCollaboratingTeams]);

  const value = useMemo<CollaboratingTeamsFilterValue>(
    () => ({
      showCollaboratingTeams: !!orgUnit && !!showCollaboratingTeams,
      toggleShowCollaboratingTeams,
      clearShowCollaboratingTeams,
    }),
    [
      clearShowCollaboratingTeams,
      orgUnit,
      showCollaboratingTeams,
      toggleShowCollaboratingTeams,
    ],
  );

  const { setChipItems } = useChipGroup();

  useEffect(
    () =>
      setChipItems(
        filterName,
        clearShowCollaboratingTeams,
        showCollaboratingTeams
          ? [
              {
                label: t('filters.showCollaboratingTeams.chip'),
                onRemove: clearShowCollaboratingTeams,
              },
            ]
          : [],
      ),
    [clearShowCollaboratingTeams, setChipItems, showCollaboratingTeams, t],
  );

  return (
    <CollaboratingTeamsFilterContext.Provider value={value}>
      {children}
    </CollaboratingTeamsFilterContext.Provider>
  );
};

export default CollaboratingTeamsFilterProvider;
