import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';

import { useTeamAdapter } from 'team/TeamAdapter';
import {
  groupObjectivesByTheme,
  sortObjectivesByTheme,
} from 'objective/objective.utils';
import { getNodesFromConnection } from 'shared/graphql/utils';
import Spinner from 'shared/spinner/Spinner';
import useStrategyProfilePriorities from 'strategy/useStrategyProfilePriorities';
import useHandleError from 'shared/errors/useHandleError';
import { useCalendarControls } from 'shared/filters/CalendarControls/useCalendarControls';
import { useCompletedFilter } from 'shared/filters/CompletedFilterButton/useCompletedFilter';
import { useOrgUnit } from 'orgUnit/OrgUnitProvider';
import { useCollaboratingTeamsFilter } from 'shared/filters/CollaboratingTeamsFilter';
import { useStatusFilter } from 'shared/filters/StatusFilter';
import { usePriorityFilter } from 'shared/filters/PriorityFilter';
import { useUserFilter } from 'shared/filters/UserFilter';
import { useTimelineFilter } from 'shared/filters/TimelineFilter';
import { useThemeFilter } from 'shared/filters/ThemeFilter';

import type { TeamInsightsOverviewProviderContextValue } from './TeamInsightsOverviewProvider.context';
import { TeamInsightsOverviewProviderContext } from './TeamInsightsOverviewProvider.context';
import {
  useTeamInsightsOverviewForOrgQuery,
  useTeamInsightsOverviewForOrgUnitQuery,
} from './TeamInsightsOverviewProvider.graphql.generated';
import type {
  TeamInsightsOverviewTheme,
  TeamInsightsOverviewObjective,
} from './TeamInsightsOverviewProvider.type';
import {
  filterObjectivesByUser,
  filterObjectivesByTheme,
  filterObjectivesByStatus,
  filterObjectivesByDates,
  getActiveTheme,
  getActiveThemeObjectives,
  getObjectivesOwners,
  filterObjectivesByPriority,
  filterObjectivesByCollaboratingTeams,
  filterObjectivesByCompleted,
} from './TeamInsightsOverviewProvider.utils';

type TeamInsightsOverviewProviderProps = PropsWithChildren<object>;

const TeamInsightsOverviewProvider = ({
  children,
}: TeamInsightsOverviewProviderProps) => {
  const { teamAdapter } = useTeamAdapter();

  const { orgUnit } = useOrgUnit();

  const { isPrioritiesLoading } = useStrategyProfilePriorities();

  const onError = useHandleError();

  const activeQuery = teamAdapter.isOrgUnit
    ? useTeamInsightsOverviewForOrgUnitQuery
    : useTeamInsightsOverviewForOrgQuery;

  const { data: orgData, loading: isOrgLoading } =
    useTeamInsightsOverviewForOrgQuery({
      skip: activeQuery !== useTeamInsightsOverviewForOrgQuery,
      onError,
    });

  const { data: orgUnitData, loading: isOrgUnitLoading } =
    useTeamInsightsOverviewForOrgUnitQuery({
      variables: {
        orgUnitId: teamAdapter.keyArg,
      },
      skip: activeQuery !== useTeamInsightsOverviewForOrgUnitQuery,
      onError,
    });

  const isLoading =
    (!orgData && isOrgLoading) || (!orgUnitData && isOrgUnitLoading);

  const objectives = useMemo(() => {
    const objectiveConnection = teamAdapter.isOrg
      ? orgData?.activeOrg.objectives
      : orgUnitData?.orgUnit.objectives;

    if (!objectiveConnection) return;

    return getNodesFromConnection(objectiveConnection).map((objective) => ({
      ...objective,
      isFiltersMatch: true,
    }));
  }, [
    orgData?.activeOrg.objectives,
    orgUnitData?.orgUnit.objectives,
    teamAdapter.isOrg,
  ]);

  const { selectedThemeIds } = useThemeFilter();

  const { statuses } = useStatusFilter();

  const { priorities } = usePriorityFilter();

  const { selectedUserIds, setAvailableUsers } = useUserFilter();

  const { startDate, endDate } = useTimelineFilter();

  const { showCollaboratingTeams } = useCollaboratingTeamsFilter();

  const { calendarIntervalStartDate, calendarIntervalEndDate, calendarCheck } =
    useCalendarControls();

  const { showCompleted } = useCompletedFilter();

  useEffect(() => {
    if (!objectives) return;

    setAvailableUsers(getObjectivesOwners(objectives));
  }, [objectives, setAvailableUsers]);

  const contextValue = useMemo<
    Maybe<TeamInsightsOverviewProviderContextValue>
  >(() => {
    if (!objectives) return;

    const objectivesByTheme = sortObjectivesByTheme(
      groupObjectivesByTheme<
        TeamInsightsOverviewObjective,
        TeamInsightsOverviewTheme
      >(objectives, true),
      orgUnitData
        ? [
            ...orgUnitData.orgUnit.parentOrgUnitTree,
            orgUnitData.orgUnit,
          ].reverse()
        : [],
      true,
    );

    return {
      objectivesByTheme,
      objectives,
      filteredObjectives: filterObjectivesByCollaboratingTeams(
        filterObjectivesByDates(
          filterObjectivesByDates(
            filterObjectivesByUser(
              filterObjectivesByCompleted(
                filterObjectivesByStatus(
                  filterObjectivesByTheme(
                    filterObjectivesByPriority(objectives, priorities),
                    selectedThemeIds,
                  ),
                  statuses,
                ),
                showCompleted,
              ),
              selectedUserIds,
            ),
            startDate,
            endDate,
          ),
          calendarIntervalStartDate,
          calendarIntervalEndDate,
          calendarCheck,
        ),
        showCollaboratingTeams,
        orgUnit?.id,
      ),
      activeTheme: getActiveTheme(objectivesByTheme, selectedThemeIds),
      activeThemeObjectives: getActiveThemeObjectives(
        objectivesByTheme,
        selectedThemeIds,
      ),
    };
  }, [
    calendarCheck,
    calendarIntervalEndDate,
    calendarIntervalStartDate,
    endDate,
    objectives,
    orgUnit?.id,
    orgUnitData,
    priorities,
    selectedThemeIds,
    selectedUserIds,
    showCollaboratingTeams,
    showCompleted,
    startDate,
    statuses,
  ]);

  if (isLoading || isPrioritiesLoading) {
    return <Spinner.Circle />;
  }

  if (contextValue) {
    return (
      <TeamInsightsOverviewProviderContext.Provider value={contextValue}>
        {children}
      </TeamInsightsOverviewProviderContext.Provider>
    );
  }

  return null;
};

export default TeamInsightsOverviewProvider;
